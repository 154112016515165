import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useEffect, useState } from "react";
import { useFrame } from "@react-three/fiber";


const keys = { w: 'w', s: 's', a: 'a', d: 'd', q: 'q', e: 'e' }
const getKey = (e) => keys[e]
// 创建键盘控制器
const useControl = () => {
    const [step, setStep] = useState({ w: false, s: false, a: false, d: false, q: false, e: false });
    useEffect(() => {
      const up = (e) => {
        setStep((s) => (
          {
            ...s,
            [getKey(e.key)]: false
          }
        ))
      }
      const down = (e) => {
        setStep((s) => (
          {
            ...s,
            [getKey(e.key)]: true
          }
        ))
      }
      // 添加事件监听器
      document.addEventListener("keyup", up);
      document.addEventListener("keydown", down);
  
      return () => {
        document.removeEventListener("keyup", up);
        document.removeEventListener("keydown", down);
      }
  
    }, [])
    return step
}

export function BambooDragonfly(props) {


    const group = useRef();
    const { nodes, materials, animations } = useGLTF("/竹蜻蜓.glb");
    const { actions, names } = useAnimations(animations, group);
    const step = useControl()

    useEffect(() => {
        // 播放全部模型动画
        names.forEach(name => {
            actions[name].play()
        })
    }, [actions, names])

    // 按键按下，改变模型和摄像机的位置
    useFrame((state) => {
        
        state.camera.lookAt(group.current.position.x , group.current.position.y , group.current.position.z )
        if (step.w) {
            group.current.position.set(group.current.position.x, group.current.position.y, group.current.position.z - 0.04)
            state.camera.position.set(state.camera.position.x , state.camera.position.y , state.camera.position.z - 0.04 )
    
        } else if (step.s) {
            group.current.position.set(group.current.position.x, group.current.position.y, group.current.position.z + 0.04)
            state.camera.position.set(state.camera.position.x , state.camera.position.y , state.camera.position.z + 0.04 )
        }
        else if (step.a) {
            group.current.position.set(group.current.position.x - 0.04, group.current.position.y, group.current.position.z)
            state.camera.position.set(state.camera.position.x - 0.04 , state.camera.position.y , state.camera.position.z )
        }
        else if (step.d) {
            group.current.position.set(group.current.position.x + 0.04, group.current.position.y, group.current.position.z)
            state.camera.position.set(state.camera.position.x + 0.04 , state.camera.position.y , state.camera.position.z )
    
        } else if (step.q) {
            group.current.position.set(group.current.position.x, group.current.position.y + 0.04, group.current.position.z)
            state.camera.position.set(state.camera.position.x , state.camera.position.y + 0.04 , state.camera.position.z )
    
        } else if (step.e) {
            group.current.position.set(group.current.position.x, group.current.position.y - 0.04, group.current.position.z)
            state.camera.position.set(state.camera.position.x , state.camera.position.y - 0.04 , state.camera.position.z )
        }
    
    })
      
    return (
        <group ref={group} {...props} dispose={null}>
          <group name="Scene">
            <mesh
              name="杆子"
              castShadow
              receiveShadow
              geometry={nodes.杆子.geometry}
              material={materials.杆子}
              position={[0, 0.45, 0]}
              scale={[1, 1.5, 1]}
            />
            <mesh
              name="叶子"
              castShadow
              receiveShadow
              geometry={nodes.叶子.geometry}
              material={materials.叶片}
              position={[0, 0.89, 0]}
              scale={0.8}
            />
          </group>
        </group>
    );
}

useGLTF.preload("/竹蜻蜓.glb");

export default BambooDragonfly