import React, { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import BoxSubstation from './component/boxSubstation';
import { OrbitControls, Stage, Sky,  Stats } from '@react-three/drei'

export default function BoxSubstationShow() {
    const ref = useRef()
    return (
        <div className='App'>
            <Canvas  camera={{ fov: 50 }}>
                <Suspense fallback={null}>
                    <Stage controls={ref} >
                        <BoxSubstation />
                    </Stage>
                    <Sky scale={1000} sunPosition={[500, 150, -1000]} turbidity={0.1} />
                </Suspense>
                <OrbitControls ref={ref} />
                <Stats />
            </Canvas>
        </div>
    )
}