/*
 * @Author: mwxp (ljh) 
 * @Date: 2022-06-08 19:16:26 
 * @Last Modified by: mwxp (ljh)
 * @Last Modified time: 2022-07-11 18:21:59
 */

import './style.css'
import React, { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage, Sky, Html, Stats } from '@react-three/drei'
import Model from './component/anima'
import { FaChevronUp } from 'react-icons/fa'
import { useNavigate } from "react-router-dom";

export default function Anima() {


    const navigate = useNavigate()

    //平滑上升
    const up = () => {

        navigate('/model')
    }

    const ref = useRef()
    return (
        <div className='App'>
            <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
                <Suspense fallback={null}>
                    <Stage controls={ref} preset="rembrandt" intensity={1} environment="city">
                        <Model />
                    </Stage>
                    <Html distanceFactor={80} position={[-0.5, 11, 0]}>
                        <FaChevronUp onClick={up} style={{ cursor: 'pointer', color: 'indianred' }} />
                    </Html>
                    <Sky scale={1000} sunPosition={[500, 150, -1000]} turbidity={0.1} />
                </Suspense>
                <OrbitControls ref={ref} />
                <Stats />
            </Canvas>
        </div>

    )
}