import React, { Component, useState } from "react";
import {
    Layout, Row, Col, Typography, Card
} from 'antd';
import { useNavigate } from 'react-router-dom';

import './style.css'
import { useEffect } from "react";


const { Header, Footer } = Layout;


const HomePage = () => {

    const [windowWidth, setWindowWidth] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        let lgWidth = window.screen.width;
        if (lgWidth === 2560) {
            setWindowWidth(true)
        } else {
            setWindowWidth(false)
        }
    }, [])

    return         (
        <div className="homepage_root">
            <Layout>
                {/* 页头 */}
                <Header style={windowWidth ? { paddingTop: 30, height: '10vh', borderBottom: "5px solid #c00" } : { paddingTop: 14, height: '12vh', borderBottom: "5px solid #c00" }}>
                    <Row type="flex" justify="center" >
                        <Col span={1} style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center",flexDirection:"row",placeContent:"center"}}>
                            <img alt="logo" src="/logo.png" width={70} height={50}  ></img>
                        </Col>
                        <Col span={14}>
                            <h1 style={windowWidth ? titleLayoutLG : titleLayout}>数字孪生</h1>
                        </Col>
                    </Row>
                </Header>
                {/* 页面主题 */}
                <div style={{height:"78vh",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="divLayout">
                            <div className="frameLayout" style={{height:"360px",width:"724px"}} onClick={() => { 
                                navigate('/SubstationShow');
                             }}>
                                <div className="flexLayout">
                                    <img style={{width: "85%"}} alt="地址油藏" src="/picture/智慧变电站.png" />
                                </div>
                                <div className="flexTitle">
                                    <p className="cardTitleLayout">智慧变电站</p>
                                </div>
                            </div>
                            <div className="frameLayout" onClick={() => {
                                navigate('/model');
                            }}>
                                <div className="flexLayout">
                                    <img style={{width: "95%"}} alt="钻采工程" src="/picture/泵.png"/>
                                </div>
                                <div className="flexTitle">
                                    <p className="cardTitleLayout">泵</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <div className="divLayout">
                            <div className="frameLayout" onClick={() => {
                                navigate('/LandRIGSHOW');
                            }}>
                                <div className="flexLayout">
                                    <img style={{width: "95%"}} alt="生产运行" src="/picture/陆地钻井平台.png"/>
                                </div>
                                <div className="flexTitle">
                                    <p className="cardTitleLayout">陆地钻井平台</p>
                                </div>
                            </div>
                            <div className="frameLayout" onClick={() => {
                                navigate('/OceanRIGSHOW');
                            }}>
                                <div className="flexLayout">
                                    <img style={{width: "95%"}} alt="安全环保" src="/picture/海上钻井平台.png"/>
                                </div>
                                <div className="flexTitle">
                                    <p className="cardTitleLayout">海上钻井平台</p>
                                </div>
                            </div>
                            <div className="frameLayout" onClick={() => {
                                navigate('/OilWell3D');
                            }}>
                                <div className="flexLayout">
                                    <img style={{width: "95%"}} alt="地面工程" src="/picture/3D井场看板.png"/>
                                </div>
                                <div className="flexTitle">
                                    <p className="cardTitleLayout">智能液面回声仪</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout >
        </div>
    )
}


const titleLayout = {
    marginTop: "1vh",
    fontSize: "2.0em",
    color: '#b7251e'
}
const titleLayoutLG = {
    marginTop: "2vh",
    fontSize: "3.0em",
    color: "#b7251e"
}

export default HomePage;