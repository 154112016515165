import { useState } from 'react'
import { useEffect } from 'react'

import OilWell3D from './component/OilWell3D.jsx'

let timer = null;
const Visualization3D = (props) => {

    // 深度
    const [deepth, setDeepth] = useState(0)
    // 声速
    const [sound, setSound] = useState(0)


    useEffect(() => {
        timer = setInterval(() => {
            setDeepth(fullClose(0, 3000))
            setSound(fullClose(300, 600))
        }, 5000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    //取得[n,m]范围随机数
    function fullClose(n,m) {
        var result = Math.random()*(m+1-n)+n;
        while(result>m) {
            result = Math.random()*(m+1-n)+n;
        }
        return result;
    }

    return (
        <div style={{ position: 'relative' }}>
            <OilWell3D
                style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
                info = {{
                    // 深度
                    "deepth": deepth,
                    // 声速
                    "acousticVelocity": sound
            }}/>
        </div>
    )

}

export default Visualization3D