import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Transformation({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/开关室内内部.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <group
        name="立方体035"
        position={[4.75, 1.67, -0.29]}
        rotation={[-Math.PI, Math.PI / 2, 0]}
        scale={[-1.7, -1.35, -2.83]}
      >
        <mesh
          name="立方体012"
          castShadow
          receiveShadow
          geometry={nodes.立方体012.geometry}
          material={materials["材质.002"]}
        />
        <lineSegments
          name="立方体012_1"
          geometry={nodes.立方体012_1.geometry}
          material={materials["材质.002"]}
        />
      </group>
      <mesh
        name="立方体018"
        castShadow
        receiveShadow
        geometry={nodes.立方体018.geometry}
        material={materials["材质.002"]}
        position={[0.21, 1.67, 1.81]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-1.7, -1.35, -3.82]}
      />
      <group
        name="立方体015"
        position={[6.97, 1.38, -1.55]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.5, 1.86, 0.43]}
      >
        <mesh
          name="立方体030"
          castShadow
          receiveShadow
          geometry={nodes.立方体030.geometry}
          material={materials.内层箱子}
        />
        <mesh
          name="立方体030_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体030_1.geometry}
          material={materials.最外层的壳}
        />
        <mesh
          name="立方体030_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体030_2.geometry}
          material={materials["按钮.001"]}
        />
        <mesh
          name="立方体030_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体030_3.geometry}
          material={nodes.立方体030_3.material}
        />
        <mesh
          name="立方体030_4"
          castShadow
          receiveShadow
          geometry={nodes.立方体030_4.geometry}
          material={materials.红色大按钮}
        />
        <mesh
          name="立方体030_5"
          castShadow
          receiveShadow
          geometry={nodes.立方体030_5.geometry}
          material={materials.红色不透明}
        />
        <mesh
          name="立方体030_6"
          castShadow
          receiveShadow
          geometry={nodes.立方体030_6.geometry}
          material={materials.黑色屏幕}
        />
        <mesh
          name="立方体030_7"
          castShadow
          receiveShadow
          geometry={nodes.立方体030_7.geometry}
          material={materials.纯黑}
        />
        <mesh
          name="立方体030_8"
          castShadow
          receiveShadow
          geometry={nodes.立方体030_8.geometry}
          material={materials.漏电开关红色}
        />
        <points
          name="立方体030_9"
          geometry={nodes.立方体030_9.geometry}
          material={materials.内层箱子}
        />
      </group>
      <group
        name="立方体002"
        position={[-1.26, 2.36, 1.9]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[0.19, 0.07, 0.14]}
      >
        <mesh
          name="立方体004_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体004_1.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          name="立方体004_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体004_2.geometry}
          material={materials["材质.006"]}
        />
        <mesh
          name="立方体004_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体004_3.geometry}
          material={materials["变压器主体.001"]}
        />
        <mesh
          name="立方体004_4"
          castShadow
          receiveShadow
          geometry={nodes.立方体004_4.geometry}
          material={materials["铁柱子.001"]}
        />
        <mesh
          name="立方体004_5"
          castShadow
          receiveShadow
          geometry={nodes.立方体004_5.geometry}
          material={materials["打卡机前壳.002"]}
        />
        <mesh
          name="立方体004_6"
          castShadow
          receiveShadow
          geometry={nodes.立方体004_6.geometry}
          material={materials["按钮.002"]}
        />
      </group>
      <group
        name="立方体065"
        position={[1.23, 1.51, 4.73]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[-0.04, -0.04, -0.01]}
      >
        <mesh
          name="立方体100"
          castShadow
          receiveShadow
          geometry={nodes.立方体100.geometry}
          material={materials["Material.003"]}
        />
        <mesh
          name="立方体100_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_1.geometry}
          material={nodes.立方体100_1.material}
        />
        <mesh
          name="立方体100_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_2.geometry}
          material={materials["材质.017"]}
        />
        <mesh
          name="立方体100_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_3.geometry}
          material={materials["铁柱子.002"]}
        />
        <mesh
          name="立方体100_4"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_4.geometry}
          material={materials["变压器主体.002"]}
        />
        <mesh
          name="立方体100_5"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_5.geometry}
          material={materials.绿色按钮}
        />
        <mesh
          name="立方体100_6"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_6.geometry}
          material={materials["散热片.001"]}
        />
        <mesh
          name="立方体100_7"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_7.geometry}
          material={materials["按钮.003"]}
        />
        <mesh
          name="立方体100_8"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_8.geometry}
          material={materials["plastic_black_shiny_camera.003"]}
        />
        <mesh
          name="立方体100_9"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_9.geometry}
          material={materials["plastic_grey_camera.002"]}
        />
      </group>
      <mesh
        name="空调外壳"
        castShadow
        receiveShadow
        geometry={nodes.空调外壳.geometry}
        material={materials.空调外壳}
        position={[-1, 1.41, 5.1]}
        rotation={[0, -1.05, Math.PI]}
        scale={[-0.04, -0.03, -0.04]}
      />
      <group
        name="Cube004"
        position={[7.43, 1.04, 1.25]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[0.21, 0.04, 0.49]}
      >
        <mesh
          name="Cube005_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube005_1.geometry}
          material={materials["灭火器红色.001"]}
        />
        <mesh
          name="Cube005_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube005_2.geometry}
          material={materials["灭火器黑色.001"]}
        />
      </group>
      <group
        name="立方体057"
        position={[-1.27, 1.42, 0.26]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.33, 1.08, 0.57]}
      >
        <mesh
          name="立方体074"
          castShadow
          receiveShadow
          geometry={nodes.立方体074.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体074_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体074_1.geometry}
          material={materials["材质.007"]}
        />
      </group>
      <group
        name="立方体001"
        position={[-1.27, 1.42, 3.47]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.33, 1.08, 0.57]}
      >
        <mesh
          name="立方体001_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体001_1.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体001_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体001_2.geometry}
          material={materials["材质.007"]}
        />
      </group>
      <group name="墙体" position={[2, 2.83, 1.42]} scale={2.46}>
        <mesh
          name="camera_security001"
          castShadow
          receiveShadow
          geometry={nodes.camera_security001.geometry}
          material={materials.plastic_grey_camera}
        />
        <mesh
          name="camera_security001_1"
          castShadow
          receiveShadow
          geometry={nodes.camera_security001_1.geometry}
          material={materials.plastic_black_shiny_camera}
        />
      </group>
      <group
        name="Cube013"
        position={[0.65, 1.66, 6.08]}
        rotation={[0, -1.57, 0]}
        scale={[0.05, 0.52, 0.46]}
      >
        <mesh
          name="Cube013_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube013_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube013_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube013_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube012"
        position={[-0.3, 1.66, 6.08]}
        rotation={[0, -1.57, 0]}
        scale={[0.05, 0.52, 0.46]}
      >
        <mesh
          name="Cube012_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube012_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube012_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube012_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube001"
        position={[-0.3, 1.66, 5.98]}
        rotation={[0, 1.57, 0]}
        scale={[0.05, 0.52, 0.46]}
      >
        <mesh
          name="Cube001_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube001_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube001_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube001_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube002"
        position={[0.65, 1.66, 5.98]}
        rotation={[0, 1.57, 0]}
        scale={[0.05, 0.52, 0.46]}
      >
        <mesh
          name="Cube002_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube002_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube002_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube002_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube003"
        position={[4.1, 1.66, 1.85]}
        rotation={[0, -1.57, 0]}
        scale={[0.05, 0.49, 0.43]}
      >
        <mesh
          name="Cube003_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube003_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube003_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube003_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube005"
        position={[3.22, 1.66, 1.85]}
        rotation={[0, -1.57, 0]}
        scale={[0.05, 0.49, 0.43]}
      >
        <mesh
          name="Cube004_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube004_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube004_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube004_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube006"
        position={[3.22, 1.66, 1.76]}
        rotation={[0, 1.57, 0]}
        scale={[0.05, 0.49, 0.43]}
      >
        <mesh
          name="Cube006_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube006_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube006_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube006_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube007"
        position={[4.1, 1.66, 1.76]}
        rotation={[0, 1.57, 0]}
        scale={[0.05, 0.49, 0.43]}
      >
        <mesh
          name="Cube007_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube007_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube007_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube007_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <mesh
        name="立方体043"
        castShadow
        receiveShadow
        geometry={nodes.立方体043.geometry}
        material={materials.铁门}
        position={[5.79, 1.34, 1.77]}
        rotation={[-Math.PI, Math.PI / 2, 0]}
        scale={[-5.69, -1.02, -0.5]}
      />
      <group
        name="立方体034"
        position={[5.62, 1.36, 1.62]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={[0.02, 0.2, 0.03]}
      >
        <mesh
          name="立方体057_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体057_1.geometry}
          material={materials.black}
        />
        <mesh
          name="立方体057_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体057_2.geometry}
          material={materials.排风扇子}
        />
        <mesh
          name="立方体057_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体057_3.geometry}
          material={materials.玻璃}
        />
        <mesh
          name="立方体057_4"
          castShadow
          receiveShadow
          geometry={nodes.立方体057_4.geometry}
          material={materials.铁门}
        />
      </group>
      <mesh
        name="立方体004"
        castShadow
        receiveShadow
        geometry={nodes.立方体004.geometry}
        material={materials.阁楼门}
        position={[5.79, 1.34, 1.98]}
        rotation={[-Math.PI, Math.PI / 2, 0]}
        scale={[-5.69, -1.02, -0.5]}
      />
      <mesh
        name="立方体003"
        castShadow
        receiveShadow
        geometry={nodes.立方体003.geometry}
        material={materials.阁楼门}
        position={[5.79, 1.34, 1.81]}
        rotation={[-Math.PI, Math.PI / 2, 0]}
        scale={[-19.48, -1.02, -0.5]}
      />
      <group
        name="Cube008"
        position={[8.03, 1.66, -0.32]}
        scale={[0.08, 0.83, 0.73]}
      >
        <mesh
          name="Cube008_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube008_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube008_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube008_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube009"
        position={[7.87, 1.66, -0.32]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.08, 0.83, 0.73]}
      >
        <mesh
          name="Cube009_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube009_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube009_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube009_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
    </group>
  );
}

// useGLTF.preload("/开关室内内部.gltf");
