import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function LandRIG(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/陆地钻井平台.gltf");
  const { actions } = useAnimations(animations, group)
  
  console.log('读取actions里的元素 --> ', actions);
  return (
    <group {...props} ref={group} dispose={null}>
      <group name="Scene">
        <mesh 
          name="立方体210"
          castShadow
          receiveShadow
          geometry={nodes.立方体210.geometry}
          material={materials.栏杆}
          position={[-9.24, -2.22, -5.91]}
          rotation={[0, 0, -Math.PI]}
          scale={[-1.2, -0.11, -0.11]}
        />
        <mesh
          // ref={myCube}
          name="立方体208"
          castShadow
          receiveShadow
          geometry={nodes.立方体208.geometry}
          material={materials.栏杆}
          position={[-9.29, -2.31, -0.2]}
          rotation={[0, 0, -Math.PI]}
          scale={[-1.2, -0.11, -0.11]}
        />
        <group
          name="柱体108"
          position={[12.42, -1.93, -5.25]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={[-0.01, -1.3, -0.01]}
        >
          <mesh
            name="柱体038"
            castShadow
            receiveShadow
            geometry={nodes.柱体038.geometry}
            material={materials.黑色钢铁}
          />
          <mesh
            name="柱体038_1"
            castShadow
            receiveShadow
            geometry={nodes.柱体038_1.geometry}
            material={materials.蓝色三角架}
          />
        </group>
        <group
          name="平面006"
          position={[51.9, -1.63, -20.97]}
          rotation={[Math.PI / 4, Math.PI / 2, 0]}
          scale={1.15}
        >
          <mesh
            name="平面_1"
            castShadow
            receiveShadow
            geometry={nodes.平面_1.geometry}
            material={materials.太阳能电板}
          />
          <mesh
            name="平面_2"
            castShadow
            receiveShadow
            geometry={nodes.平面_2.geometry}
            material={materials.楼梯板}
          />
        </group>
        <mesh
          name="集装箱"
          castShadow
          receiveShadow
          geometry={nodes.集装箱.geometry}
          material={materials.wire}
          position={[42.26, -1.09, -4.47]}
          scale={0.88}
        />
        <group
          name="平面001"
          position={[27.4, -1.63, -20.97]}
          rotation={[Math.PI / 4, Math.PI / 2, 0]}
          scale={1.15}
        >
          <mesh
            name="平面011_1"
            castShadow
            receiveShadow
            geometry={nodes.平面011_1.geometry}
            material={materials.太阳能电板}
          />
          <mesh
            name="平面011_2"
            castShadow
            receiveShadow
            geometry={nodes.平面011_2.geometry}
            material={materials.楼梯板}
          />
        </group>
        <group
          name="平面003"
          position={[51.9, -1.63, -18.56]}
          rotation={[Math.PI / 4, Math.PI / 2, 0]}
          scale={1.15}
        >
          <mesh
            name="平面012_1"
            castShadow
            receiveShadow
            geometry={nodes.平面012_1.geometry}
            material={materials.太阳能电板}
          />
          <mesh
            name="平面012_2"
            castShadow
            receiveShadow
            geometry={nodes.平面012_2.geometry}
            material={materials.楼梯板}
          />
        </group>
        <group
          name="平面005"
          position={[27.4, -1.63, -18.56]}
          rotation={[Math.PI / 4, Math.PI / 2, 0]}
          scale={1.15}
        >
          <mesh
            name="平面013_1"
            castShadow
            receiveShadow
            geometry={nodes.平面013_1.geometry}
            material={materials.太阳能电板}
          />
          <mesh
            name="平面013_2"
            castShadow
            receiveShadow
            geometry={nodes.平面013_2.geometry}
            material={materials.楼梯板}
          />
        </group>
        <group
          name="平面011"
          position={[27.4, -1.63, -15.97]}
          rotation={[Math.PI / 4, Math.PI / 2, 0]}
          scale={1.15}
        >
          <mesh
            name="平面015"
            castShadow
            receiveShadow
            geometry={nodes.平面015.geometry}
            material={materials.太阳能电板}
          />
          <mesh
            name="平面015_1"
            castShadow
            receiveShadow
            geometry={nodes.平面015_1.geometry}
            material={materials.楼梯板}
          />
        </group>
        <group
          name="平面010"
          position={[51.9, -1.63, -15.97]}
          rotation={[Math.PI / 4, Math.PI / 2, 0]}
          scale={1.15}
        >
          <mesh
            name="平面014"
            castShadow
            receiveShadow
            geometry={nodes.平面014.geometry}
            material={materials.太阳能电板}
          />
          <mesh
            name="平面014_1"
            castShadow
            receiveShadow
            geometry={nodes.平面014_1.geometry}
            material={materials.楼梯板}
          />
        </group>
        <mesh
          name="立方体001"
          castShadow
          receiveShadow
          geometry={nodes.立方体001.geometry}
          material={materials.栏杆}
          position={[-9.29, -2.31, -12.25]}
          rotation={[0, 0, -Math.PI]}
          scale={[-1.2, -0.11, -0.11]}
        />
        <mesh
          name="立方体004"
          castShadow
          receiveShadow
          geometry={nodes.立方体004.geometry}
          material={materials.栏杆}
          position={[-4.84, -2.22, -17.96]}
          rotation={[0, 0, -Math.PI]}
          scale={[-1.2, -0.11, -0.11]}
        />
        <mesh
          name="集装箱001"
          castShadow
          receiveShadow
          geometry={nodes.集装箱001.geometry}
          material={materials.排风扇子}
          position={[27.4, -1.09, -4.47]}
          scale={0.88}
        />
        <mesh
          name="集装箱003"
          castShadow
          receiveShadow
          geometry={nodes.集装箱003.geometry}
          material={materials.集装箱红}
          position={[20.06, -1.09, -4.47]}
          scale={0.88}
        />
        <mesh
          name="集装箱006"
          castShadow
          receiveShadow
          geometry={nodes.集装箱006.geometry}
          material={materials.集装箱红}
          position={[21.99, 1.62, -4.47]}
          scale={0.88}
        />
        <mesh
          name="立方体002"
          castShadow
          receiveShadow
          geometry={nodes.立方体002.geometry}
          material={materials.栏杆}
          position={[-9.24, -2.22, -17.96]}
          rotation={[0, 0, -Math.PI]}
          scale={[-1.2, -0.11, -0.11]}
        />
        <mesh
          name="立方体005"
          castShadow
          receiveShadow
          geometry={nodes.立方体005.geometry}
          material={materials.栏杆}
          position={[-4.89, -2.31, -0.22]}
          rotation={[0, 0, -Math.PI]}
          scale={[-1.2, -0.11, -0.11]}
        />
        <mesh
          name="立方体003"
          castShadow
          receiveShadow
          geometry={nodes.立方体003.geometry}
          material={materials.栏杆}
          position={[-4.89, -2.31, -12.25]}
          rotation={[0, 0, -Math.PI]}
          scale={[-1.2, -0.11, -0.11]}
        />
        <mesh
          name="集装箱002"
          castShadow
          receiveShadow
          geometry={nodes.集装箱002.geometry}
          material={materials.蓝色三角架}
          position={[29.38, 1.59, -4.47]}
          scale={0.88}
        />
        <mesh
          name="集装箱005"
          castShadow
          receiveShadow
          geometry={nodes.集装箱005.geometry}
          material={materials.蓝色三角架}
          position={[36.8, 1.59, -4.47]}
          scale={0.88}
        />
        <mesh
          name="立方体006"
          castShadow
          receiveShadow
          geometry={nodes.立方体006.geometry}
          material={materials.栏杆}
          position={[-4.84, -2.22, -5.93]}
          rotation={[0, 0, -Math.PI]}
          scale={[-1.2, -0.11, -0.11]}
        />
        <mesh
          name="集装箱004"
          castShadow
          receiveShadow
          geometry={nodes.集装箱004.geometry}
          material={materials.排风扇子}
          position={[34.72, -1.09, -4.47]}
          scale={0.88}
        />
        <group
          name="柱体002"
          position={[49, -1.93, -6]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={[-0.01, -1.3, -0.01]}
        >
          <mesh
            name="柱体073"
            castShadow
            receiveShadow
            geometry={nodes.柱体073.geometry}
            material={materials.黑色钢铁}
          />
          <mesh
            name="柱体073_1"
            castShadow
            receiveShadow
            geometry={nodes.柱体073_1.geometry}
            material={materials.蓝色三角架}
          />
        </group>
        <mesh
          name="立方体013"
          castShadow
          receiveShadow
          geometry={nodes.立方体013.geometry}
          material={materials["排风扇子.004"]}
          position={[12.84, -0.64, -0.32]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.41, -0.04, -0.41]}
        />
        <mesh
          name="平面"
          castShadow
          receiveShadow
          geometry={nodes.平面.geometry}
          material={materials.沙漠}
          position={[22.96, -3.07, 8.48]}
          scale={[30.32, 17.63, 30.5]}
        />
        <group
          name="平面012"
          position={[51.9, -1.63, -13.36]}
          rotation={[Math.PI / 4, Math.PI / 2, 0]}
          scale={1.15}
        >
          <mesh
            name="平面016"
            castShadow
            receiveShadow
            geometry={nodes.平面016.geometry}
            material={materials.太阳能电板}
          />
          <mesh
            name="平面016_1"
            castShadow
            receiveShadow
            geometry={nodes.平面016_1.geometry}
            material={materials.楼梯板}
          />
        </group>
        <group
          name="文本002"
          position={[-5.8, -1.81, 26.37]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.31}
        >
          <mesh
            name="文本002_1"
            castShadow
            receiveShadow
            geometry={nodes.文本002_1.geometry}
            material={materials["警示牌蓝色.001"]}
          />
          <mesh
            name="文本002_2"
            castShadow
            receiveShadow
            geometry={nodes.文本002_2.geometry}
            material={materials["材质.003"]}
          />
          <mesh
            name="文本002_3"
            castShadow
            receiveShadow
            geometry={nodes.文本002_3.geometry}
            material={materials["材质.004"]}
          />
          <mesh
            name="文本002_4"
            castShadow
            receiveShadow
            geometry={nodes.文本002_4.geometry}
            material={materials["排风扇子.003"]}
          />
          <lineSegments
            name="文本002_5"
            geometry={nodes.文本002_5.geometry}
            material={materials["警示牌蓝色.001"]}
          />
        </group>
        <group
          name="平面013"
          position={[27.4, -1.63, -13.36]}
          rotation={[Math.PI / 4, Math.PI / 2, 0]}
          scale={1.15}
        >
          <mesh
            name="平面017"
            castShadow
            receiveShadow
            geometry={nodes.平面017.geometry}
            material={materials.太阳能电板}
          />
          <mesh
            name="平面017_1"
            castShadow
            receiveShadow
            geometry={nodes.平面017_1.geometry}
            material={materials.楼梯板}
          />
        </group>
        <group
          name="柱体001"
          position={[7.89, -1.93, 0.12]}
          rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          scale={[-0.01, -1.3, -0.01]}
        >
          <mesh
            name="柱体042"
            castShadow
            receiveShadow
            geometry={nodes.柱体042.geometry}
            material={materials.黑色钢铁}
          />
          <mesh
            name="柱体042_1"
            castShadow
            receiveShadow
            geometry={nodes.柱体042_1.geometry}
            material={materials.蓝色三角架}
          />
        </group>
        <group
          name="平面004"
          position={[6.94, -2.51, -3.04]}
          rotation={[0, Math.PI / 4, 0]}
          scale={[0.72, 0.42, 0.34]}
        >
          <mesh
            name="平面029"
            castShadow
            receiveShadow
            geometry={nodes.平面029.geometry}
            material={materials.排风扇子}
          />
          <mesh
            name="平面029_1"
            castShadow
            receiveShadow
            geometry={nodes.平面029_1.geometry}
            material={materials.警示牌蓝色}
          />
          <mesh
            name="平面029_2"
            castShadow
            receiveShadow
            geometry={nodes.平面029_2.geometry}
            material={materials["材质.002"]}
          />
          <mesh
            name="平面029_3"
            castShadow
            receiveShadow
            geometry={nodes.平面029_3.geometry}
            material={materials.材质}
          />
          <points
            name="平面029_4"
            geometry={nodes.平面029_4.geometry}
            material={materials.排风扇子}
          />
        </group>
        <group
          name="平面002"
          position={[26.13, -1.83, 20.75]}
          rotation={[0, Math.PI / 4, 0]}
          scale={[0.72, 0.42, 0.34]}
        >
          <mesh
            name="平面001_1"
            castShadow
            receiveShadow
            geometry={nodes.平面001_1.geometry}
            material={materials.排风扇子}
          />
          <mesh
            name="平面001_2"
            castShadow
            receiveShadow
            geometry={nodes.平面001_2.geometry}
            material={materials.警示牌蓝色}
          />
          <mesh
            name="平面001_3"
            castShadow
            receiveShadow
            geometry={nodes.平面001_3.geometry}
            material={materials["材质.002"]}
          />
          <mesh
            name="平面001_4"
            castShadow
            receiveShadow
            geometry={nodes.平面001_4.geometry}
            material={materials.材质}
          />
          <points
            name="平面001_5"
            geometry={nodes.平面001_5.geometry}
            material={materials.排风扇子}
          />
        </group>
        <mesh
          name="平面007"
          castShadow
          receiveShadow
          geometry={nodes.平面007.geometry}
          material={materials.沙漠}
          position={[20.13, -3.07, 8.48]}
          scale={[30.32, 17.63, 30.5]}
        />
        <group
          name="立方体008"
          position={[4.03, -1.54, 3.17]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
          scale={[0.4, 0.05, 0.04]}
        >
          <mesh
            name="立方体050"
            castShadow
            receiveShadow
            geometry={nodes.立方体050.geometry}
            material={materials.油suan}
          />
          <mesh
            name="立方体050_1"
            castShadow
            receiveShadow
            geometry={nodes.立方体050_1.geometry}
            material={materials.变压器主体}
          />
        </group>
        <group
          name="立方体043"
          position={[44.11, -1.54, 3.17]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
          scale={[0.4, 0.05, 0.04]}
        >
          <mesh
            name="立方体047"
            castShadow
            receiveShadow
            geometry={nodes.立方体047.geometry}
            material={materials.油suan}
          />
          <mesh
            name="立方体047_1"
            castShadow
            receiveShadow
            geometry={nodes.立方体047_1.geometry}
            material={materials.变压器主体}
          />
        </group>
        <group
          name="立方体007"
          position={[36.74, -1.54, 34.62]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
          scale={[0.4, 0.05, 0.04]}
        >
          <mesh
            name="立方体049"
            castShadow
            receiveShadow
            geometry={nodes.立方体049.geometry}
            material={materials.油suan}
          />
          <mesh
            name="立方体049_1"
            castShadow
            receiveShadow
            geometry={nodes.立方体049_1.geometry}
            material={materials.变压器主体}
          />
        </group>
        <mesh
          // ref={Cube}
          name="myCube"
          castShadow
          receiveShadow
          geometry={nodes.myCube.geometry}
          material={nodes.myCube.material}
          position={[42.73, -1.77, 37.46]}
          scale={0.68}
        />
        <group
          name="机壳"
          position={[40.62, -2.14, 22.96]}
          rotation={[0, 1.57, 0]}
          scale={[0.18, 0.35, 0.54]}
        >
          <mesh
            name="Cube008"
            castShadow
            receiveShadow
            geometry={nodes.Cube008.geometry}
            material={materials["ac.001"]}
          />
          <mesh
            name="Cube008_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_1.geometry}
            material={materials["wire.003"]}
          />
          <mesh
            name="Cube008_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_2.geometry}
            material={materials["ac_stand.003"]}
          />
          <mesh
            name="Cube008_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_3.geometry}
            material={materials["mesh.003"]}
          />
          <mesh
            name="Cube008_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_4.geometry}
            material={materials["Material.006"]}
          />
          <mesh
            name="Cube008_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_5.geometry}
            material={materials["black.007"]}
          />
          <mesh
            name="Cube008_6"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_6.geometry}
            material={materials["sticker.003"]}
          />
          <mesh
            name="Cube008_7"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_7.geometry}
            material={materials.材质}
          />
          <mesh
            name="Cube008_8"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_8.geometry}
            material={materials.警示牌蓝色}
          />
          <mesh
            name="Cube008_9"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_9.geometry}
            material={materials["排风扇子.004"]}
          />
          <mesh
            name="Cube008_10"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_10.geometry}
            material={materials["铁门.003"]}
          />
          <mesh
            name="Cube008_11"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_11.geometry}
            material={materials["阁楼门.003"]}
          />
          <mesh
            name="Cube008_12"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_12.geometry}
            material={materials["black.006"]}
          />
          <mesh
            name="Cube008_13"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_13.geometry}
            material={materials["排风扇子.005"]}
          />
          <mesh
            name="Cube008_14"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_14.geometry}
            material={materials["玻璃.007"]}
          />
          <mesh
            name="Cube008_15"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_15.geometry}
            material={materials["灰墙.004"]}
          />
          <mesh
            name="Cube008_16"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_16.geometry}
            material={materials["玻璃.009"]}
          />
          <lineSegments
            name="Cube008_17"
            geometry={nodes.Cube008_17.geometry}
            material={materials["ac.001"]}
          />
        </group>
        <group
          name="机壳001"
          position={[40.62, -2.14, 14.64]}
          rotation={[0, 1.57, 0]}
          scale={[0.18, 0.35, 0.54]}
        >
          <mesh
            name="Cube011"
            castShadow
            receiveShadow
            geometry={nodes.Cube011.geometry}
            material={materials["ac.001"]}
          />
          <mesh
            name="Cube011_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_1.geometry}
            material={materials["wire.003"]}
          />
          <mesh
            name="Cube011_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_2.geometry}
            material={materials["ac_stand.003"]}
          />
          <mesh
            name="Cube011_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_3.geometry}
            material={materials["mesh.003"]}
          />
          <mesh
            name="Cube011_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_4.geometry}
            material={materials["Material.006"]}
          />
          <mesh
            name="Cube011_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_5.geometry}
            material={materials["black.007"]}
          />
          <mesh
            name="Cube011_6"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_6.geometry}
            material={materials["sticker.003"]}
          />
          <mesh
            name="Cube011_7"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_7.geometry}
            material={materials.材质}
          />
          <mesh
            name="Cube011_8"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_8.geometry}
            material={materials.警示牌蓝色}
          />
          <mesh
            name="Cube011_9"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_9.geometry}
            material={materials["排风扇子.004"]}
          />
          <mesh
            name="Cube011_10"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_10.geometry}
            material={materials["铁门.003"]}
          />
          <mesh
            name="Cube011_11"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_11.geometry}
            material={materials["阁楼门.003"]}
          />
          <mesh
            name="Cube011_12"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_12.geometry}
            material={materials["black.006"]}
          />
          <mesh
            name="Cube011_13"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_13.geometry}
            material={materials["排风扇子.005"]}
          />
          <mesh
            name="Cube011_14"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_14.geometry}
            material={materials["玻璃.007"]}
          />
          <mesh
            name="Cube011_15"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_15.geometry}
            material={materials["灰墙.004"]}
          />
          <mesh
            name="Cube011_16"
            castShadow
            receiveShadow
            geometry={nodes.Cube011_16.geometry}
            material={materials["玻璃.009"]}
          />
          <lineSegments
            name="Cube011_17"
            geometry={nodes.Cube011_17.geometry}
            material={materials["ac.001"]}
          />
        </group>
        <group
          name="机壳002"
          position={[48.33, -2.14, 22.96]}
          rotation={[0, 1.57, 0]}
          scale={[0.18, 0.35, 0.54]}
        >
          <mesh
            name="Cube015"
            castShadow
            receiveShadow
            geometry={nodes.Cube015.geometry}
            material={materials["ac.001"]}
          />
          <mesh
            name="Cube015_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_1.geometry}
            material={materials["wire.003"]}
          />
          <mesh
            name="Cube015_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_2.geometry}
            material={materials["ac_stand.003"]}
          />
          <mesh
            name="Cube015_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_3.geometry}
            material={materials["mesh.003"]}
          />
          <mesh
            name="Cube015_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_4.geometry}
            material={materials["Material.006"]}
          />
          <mesh
            name="Cube015_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_5.geometry}
            material={materials["black.007"]}
          />
          <mesh
            name="Cube015_6"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_6.geometry}
            material={materials["sticker.003"]}
          />
          <mesh
            name="Cube015_7"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_7.geometry}
            material={materials.材质}
          />
          <mesh
            name="Cube015_8"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_8.geometry}
            material={materials.警示牌蓝色}
          />
          <mesh
            name="Cube015_9"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_9.geometry}
            material={materials["排风扇子.004"]}
          />
          <mesh
            name="Cube015_10"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_10.geometry}
            material={materials["铁门.003"]}
          />
          <mesh
            name="Cube015_11"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_11.geometry}
            material={materials["阁楼门.003"]}
          />
          <mesh
            name="Cube015_12"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_12.geometry}
            material={materials["black.006"]}
          />
          <mesh
            name="Cube015_13"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_13.geometry}
            material={materials["排风扇子.005"]}
          />
          <mesh
            name="Cube015_14"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_14.geometry}
            material={materials["玻璃.007"]}
          />
          <mesh
            name="Cube015_15"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_15.geometry}
            material={materials["灰墙.004"]}
          />
          <mesh
            name="Cube015_16"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_16.geometry}
            material={materials["玻璃.009"]}
          />
          <lineSegments
            name="Cube015_17"
            geometry={nodes.Cube015_17.geometry}
            material={materials["ac.001"]}
          />
        </group>
        <group
          name="机壳003"
          position={[48.33, -2.14, 14.64]}
          rotation={[0, 1.57, 0]}
          scale={[0.18, 0.35, 0.54]}
        >
          <mesh
            name="Cube016"
            castShadow
            receiveShadow
            geometry={nodes.Cube016.geometry}
            material={materials["ac.001"]}
          />
          <mesh
            name="Cube016_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_1.geometry}
            material={materials["wire.003"]}
          />
          <mesh
            name="Cube016_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_2.geometry}
            material={materials["ac_stand.003"]}
          />
          <mesh
            name="Cube016_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_3.geometry}
            material={materials["mesh.003"]}
          />
          <mesh
            name="Cube016_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_4.geometry}
            material={materials["Material.006"]}
          />
          <mesh
            name="Cube016_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_5.geometry}
            material={materials["black.007"]}
          />
          <mesh
            name="Cube016_6"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_6.geometry}
            material={materials["sticker.003"]}
          />
          <mesh
            name="Cube016_7"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_7.geometry}
            material={materials.材质}
          />
          <mesh
            name="Cube016_8"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_8.geometry}
            material={materials.警示牌蓝色}
          />
          <mesh
            name="Cube016_9"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_9.geometry}
            material={materials["排风扇子.004"]}
          />
          <mesh
            name="Cube016_10"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_10.geometry}
            material={materials["铁门.003"]}
          />
          <mesh
            name="Cube016_11"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_11.geometry}
            material={materials["阁楼门.003"]}
          />
          <mesh
            name="Cube016_12"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_12.geometry}
            material={materials["black.006"]}
          />
          <mesh
            name="Cube016_13"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_13.geometry}
            material={materials["排风扇子.005"]}
          />
          <mesh
            name="Cube016_14"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_14.geometry}
            material={materials["玻璃.007"]}
          />
          <mesh
            name="Cube016_15"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_15.geometry}
            material={materials["灰墙.004"]}
          />
          <mesh
            name="Cube016_16"
            castShadow
            receiveShadow
            geometry={nodes.Cube016_16.geometry}
            material={materials["玻璃.009"]}
          />
          <lineSegments
            name="Cube016_17"
            geometry={nodes.Cube016_17.geometry}
            material={materials["ac.001"]}
          />
        </group>
        <group
          name="柱体009"
          position={[0.63, 0.05, 27.93]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.02, 0.37, 0.02]}
        >
          <mesh
            name="柱体039"
            castShadow
            receiveShadow
            geometry={nodes.柱体039.geometry}
            material={materials["铁门.004"]}
          />
          <mesh
            name="柱体039_1"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_1.geometry}
            material={materials["材质.001"]}
          />
          <mesh
            name="柱体039_2"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_2.geometry}
            material={nodes.柱体039_2.material}
          />
          <mesh
            name="柱体039_3"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_3.geometry}
            material={materials["阁楼门.004"]}
          />
          <mesh
            name="柱体039_4"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_4.geometry}
            material={materials["灰墙.005"]}
          />
          <mesh
            name="柱体039_5"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_5.geometry}
            material={materials["材质.006"]}
          />
          <mesh
            name="柱体039_6"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_6.geometry}
            material={materials["black.008"]}
          />
          <mesh
            name="柱体039_7"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_7.geometry}
            material={materials["排风扇子.006"]}
          />
          <mesh
            name="柱体039_8"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_8.geometry}
            material={materials["玻璃.011"]}
          />
          <mesh
            name="柱体039_9"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_9.geometry}
            material={materials["材质.007"]}
          />
          <mesh
            name="柱体039_10"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_10.geometry}
            material={materials["把手黑.001"]}
          />
          <mesh
            name="柱体039_11"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_11.geometry}
            material={materials["打卡机后壳.001"]}
          />
          <mesh
            name="柱体039_12"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_12.geometry}
            material={materials["材质.010"]}
          />
          <mesh
            name="柱体039_13"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_13.geometry}
            material={materials["打卡机前壳.001"]}
          />
          <mesh
            name="柱体039_14"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_14.geometry}
            material={materials["材质.011"]}
          />
          <mesh
            name="柱体039_15"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_15.geometry}
            material={materials["材质.013"]}
          />
          <mesh
            name="柱体039_16"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_16.geometry}
            material={materials["材质.014"]}
          />
          <mesh
            name="柱体039_17"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_17.geometry}
            material={materials["ac_stand.004"]}
          />
          <mesh
            name="柱体039_18"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_18.geometry}
            material={materials["wire.004"]}
          />
          <mesh
            name="柱体039_19"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_19.geometry}
            material={materials["ac.003"]}
          />
          <mesh
            name="柱体039_20"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_20.geometry}
            material={materials["mesh.004"]}
          />
          <mesh
            name="柱体039_21"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_21.geometry}
            material={materials["Material.008"]}
          />
          <mesh
            name="柱体039_22"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_22.geometry}
            material={materials["black.009"]}
          />
          <mesh
            name="柱体039_23"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_23.geometry}
            material={materials["sticker.004"]}
          />
          <mesh
            name="柱体039_24"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_24.geometry}
            material={materials["玻璃.012"]}
          />
          <mesh
            name="柱体039_25"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_25.geometry}
            material={materials["玻璃.013"]}
          />
          <mesh
            name="柱体039_26"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_26.geometry}
            material={materials["灭火器红色.001"]}
          />
          <mesh
            name="柱体039_27"
            castShadow
            receiveShadow
            geometry={nodes.柱体039_27.geometry}
            material={materials["灭火器黑色.001"]}
          />
        </group>
        <group
          name="立方体017"
          position={[9.29, -1.42, 36.05]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.19, -0.01, -0.04]}
        >
          <mesh
            name="立方体069"
            castShadow
            receiveShadow
            geometry={nodes.立方体069.geometry}
            material={materials["black.004"]}
          />
          <mesh
            name="立方体069_1"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_1.geometry}
            material={materials["铁门.002"]}
          />
          <mesh
            name="立方体069_2"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_2.geometry}
            material={materials.材质}
          />
          <mesh
            name="立方体069_3"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_3.geometry}
            material={materials["铁门.001"]}
          />
          <mesh
            name="立方体069_4"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_4.geometry}
            material={materials["阁楼门.001"]}
          />
          <mesh
            name="立方体069_5"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_5.geometry}
            material={materials["black.002"]}
          />
          <mesh
            name="立方体069_6"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_6.geometry}
            material={materials["排风扇子.001"]}
          />
          <mesh
            name="立方体069_7"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_7.geometry}
            material={materials["玻璃.002"]}
          />
          <mesh
            name="立方体069_8"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_8.geometry}
            material={materials["楼梯板.001"]}
          />
          <mesh
            name="立方体069_9"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_9.geometry}
            material={materials["沙漠.001"]}
          />
          <mesh
            name="立方体069_10"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_10.geometry}
            material={materials["太阳能电板.001"]}
          />
          <mesh
            name="立方体069_11"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_11.geometry}
            material={materials["栏杆.001"]}
          />
          <mesh
            name="立方体069_12"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_12.geometry}
            material={materials.白色}
          />
          <mesh
            name="立方体069_13"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_13.geometry}
            material={materials["wire.001"]}
          />
          <mesh
            name="立方体069_14"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_14.geometry}
            material={materials["ac_stand.001"]}
          />
          <mesh
            name="立方体069_15"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_15.geometry}
            material={materials["mesh.001"]}
          />
          <mesh
            name="立方体069_16"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_16.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            name="立方体069_17"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_17.geometry}
            material={materials["black.003"]}
          />
          <mesh
            name="立方体069_18"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_18.geometry}
            material={materials["sticker.001"]}
          />
          <mesh
            name="立方体069_19"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_19.geometry}
            material={materials["灰墙.001"]}
          />
          <mesh
            name="立方体069_20"
            castShadow
            receiveShadow
            geometry={nodes.立方体069_20.geometry}
            material={materials["玻璃.003"]}
          />
          <points
            name="立方体069_21"
            geometry={nodes.立方体069_21.geometry}
            material={materials["black.004"]}
          />
        </group>
        <group
          name="立方体009"
          position={[16.12, -1.42, 36.05]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.19, -0.01, -0.04]}
        >
          <mesh
            name="立方体071"
            castShadow
            receiveShadow
            geometry={nodes.立方体071.geometry}
            material={materials["black.004"]}
          />
          <mesh
            name="立方体071_1"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_1.geometry}
            material={materials["铁门.002"]}
          />
          <mesh
            name="立方体071_2"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_2.geometry}
            material={materials.材质}
          />
          <mesh
            name="立方体071_3"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_3.geometry}
            material={materials["铁门.001"]}
          />
          <mesh
            name="立方体071_4"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_4.geometry}
            material={materials["阁楼门.001"]}
          />
          <mesh
            name="立方体071_5"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_5.geometry}
            material={materials["black.002"]}
          />
          <mesh
            name="立方体071_6"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_6.geometry}
            material={materials["排风扇子.001"]}
          />
          <mesh
            name="立方体071_7"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_7.geometry}
            material={materials["玻璃.002"]}
          />
          <mesh
            name="立方体071_8"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_8.geometry}
            material={materials["楼梯板.001"]}
          />
          <mesh
            name="立方体071_9"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_9.geometry}
            material={materials["沙漠.001"]}
          />
          <mesh
            name="立方体071_10"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_10.geometry}
            material={materials["太阳能电板.001"]}
          />
          <mesh
            name="立方体071_11"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_11.geometry}
            material={materials["栏杆.001"]}
          />
          <mesh
            name="立方体071_12"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_12.geometry}
            material={materials.白色}
          />
          <mesh
            name="立方体071_13"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_13.geometry}
            material={materials["wire.001"]}
          />
          <mesh
            name="立方体071_14"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_14.geometry}
            material={materials["ac_stand.001"]}
          />
          <mesh
            name="立方体071_15"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_15.geometry}
            material={materials["mesh.001"]}
          />
          <mesh
            name="立方体071_16"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_16.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            name="立方体071_17"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_17.geometry}
            material={materials["black.003"]}
          />
          <mesh
            name="立方体071_18"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_18.geometry}
            material={materials["sticker.001"]}
          />
          <mesh
            name="立方体071_19"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_19.geometry}
            material={materials["灰墙.001"]}
          />
          <mesh
            name="立方体071_20"
            castShadow
            receiveShadow
            geometry={nodes.立方体071_20.geometry}
            material={materials["玻璃.003"]}
          />
          <points
            name="立方体071_21"
            geometry={nodes.立方体071_21.geometry}
            material={materials["black.004"]}
          />
        </group>
        <group
          name="立方体010"
          position={[-9.77, -1.42, 36.05]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.19, -0.01, -0.04]}
        >
          <mesh
            name="立方体073"
            castShadow
            receiveShadow
            geometry={nodes.立方体073.geometry}
            material={materials["black.004"]}
          />
          <mesh
            name="立方体073_1"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_1.geometry}
            material={materials["铁门.002"]}
          />
          <mesh
            name="立方体073_2"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_2.geometry}
            material={materials.材质}
          />
          <mesh
            name="立方体073_3"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_3.geometry}
            material={materials["铁门.001"]}
          />
          <mesh
            name="立方体073_4"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_4.geometry}
            material={materials["阁楼门.001"]}
          />
          <mesh
            name="立方体073_5"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_5.geometry}
            material={materials["black.002"]}
          />
          <mesh
            name="立方体073_6"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_6.geometry}
            material={materials["排风扇子.001"]}
          />
          <mesh
            name="立方体073_7"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_7.geometry}
            material={materials["玻璃.002"]}
          />
          <mesh
            name="立方体073_8"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_8.geometry}
            material={materials["楼梯板.001"]}
          />
          <mesh
            name="立方体073_9"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_9.geometry}
            material={materials["沙漠.001"]}
          />
          <mesh
            name="立方体073_10"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_10.geometry}
            material={materials["太阳能电板.001"]}
          />
          <mesh
            name="立方体073_11"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_11.geometry}
            material={materials["栏杆.001"]}
          />
          <mesh
            name="立方体073_12"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_12.geometry}
            material={materials.白色}
          />
          <mesh
            name="立方体073_13"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_13.geometry}
            material={materials["wire.001"]}
          />
          <mesh
            name="立方体073_14"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_14.geometry}
            material={materials["ac_stand.001"]}
          />
          <mesh
            name="立方体073_15"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_15.geometry}
            material={materials["mesh.001"]}
          />
          <mesh
            name="立方体073_16"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_16.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            name="立方体073_17"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_17.geometry}
            material={materials["black.003"]}
          />
          <mesh
            name="立方体073_18"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_18.geometry}
            material={materials["sticker.001"]}
          />
          <mesh
            name="立方体073_19"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_19.geometry}
            material={materials["灰墙.001"]}
          />
          <mesh
            name="立方体073_20"
            castShadow
            receiveShadow
            geometry={nodes.立方体073_20.geometry}
            material={materials["玻璃.003"]}
          />
          <points
            name="立方体073_21"
            geometry={nodes.立方体073_21.geometry}
            material={materials["black.004"]}
          />
        </group>
        <group
          name="立方体011"
          position={[46.96, -1.42, 37.43]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-0.19, -0.01, -0.04]}
        >
          <mesh
            name="立方体074"
            castShadow
            receiveShadow
            geometry={nodes.立方体074.geometry}
            material={materials["black.004"]}
          />
          <mesh
            name="立方体074_1"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_1.geometry}
            material={materials["铁门.002"]}
          />
          <mesh
            name="立方体074_2"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_2.geometry}
            material={materials.材质}
          />
          <mesh
            name="立方体074_3"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_3.geometry}
            material={materials["铁门.001"]}
          />
          <mesh
            name="立方体074_4"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_4.geometry}
            material={materials["阁楼门.001"]}
          />
          <mesh
            name="立方体074_5"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_5.geometry}
            material={materials["black.002"]}
          />
          <mesh
            name="立方体074_6"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_6.geometry}
            material={materials["排风扇子.001"]}
          />
          <mesh
            name="立方体074_7"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_7.geometry}
            material={materials["玻璃.002"]}
          />
          <mesh
            name="立方体074_8"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_8.geometry}
            material={materials["楼梯板.001"]}
          />
          <mesh
            name="立方体074_9"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_9.geometry}
            material={materials["沙漠.001"]}
          />
          <mesh
            name="立方体074_10"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_10.geometry}
            material={materials["太阳能电板.001"]}
          />
          <mesh
            name="立方体074_11"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_11.geometry}
            material={materials["栏杆.001"]}
          />
          <mesh
            name="立方体074_12"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_12.geometry}
            material={materials.白色}
          />
          <mesh
            name="立方体074_13"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_13.geometry}
            material={materials["wire.001"]}
          />
          <mesh
            name="立方体074_14"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_14.geometry}
            material={materials["ac_stand.001"]}
          />
          <mesh
            name="立方体074_15"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_15.geometry}
            material={materials["mesh.001"]}
          />
          <mesh
            name="立方体074_16"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_16.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            name="立方体074_17"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_17.geometry}
            material={materials["black.003"]}
          />
          <mesh
            name="立方体074_18"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_18.geometry}
            material={materials["sticker.001"]}
          />
          <mesh
            name="立方体074_19"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_19.geometry}
            material={materials["灰墙.001"]}
          />
          <mesh
            name="立方体074_20"
            castShadow
            receiveShadow
            geometry={nodes.立方体074_20.geometry}
            material={materials["玻璃.003"]}
          />
          <points
            name="立方体074_21"
            geometry={nodes.立方体074_21.geometry}
            material={materials["black.004"]}
          />
        </group>
        <group
          name="Cube004"
          position={[21.77, 0.6, 29.18]}
          rotation={[0, 1.57, 0]}
          scale={[0.03, 0.34, 0.3]}
        >
          <mesh
            name="Cube014"
            castShadow
            receiveShadow
            geometry={nodes.Cube014.geometry}
            material={materials.灰墙}
          />
          <mesh
            name="Cube014_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_1.geometry}
            material={materials["玻璃.004"]}
          />
          <mesh
            name="Cube014_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_2.geometry}
            material={nodes.Cube014_2.material}
          />
          <mesh
            name="Cube014_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_3.geometry}
            material={materials.黄色}
          />
          <mesh
            name="Cube014_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_4.geometry}
            material={materials.白色}
          />
          <mesh
            name="Cube014_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_5.geometry}
            material={materials["楼梯板.004"]}
          />
          <mesh
            name="Cube014_6"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_6.geometry}
            material={materials["白色.002"]}
          />
          <mesh
            name="Cube014_7"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_7.geometry}
            material={materials["白色.001"]}
          />
          <mesh
            name="Cube014_8"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_8.geometry}
            material={materials.铁门}
          />
          <mesh
            name="Cube014_9"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_9.geometry}
            material={materials.阁楼门}
          />
          <mesh
            name="Cube014_10"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_10.geometry}
            material={materials.black}
          />
          <mesh
            name="Cube014_11"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_11.geometry}
            material={materials["排风扇子.002"]}
          />
          <mesh
            name="Cube014_12"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_12.geometry}
            material={materials.玻璃}
          />
          <mesh
            name="Cube014_13"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_13.geometry}
            material={materials.ac}
          />
          <mesh
            name="Cube014_14"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_14.geometry}
            material={materials["wire.002"]}
          />
          <mesh
            name="Cube014_15"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_15.geometry}
            material={materials.ac_stand}
          />
          <mesh
            name="Cube014_16"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_16.geometry}
            material={materials.mesh}
          />
          <mesh
            name="Cube014_17"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_17.geometry}
            material={materials["Material.005"]}
          />
          <mesh
            name="Cube014_18"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_18.geometry}
            material={materials["black.001"]}
          />
          <mesh
            name="Cube014_19"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_19.geometry}
            material={materials.sticker}
          />
        </group>
        <group name="油桶006" position={[36.39, -1.94, 1.63]} scale={1.82}>
          <mesh
            name="001-0-1"
            castShadow
            receiveShadow
            geometry={nodes["001-0-1"].geometry}
            material={materials["Top.002"]}
          />
          <points
            name="001-0-1_1"
            geometry={nodes["001-0-1_1"].geometry}
            material={materials["Top.002"]}
          />
        </group>
        <mesh
          name="油桶005"
          castShadow
          receiveShadow
          geometry={nodes.油桶005.geometry}
          material={materials["Top.002"]}
          position={[45.05, -12.15, 34.23]}
          scale={1.82}
        />
        <mesh
          name="油桶004"
          castShadow
          receiveShadow
          geometry={nodes.油桶004.geometry}
          material={materials["Top.002"]}
          position={[38.81, -1.27, -1.11]}
          scale={1.82}
        />
        <mesh
          name="油桶003"
          castShadow
          receiveShadow
          geometry={nodes.油桶003.geometry}
          material={materials["Top.002"]}
          position={[37.11, -1.27, 1.16]}
          scale={1.82}
        />
        <mesh
          name="油桶001"
          castShadow
          receiveShadow
          geometry={nodes.油桶001.geometry}
          material={materials["Top.002"]}
          position={[35.37, -1.27, 1.16]}
          scale={1.82}
        />
        <mesh
          name="油桶002"
          castShadow
          receiveShadow
          geometry={nodes.油桶002.geometry}
          material={materials["Top.002"]}
          position={[38.81, -1.27, 1.89]}
          scale={1.82}
        />
        <group name="油桶007" position={[-11.33, -1.94, 6.87]} scale={1.82}>
          <mesh
            name="001-0-1010"
            castShadow
            receiveShadow
            geometry={nodes["001-0-1010"].geometry}
            material={materials["Top.002"]}
          />
          <points
            name="001-0-1010_1"
            geometry={nodes["001-0-1010_1"].geometry}
            material={materials["Top.002"]}
          />
        </group>
        <mesh
          name="油桶008"
          castShadow
          receiveShadow
          geometry={nodes.油桶008.geometry}
          material={materials["Top.002"]}
          position={[-2.67, -12.15, 39.47]}
          scale={1.82}
        />
        <mesh
          name="油桶009"
          castShadow
          receiveShadow
          geometry={nodes.油桶009.geometry}
          material={materials["Top.002"]}
          position={[-8.91, -1.27, 4.13]}
          scale={1.82}
        />
        <mesh
          name="油桶010"
          castShadow
          receiveShadow
          geometry={nodes.油桶010.geometry}
          material={materials["Top.002"]}
          position={[-10.61, -1.27, 6.4]}
          scale={1.82}
        />
        <mesh
          name="油桶011"
          castShadow
          receiveShadow
          geometry={nodes.油桶011.geometry}
          material={materials["Top.002"]}
          position={[-12.35, -1.27, 6.4]}
          scale={1.82}
        />
        <mesh
          name="油桶012"
          castShadow
          receiveShadow
          geometry={nodes.油桶012.geometry}
          material={materials["Top.002"]}
          position={[-8.91, -1.27, 7.13]}
          scale={1.82}
        />
        <mesh
          name="油桶013"
          castShadow
          receiveShadow
          geometry={nodes.油桶013.geometry}
          material={materials["Top.002"]}
          position={[-9.82, -1.27, 8.56]}
          scale={1.82}
        />
        <mesh
          name="油桶014"
          castShadow
          receiveShadow
          geometry={nodes.油桶014.geometry}
          material={materials["Top.002"]}
          position={[-12.06, -1.27, 8.06]}
          scale={1.82}
        />
        <group
          name="送油房002"
          position={[-9.84, -0.44, 19.33]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.53}
        >
          <mesh
            name="002-0-2002"
            castShadow
            receiveShadow
            geometry={nodes["002-0-2002"].geometry}
            material={materials["Mid.002"]}
          />
          <mesh
            name="002-0-2002_1"
            castShadow
            receiveShadow
            geometry={nodes["002-0-2002_1"].geometry}
            material={materials["Bottom.002"]}
          />
        </group>
        <group
          name="塔吊"
          position={[46.35, 5.13, 1.8]}
          rotation={[-Math.PI, Math.PI / 4, -Math.PI]}
          scale={0.53}
        >
          <mesh
            name="000-0-0006"
            castShadow
            receiveShadow
            geometry={nodes["000-0-0006"].geometry}
            material={materials["Bottom.003"]}
          />
          <mesh
            name="000-0-0006_1"
            castShadow
            receiveShadow
            geometry={nodes["000-0-0006_1"].geometry}
            material={materials["Top.001"]}
          />
        </group>
        <group
          name="立方体092"
          position={[19.26, 1.13, 10.72]}
          scale={[-3.98, -0.1, -2.88]}
        >
          <mesh
            name="立方体"
            castShadow
            receiveShadow
            geometry={nodes.立方体.geometry}
            material={materials["电塔红.001"]}
          />
          <mesh
            name="立方体_1"
            castShadow
            receiveShadow
            geometry={nodes.立方体_1.geometry}
            material={materials["楼梯板.002"]}
          />
          <mesh
            name="立方体_2"
            castShadow
            receiveShadow
            geometry={nodes.立方体_2.geometry}
            material={materials["沙漠.002"]}
          />
          <mesh
            name="立方体_3"
            castShadow
            receiveShadow
            geometry={nodes.立方体_3.geometry}
            material={nodes.立方体_3.material}
          />
          <mesh
            name="立方体_4"
            castShadow
            receiveShadow
            geometry={nodes.立方体_4.geometry}
            material={materials["栏杆.002"]}
          />
          <mesh
            name="立方体_5"
            castShadow
            receiveShadow
            geometry={nodes.立方体_5.geometry}
            material={materials["Material.001"]}
          />
          <mesh
            name="立方体_6"
            castShadow
            receiveShadow
            geometry={nodes.立方体_6.geometry}
            material={materials["楼梯板子.001"]}
          />
          <mesh
            name="立方体_7"
            castShadow
            receiveShadow
            geometry={nodes.立方体_7.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            name="立方体_8"
            castShadow
            receiveShadow
            geometry={nodes.立方体_8.geometry}
            material={materials["电塔红.002"]}
          />
          <mesh
            name="立方体_9"
            castShadow
            receiveShadow
            geometry={nodes.立方体_9.geometry}
            material={materials["电塔白.001"]}
          />
          <mesh
            name="立方体_10"
            castShadow
            receiveShadow
            geometry={nodes.立方体_10.geometry}
            material={materials["楼梯板.003"]}
          />
          <mesh
            name="立方体_11"
            castShadow
            receiveShadow
            geometry={nodes.立方体_11.geometry}
            material={materials["线圈.001"]}
          />
          <mesh
            name="立方体_12"
            castShadow
            receiveShadow
            geometry={nodes.立方体_12.geometry}
            material={materials["玻璃.001"]}
          />
          <mesh
            name="立方体_13"
            castShadow
            receiveShadow
            geometry={nodes.立方体_13.geometry}
            material={materials["Bottom.001"]}
          />
          <lineSegments
            name="立方体_14"
            geometry={nodes.立方体_14.geometry}
            material={materials["电塔红.001"]}
          />
          <points
            name="立方体_15"
            geometry={nodes.立方体_15.geometry}
            material={materials["电塔红.001"]}
          />
        </group>
      </group>
    </group>
  );
}

// useGLTF.preload("/陆地钻井平台.gltf");