/*
 * @Author: mwxp (ljh) 
 * @Date: 2022-06-21 20:21:37 
 * @Last Modified by: mwxp (ljh)
 * @Last Modified time: 2022-07-13 14:29:10
 */

// import * as THREE from 'three'
import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations ,useTexture} from "@react-three/drei";
import { extend } from '@react-three/fiber'
import { Water } from 'three-stdlib'
import { useControls } from 'leva'


extend({ Water })

export default function Model(props) {
	const group = useRef();

	// const bakedTexture = useTexture('/baked1.png')
	// const { nodes, materials, animations } = useGLTF("/泵动画.gltf");
	const { nodes, materials, animations } = useGLTF("/泵动画.gltf");
	const { actions, mixer } = useAnimations(animations, group);

	const crProps = useControls({
		轴1:{
			min:1,
			max:100,
			value:10
		},
		轴2:{
			min:1,
			max:100,
			value:10
		},
		水管:{
			min:1,
			max:100,
			value:10
		}
	})

	useEffect(() => {
		// console.log(crProps.柱体);
		actions.柱体Action.setDuration(crProps.轴1)
		actions.柱体Action.play()
		actions["柱体.004Action"].setDuration(crProps.轴2)
		actions["柱体.004Action"].play()
		actions["KeyAction.002"].setDuration(crProps.水管)
		actions["KeyAction.002"].play()
	}, [mixer,crProps]);

	console.log('----->>',nodes);

	return (
		<group ref={group} {...props} dispose={null}>
			<group name="Scene">
				<mesh
					name="柱体008"
					castShadow
					receiveShadow
					geometry={nodes.柱体008.geometry}
					// material={materials["材质.004"]}
					material={materials["材质.005"]}
					position={[0.07, 1.27, -0.83]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={0.29}
				/>
				<mesh
					name="NURBS_路径"
					castShadow
					receiveShadow
					geometry={nodes.NURBS_路径.geometry}
					material={materials["材质.005"]}
					morphTargetDictionary={nodes.NURBS_路径.morphTargetDictionary}
					morphTargetInfluences={nodes.NURBS_路径.morphTargetInfluences}
					position={[-0.11, 1.33, -6.11]}
					scale={0.59}
				/>
				<mesh
					name="柱体"
					castShadow
					receiveShadow
					geometry={nodes.柱体.geometry}
					material={materials["材质.005"]}
					position={[0, 1.29, -0.01]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={0.25}
				>
					{/* <meshPhongMaterial shininess={60} map={bakedTexture} map-flipY={false} /> */}
				</mesh>
				<mesh
					name="柱体002"
					castShadow
					receiveShadow
					geometry={nodes.柱体002.geometry}
					material={materials["材质.005"]}
					position={[0.01, 4.47, -2.06]}
					scale={0.53}
				>
					{/* <meshBasicMaterial map={bakedTexture} map-flipY={false} /> */}
				</mesh>
				<mesh
					name="柱体005"
					castShadow
					receiveShadow
					geometry={nodes.柱体005.geometry}
					material={materials["材质.005"]}
					position={[0, 0.04, -3.12]}
					scale={0.36}
				/>
				<mesh
					name="柱体007"
					castShadow
					receiveShadow
					geometry={nodes.柱体007.geometry}
					material={materials["材质.005"]}
					position={[0, -0.38, -3.73]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={0.32}
				/>
				<group
					name="柱体001"
					position={[0, 1.29, -0.01]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={0.25}
				>
					<mesh
						name="柱体001_1"
						castShadow
						receiveShadow
						geometry={nodes.柱体001_1.geometry}
						material={materials["材质.001"]}
					/>
					<mesh
						name="柱体001_2"
						castShadow
						receiveShadow
						geometry={nodes.柱体001_2.geometry}
						material={materials["材质.005"]}
					>
						{/* <meshBasicMaterial map={bakedTexture} map-flipY={false} /> */}
					</mesh>
				</group>
				<mesh
					name="柱体006"
					castShadow
					receiveShadow
					geometry={nodes.柱体006.geometry}
					material={materials["材质.001"]}
					position={[0, -0.33, -3.12]}
					rotation={[0, 0, -Math.PI / 2]}
					scale={0.47}
				/>
				<group name="柱体004" position={[0.01, 4.47, -2.06]} scale={0.48}>
					<mesh
						name="柱体004_1"
						castShadow
						receiveShadow
						geometry={nodes.柱体004_1.geometry}
						material={materials["材质.001"]}
					/>
					<mesh
						name="柱体004_2"
						castShadow
						receiveShadow
						geometry={nodes.柱体004_2.geometry}
						material={materials["材质.011"]}
					/>
				</group>
				<group
					name="柱体003"
					position={[0, 1.26, -0.79]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={0.37}
				>
					<mesh
						name="柱体003_1"
						castShadow
						receiveShadow
						geometry={nodes.柱体003_1.geometry}
						material={materials["材质.005"]}
					/>
					<mesh
						name="柱体003_2"
						castShadow
						receiveShadow
						geometry={nodes.柱体003_2.geometry}
						material={materials["材质.011"]}
					/>
				</group>
			</group>
		</group>
	);
}

// useGLTF.preload("/泵动画.gltf");
