import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function SmallSubstation({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/变压室.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        name="立方体"
        castShadow
        receiveShadow
        geometry={nodes.立方体.geometry}
        material={nodes.立方体.material}
        position={[-38.68, 0, 0]}
        scale={[10.69, 2.47, 8.66]}
      />
      <group
        name="立方体003"
        position={[-33.83, 0.37, -6.41]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.64, 2.07, 1.1]}
      >
        <mesh
          name="立方体003_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体003_1.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体003_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体003_2.geometry}
          material={materials["材质.007"]}
        />
      </group>
      <mesh
        name="柱体008"
        castShadow
        receiveShadow
        geometry={nodes.柱体008.geometry}
        material={materials.按钮}
        position={[-31.85, -0.33, -8.17]}
        rotation={[-Math.PI, 0, 0]}
        scale={[0.06, 0.18, 0.06]}
      />
      <mesh
        name="柱体009"
        castShadow
        receiveShadow
        geometry={nodes.柱体009.geometry}
        material={nodes.柱体009.material}
        position={[-32.37, 0.32, -8.17]}
        rotation={[-Math.PI, 0, 0]}
        scale={[0.06, 0.14, 0.06]}
      />
      <mesh
        name="柱体010"
        castShadow
        receiveShadow
        geometry={nodes.柱体010.geometry}
        material={materials.打卡机前壳}
        position={[-32.37, -0.26, -8.17]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-0.06, -0.08, -0.06]}
      />
      <mesh
        name="柱体012"
        castShadow
        receiveShadow
        geometry={nodes.柱体012.geometry}
        material={nodes.柱体012.material}
        position={[-32.37, 0.25, -8.17]}
        rotation={[-Math.PI, 0, 0]}
        scale={[0.06, 0.14, 0.06]}
      />
      <mesh
        name="立方体004"
        castShadow
        receiveShadow
        geometry={nodes.立方体004.geometry}
        material={nodes.立方体004.material}
        position={[-32.07, 0.23, -8.17]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={[-0.58, -0.14, -0.4]}
      />
      <mesh
        name="立方体037"
        castShadow
        receiveShadow
        geometry={nodes.立方体037.geometry}
        material={materials.变压器主体}
        position={[-32.07, 0.23, -8.1]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={[-0.46, -0.11, -0.31]}
      />
      <mesh
        name="柱体013"
        castShadow
        receiveShadow
        geometry={nodes.柱体013.geometry}
        material={materials.铁柱子}
        position={[-31.59, -0.02, -8.06]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.04}
      />
      <mesh
        name="柱体014"
        castShadow
        receiveShadow
        geometry={nodes.柱体014.geometry}
        material={materials.铁柱子}
        position={[-31.59, 0.5, -8.06]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.04}
      />
      <mesh
        name="柱体015"
        castShadow
        receiveShadow
        geometry={nodes.柱体015.geometry}
        material={materials.铁柱子}
        position={[-32.54, 0.5, -8.06]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.04}
      />
      <mesh
        name="柱体096"
        castShadow
        receiveShadow
        geometry={nodes.柱体096.geometry}
        material={materials.铁柱子}
        position={[-32.55, -0.02, -8.06]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.04}
      />
      <lineSegments
        name="圆环"
        geometry={nodes.圆环.geometry}
        material={nodes.圆环.material}
        position={[-32.07, 0.23, -8.17]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.36}
      />
      <mesh
        name="立方体038"
        castShadow
        receiveShadow
        geometry={nodes.立方体038.geometry}
        material={nodes.立方体038.material}
        position={[-32.07, 0.23, -8.09]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.3, 0.12, 0.23]}
      />
      <mesh
        name="立方体039"
        castShadow
        receiveShadow
        geometry={nodes.立方体039.geometry}
        material={materials["材质.001"]}
        position={[-32.07, 0.23, -8]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.27, 0.04, 0.2]}
      />
      <group
        name="立方体040"
        position={[-43.4, 1.93, 1.28]}
        rotation={[0, 1.57, 0]}
        scale={[0.09, 0.15, 0.01]}
      >
        <mesh
          name="立方体031_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体031_1.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体031_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体031_2.geometry}
          material={materials.变压器主体}
        />
      </group>
      <group
        name="立方体041"
        position={[-43.4, 1.93, -1.91]}
        rotation={[0, 1.57, 0]}
        scale={[0.09, 0.15, 0.01]}
      >
        <mesh
          name="立方体045_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体045_1.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体045_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体045_2.geometry}
          material={materials.变压器主体}
        />
      </group>
      <group
        name="立方体042"
        position={[-41.56, 1.93, 1.28]}
        rotation={[0, 1.57, 0]}
        scale={[0.09, 0.15, 0.01]}
      >
        <mesh
          name="立方体046_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体046_1.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体046_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体046_2.geometry}
          material={materials.变压器主体}
        />
      </group>
      <group
        name="立方体043"
        position={[-41.56, 1.93, -1.91]}
        rotation={[0, 1.57, 0]}
        scale={[0.09, 0.15, 0.01]}
      >
        <mesh
          name="立方体047_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体047_1.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体047_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体047_2.geometry}
          material={materials.变压器主体}
        />
      </group>
      <mesh
        name="立方体044"
        castShadow
        receiveShadow
        geometry={nodes.立方体044.geometry}
        material={materials.变压器主体}
        position={[-41.16, 1.54, -2.23]}
        rotation={[0, -1.57, 0]}
        scale={[0.03, 0.15, 0.15]}
      />
      <mesh
        name="立方体045"
        castShadow
        receiveShadow
        geometry={nodes.立方体045.geometry}
        material={materials.变压器主体}
        position={[-42.46, 1.76, -0.3]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[1.39, 0.05, 2.05]}
      />
      <mesh
        name="立方体046"
        castShadow
        receiveShadow
        geometry={nodes.立方体046.geometry}
        material={materials.变压器主体}
        position={[-41.16, 1.54, 1.64]}
        rotation={[0, -1.57, 0]}
        scale={[0.03, 0.15, 0.15]}
      />
      <mesh
        name="立方体047"
        castShadow
        receiveShadow
        geometry={nodes.立方体047.geometry}
        material={materials.变压器主体}
        position={[-40.44, 1.29, -1.48]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.11, 0.02, 0.11]}
      />
      <group
        name="立方体048"
        position={[-42.47, -1.17, -1.06]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[2.84, 0.17, 0.17]}
      >
        <mesh
          name="立方体052_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体052_1.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体052_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体052_2.geometry}
          material={materials.变压器主体}
        />
      </group>
      <mesh
        name="立方体049"
        castShadow
        receiveShadow
        geometry={nodes.立方体049.geometry}
        material={materials.变压器主体}
        position={[-44.5, 1.29, 0.88]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[0.11, 0.02, 0.11]}
      />
      <mesh
        name="立方体050"
        castShadow
        receiveShadow
        geometry={nodes.立方体050.geometry}
        material={materials.散热片}
        position={[-43.76, 0.28, 1.35]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-0.73, -1.03, -0.01]}
      />
      <mesh
        name="立方体051"
        castShadow
        receiveShadow
        geometry={nodes.立方体051.geometry}
        material={materials.散热片}
        position={[-43.48, 0.27, 1.11]}
        rotation={[0, 0, -Math.PI]}
        scale={[-0.01, -1.04, -1.23]}
      />
      <mesh
        name="立方体052"
        castShadow
        receiveShadow
        geometry={nodes.立方体052.geometry}
        material={materials.变压器主体}
        position={[-42.47, 0.23, -0.3]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[1.23, 1.23, 1.85]}
      />
      <mesh
        name="柱体098"
        castShadow
        receiveShadow
        geometry={nodes.柱体098.geometry}
        material={materials.变压器主体}
        position={[-41.3, 2.83, 1.58]}
        rotation={[0, 0, -Math.PI]}
        scale={[0.05, 0.41, 0.05]}
      />
      <mesh
        name="柱体099"
        castShadow
        receiveShadow
        geometry={nodes.柱体099.geometry}
        material={materials.变压器主体}
        position={[-41.3, 1.9, 1.58]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.05, 0.41, 0.05]}
      />
      <mesh
        name="柱体100"
        castShadow
        receiveShadow
        geometry={nodes.柱体100.geometry}
        material={materials.散热片}
        position={[-41.3, 2.37, 1.58]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.05, 0.41, 0.05]}
      />
      <mesh
        name="柱体101"
        castShadow
        receiveShadow
        geometry={nodes.柱体101.geometry}
        material={materials.按钮}
        position={[-42.45, 2.01, -1.94]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.13, 0.03, 0.13]}
      />
      <mesh
        name="柱体102"
        castShadow
        receiveShadow
        geometry={nodes.柱体102.geometry}
        material={materials.打卡机前壳}
        position={[-42.45, 1.97, -1.94]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.14, 0.06, 0.14]}
      />
      <mesh
        name="柱体103"
        castShadow
        receiveShadow
        geometry={nodes.柱体103.geometry}
        material={materials.打卡机前壳}
        position={[-42.38, 1.97, -1.83]}
        scale={[0.06, 0, 0.06]}
      />
      <mesh
        name="柱体104"
        castShadow
        receiveShadow
        geometry={nodes.柱体104.geometry}
        material={materials.打卡机前壳}
        position={[-42.32, 1.97, -1.94]}
        rotation={[0, Math.PI / 3, 0]}
        scale={[0.06, 0, 0.06]}
      />
      <mesh
        name="柱体105"
        castShadow
        receiveShadow
        geometry={nodes.柱体105.geometry}
        material={materials.打卡机前壳}
        position={[-42.51, 1.97, -1.83]}
        rotation={[0, -1.05, 0]}
        scale={[0.06, 0, 0.06]}
      />
      <mesh
        name="柱体106"
        castShadow
        receiveShadow
        geometry={nodes.柱体106.geometry}
        material={materials.打卡机前壳}
        position={[-42.57, 1.97, -1.94]}
        rotation={[Math.PI, -Math.PI / 3, Math.PI]}
        scale={[0.06, 0, 0.06]}
      />
      <mesh
        name="柱体107"
        castShadow
        receiveShadow
        geometry={nodes.柱体107.geometry}
        material={materials.打卡机前壳}
        position={[-42.51, 1.97, -2.04]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.06, 0, 0.06]}
      />
      <mesh
        name="柱体108"
        castShadow
        receiveShadow
        geometry={nodes.柱体108.geometry}
        material={materials.打卡机前壳}
        position={[-42.39, 1.97, -2.05]}
        rotation={[-Math.PI, Math.PI / 3, -Math.PI]}
        scale={[0.06, 0, 0.06]}
      />
      <lineSegments
        name="柱体109"
        geometry={nodes.柱体109.geometry}
        material={nodes.柱体109.material}
        position={[-42.45, -3.27, -0.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.22, 0.01, 0.22]}
      />
      <mesh
        name="柱体110"
        castShadow
        receiveShadow
        geometry={nodes.柱体110.geometry}
        material={materials.打卡机前壳}
        position={[-42.45, 1.81, -1.94]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.17, 0, 0.17]}
      />
      <mesh
        name="柱体111"
        castShadow
        receiveShadow
        geometry={nodes.柱体111.geometry}
        material={materials.铁柱子}
        position={[-43, 2.58, -0.31]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.18}
      />
      <mesh
        name="柱体112"
        castShadow
        receiveShadow
        geometry={nodes.柱体112.geometry}
        material={materials.变压器主体}
        position={[-43, 2.59, -0.31]}
        rotation={[0, Math.PI / 3, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体113"
        castShadow
        receiveShadow
        geometry={nodes.柱体113.geometry}
        material={materials.变压器主体}
        position={[-43, 2.57, -0.31]}
        rotation={[0, Math.PI / 6, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体114"
        castShadow
        receiveShadow
        geometry={nodes.柱体114.geometry}
        material={materials.变压器主体}
        position={[-43, 2.52, -0.31]}
        scale={[-0.15, -0.04, -0.15]}
      />
      <mesh
        name="柱体115"
        castShadow
        receiveShadow
        geometry={nodes.柱体115.geometry}
        material={materials.散热片}
        position={[-43, 2.55, -0.31]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <mesh
        name="柱体116"
        castShadow
        receiveShadow
        geometry={nodes.柱体116.geometry}
        material={materials.散热片}
        position={[-43, 2.4, -0.31]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <mesh
        name="柱体117"
        castShadow
        receiveShadow
        geometry={nodes.柱体117.geometry}
        material={materials.test}
        position={[-43, 2.45, -0.31]}
        scale={[-0.37, -0.02, -0.37]}
      />
      <mesh
        name="柱体118"
        castShadow
        receiveShadow
        geometry={nodes.柱体118.geometry}
        material={materials.test}
        position={[-43, 2.19, -0.32]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体119"
        castShadow
        receiveShadow
        geometry={nodes.柱体119.geometry}
        material={materials.test}
        position={[-43, 2.32, -0.31]}
        scale={[-0.26, -0.01, -0.26]}
      />
      <mesh
        name="柱体120"
        castShadow
        receiveShadow
        geometry={nodes.柱体120.geometry}
        material={materials.test}
        position={[-43, 2.05, -0.32]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体121"
        castShadow
        receiveShadow
        geometry={nodes.柱体121.geometry}
        material={materials.test}
        position={[-43, 1.85, -0.32]}
        scale={[-0.24, -0.04, -0.24]}
      />
      <mesh
        name="柱体122"
        castShadow
        receiveShadow
        geometry={nodes.柱体122.geometry}
        material={materials.铁柱子}
        position={[-43, 2.58, -1.51]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.18}
      />
      <mesh
        name="柱体123"
        castShadow
        receiveShadow
        geometry={nodes.柱体123.geometry}
        material={materials.变压器主体}
        position={[-43, 2.59, -1.51]}
        rotation={[0, Math.PI / 3, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体124"
        castShadow
        receiveShadow
        geometry={nodes.柱体124.geometry}
        material={materials.变压器主体}
        position={[-43, 2.57, -1.51]}
        rotation={[0, Math.PI / 6, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体125"
        castShadow
        receiveShadow
        geometry={nodes.柱体125.geometry}
        material={materials.变压器主体}
        position={[-43, 2.52, -1.51]}
        scale={[-0.15, -0.04, -0.15]}
      />
      <mesh
        name="柱体126"
        castShadow
        receiveShadow
        geometry={nodes.柱体126.geometry}
        material={materials.散热片}
        position={[-43, 2.55, -1.51]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <mesh
        name="柱体127"
        castShadow
        receiveShadow
        geometry={nodes.柱体127.geometry}
        material={materials.test}
        position={[-43, 2.45, -1.51]}
        scale={[-0.37, -0.02, -0.37]}
      />
      <mesh
        name="柱体128"
        castShadow
        receiveShadow
        geometry={nodes.柱体128.geometry}
        material={materials.test}
        position={[-43, 2.19, -1.52]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体129"
        castShadow
        receiveShadow
        geometry={nodes.柱体129.geometry}
        material={materials.铁柱子}
        position={[-43, 2.58, 0.89]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.18}
      />
      <mesh
        name="柱体130"
        castShadow
        receiveShadow
        geometry={nodes.柱体130.geometry}
        material={materials.变压器主体}
        position={[-43, 2.59, 0.89]}
        rotation={[0, Math.PI / 3, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体131"
        castShadow
        receiveShadow
        geometry={nodes.柱体131.geometry}
        material={materials.变压器主体}
        position={[-43, 2.57, 0.89]}
        rotation={[0, Math.PI / 6, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体132"
        castShadow
        receiveShadow
        geometry={nodes.柱体132.geometry}
        material={materials.变压器主体}
        position={[-43, 2.52, 0.89]}
        scale={[-0.15, -0.04, -0.15]}
      />
      <mesh
        name="柱体133"
        castShadow
        receiveShadow
        geometry={nodes.柱体133.geometry}
        material={materials.散热片}
        position={[-43, 2.55, 0.89]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <mesh
        name="柱体134"
        castShadow
        receiveShadow
        geometry={nodes.柱体134.geometry}
        material={materials.散热片}
        position={[-43, 2.4, 0.89]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <mesh
        name="柱体135"
        castShadow
        receiveShadow
        geometry={nodes.柱体135.geometry}
        material={materials.test}
        position={[-43, 2.45, 0.89]}
        scale={[-0.37, -0.02, -0.37]}
      />
      <mesh
        name="柱体136"
        castShadow
        receiveShadow
        geometry={nodes.柱体136.geometry}
        material={materials.test}
        position={[-43, 2.19, 0.88]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体137"
        castShadow
        receiveShadow
        geometry={nodes.柱体137.geometry}
        material={materials.test}
        position={[-43, 2.32, 0.88]}
        scale={[-0.26, -0.01, -0.26]}
      />
      <mesh
        name="柱体138"
        castShadow
        receiveShadow
        geometry={nodes.柱体138.geometry}
        material={materials.test}
        position={[-43, 2.32, -1.52]}
        scale={[-0.26, -0.01, -0.26]}
      />
      <mesh
        name="柱体139"
        castShadow
        receiveShadow
        geometry={nodes.柱体139.geometry}
        material={materials.test}
        position={[-43, 2.05, 0.88]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体140"
        castShadow
        receiveShadow
        geometry={nodes.柱体140.geometry}
        material={materials.test}
        position={[-43, 2.05, -1.52]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体141"
        castShadow
        receiveShadow
        geometry={nodes.柱体141.geometry}
        material={materials.test}
        position={[-43, 1.85, 0.88]}
        scale={[-0.24, -0.04, -0.24]}
      />
      <mesh
        name="柱体142"
        castShadow
        receiveShadow
        geometry={nodes.柱体142.geometry}
        material={materials.test}
        position={[-43, 1.85, -1.52]}
        scale={[-0.24, -0.04, -0.24]}
      />
      <mesh
        name="柱体143"
        castShadow
        receiveShadow
        geometry={nodes.柱体143.geometry}
        material={nodes.柱体143.material}
        position={[-41.92, 1.87, -1.5]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体144"
        castShadow
        receiveShadow
        geometry={nodes.柱体144.geometry}
        material={materials.变压器主体}
        position={[-41.92, 2.33, -1.49]}
        rotation={[Math.PI, -Math.PI / 6, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体145"
        castShadow
        receiveShadow
        geometry={nodes.柱体145.geometry}
        material={materials.变压器主体}
        position={[-41.92, 2.3, -1.49]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体146"
        castShadow
        receiveShadow
        geometry={nodes.柱体146.geometry}
        material={materials.散热片}
        position={[-41.92, 2.22, -1.49]}
        scale={[-0.1, -0.02, -0.1]}
      />
      <mesh
        name="柱体147"
        castShadow
        receiveShadow
        geometry={nodes.柱体147.geometry}
        material={materials.变压器主体}
        position={[-41.92, 2.23, -1.49]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体148"
        castShadow
        receiveShadow
        geometry={nodes.柱体148.geometry}
        material={materials.test}
        position={[-41.92, 2.18, -1.49]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.11}
      />
      <mesh
        name="柱体149"
        castShadow
        receiveShadow
        geometry={nodes.柱体149.geometry}
        material={nodes.柱体149.material}
        position={[-41.92, 2.05, -1.49]}
        scale={[-0.11, -0.02, -0.11]}
      />
      <mesh
        name="柱体150"
        castShadow
        receiveShadow
        geometry={nodes.柱体150.geometry}
        material={materials.铁柱子}
        position={[-41.92, 2.33, -1.49]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.05, 0.33, 0.05]}
      />
      <mesh
        name="柱体151"
        castShadow
        receiveShadow
        geometry={nodes.柱体151.geometry}
        material={materials.test}
        position={[-41.92, 1.99, -1.49]}
        scale={[-0.2, -0.02, -0.2]}
      />
      <mesh
        name="柱体152"
        castShadow
        receiveShadow
        geometry={nodes.柱体152.geometry}
        material={materials.test}
        position={[-41.92, 1.87, -1.5]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体153"
        castShadow
        receiveShadow
        geometry={nodes.柱体153.geometry}
        material={nodes.柱体153.material}
        position={[-41.92, 1.87, -0.3]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体154"
        castShadow
        receiveShadow
        geometry={nodes.柱体154.geometry}
        material={materials.变压器主体}
        position={[-41.92, 2.33, -0.29]}
        rotation={[Math.PI, -Math.PI / 6, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体155"
        castShadow
        receiveShadow
        geometry={nodes.柱体155.geometry}
        material={materials.变压器主体}
        position={[-41.92, 2.3, -0.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体156"
        castShadow
        receiveShadow
        geometry={nodes.柱体156.geometry}
        material={materials.散热片}
        position={[-41.92, 2.22, -0.29]}
        scale={[-0.1, -0.02, -0.1]}
      />
      <mesh
        name="柱体157"
        castShadow
        receiveShadow
        geometry={nodes.柱体157.geometry}
        material={materials.变压器主体}
        position={[-41.92, 2.23, -0.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体158"
        castShadow
        receiveShadow
        geometry={nodes.柱体158.geometry}
        material={materials.test}
        position={[-41.92, 2.18, -0.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.11}
      />
      <mesh
        name="柱体159"
        castShadow
        receiveShadow
        geometry={nodes.柱体159.geometry}
        material={nodes.柱体159.material}
        position={[-41.92, 2.05, -0.29]}
        scale={[-0.11, -0.02, -0.11]}
      />
      <mesh
        name="柱体160"
        castShadow
        receiveShadow
        geometry={nodes.柱体160.geometry}
        material={materials.铁柱子}
        position={[-41.92, 2.33, -0.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.05, 0.33, 0.05]}
      />
      <mesh
        name="柱体161"
        castShadow
        receiveShadow
        geometry={nodes.柱体161.geometry}
        material={materials.test}
        position={[-41.92, 1.99, -0.3]}
        scale={[-0.2, -0.02, -0.2]}
      />
      <mesh
        name="柱体162"
        castShadow
        receiveShadow
        geometry={nodes.柱体162.geometry}
        material={materials.test}
        position={[-41.92, 1.87, -0.3]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体163"
        castShadow
        receiveShadow
        geometry={nodes.柱体163.geometry}
        material={materials.变压器主体}
        position={[-41.92, 2.33, 0.88]}
        rotation={[Math.PI, -Math.PI / 6, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体164"
        castShadow
        receiveShadow
        geometry={nodes.柱体164.geometry}
        material={materials.变压器主体}
        position={[-41.92, 2.3, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体165"
        castShadow
        receiveShadow
        geometry={nodes.柱体165.geometry}
        material={materials.散热片}
        position={[-41.92, 2.22, 0.88]}
        scale={[-0.1, -0.02, -0.1]}
      />
      <mesh
        name="柱体166"
        castShadow
        receiveShadow
        geometry={nodes.柱体166.geometry}
        material={materials.变压器主体}
        position={[-41.92, 2.23, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体167"
        castShadow
        receiveShadow
        geometry={nodes.柱体167.geometry}
        material={materials.test}
        position={[-41.92, 2.18, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.11}
      />
      <mesh
        name="柱体168"
        castShadow
        receiveShadow
        geometry={nodes.柱体168.geometry}
        material={nodes.柱体168.material}
        position={[-41.92, 2.05, 0.88]}
        scale={[-0.11, -0.02, -0.11]}
      />
      <mesh
        name="柱体169"
        castShadow
        receiveShadow
        geometry={nodes.柱体169.geometry}
        material={materials.铁柱子}
        position={[-41.92, 2.33, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.05, 0.33, 0.05]}
      />
      <mesh
        name="柱体170"
        castShadow
        receiveShadow
        geometry={nodes.柱体170.geometry}
        material={materials.test}
        position={[-41.92, 1.99, 0.88]}
        scale={[-0.2, -0.02, -0.2]}
      />
      <mesh
        name="柱体171"
        castShadow
        receiveShadow
        geometry={nodes.柱体171.geometry}
        material={materials.test}
        position={[-41.92, 1.87, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体172"
        castShadow
        receiveShadow
        geometry={nodes.柱体172.geometry}
        material={nodes.柱体172.material}
        position={[-41.92, 1.87, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体173"
        castShadow
        receiveShadow
        geometry={nodes.柱体173.geometry}
        material={materials.铁柱子}
        position={[-43.79, 1.8, -2.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.03}
      />
      <mesh
        name="柱体174"
        castShadow
        receiveShadow
        geometry={nodes.柱体174.geometry}
        material={materials.铁柱子}
        position={[-41.4, 1.8, 1.71]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.03}
      />
      <mesh
        name="柱体175"
        castShadow
        receiveShadow
        geometry={nodes.柱体175.geometry}
        material={materials.铁柱子}
        position={[-41.41, 1.8, -2.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.03}
      />
      <mesh
        name="柱体176"
        castShadow
        receiveShadow
        geometry={nodes.柱体176.geometry}
        material={materials.铁柱子}
        position={[-41.14, 1.8, -2.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.03}
      />
      <mesh
        name="上圆洞1001"
        castShadow
        receiveShadow
        geometry={nodes.上圆洞1001.geometry}
        material={nodes.上圆洞1001.material}
        position={[-43.4, 1.9, 1.28]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.06}
      />
      <mesh
        name="上圆洞2001"
        castShadow
        receiveShadow
        geometry={nodes.上圆洞2001.geometry}
        material={nodes.上圆洞2001.material}
        position={[-43.4, 1.9, -1.91]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.06}
      />
      <mesh
        name="上圆洞4001"
        castShadow
        receiveShadow
        geometry={nodes.上圆洞4001.geometry}
        material={nodes.上圆洞4001.material}
        position={[-41.56, 1.9, 1.28]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.06}
      />
      <mesh
        name="上圆洞3001"
        castShadow
        receiveShadow
        geometry={nodes.上圆洞3001.geometry}
        material={nodes.上圆洞3001.material}
        position={[-41.56, 1.9, -1.91]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.06}
      />
      <mesh
        name="柱体177"
        castShadow
        receiveShadow
        geometry={nodes.柱体177.geometry}
        material={materials.铁柱子}
        position={[-42.47, -0.89, -2.13]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={0.1}
      />
      <mesh
        name="下圆洞1001"
        castShadow
        receiveShadow
        geometry={nodes.下圆洞1001.geometry}
        material={nodes.下圆洞1001.material}
        position={[-44.97, -1.21, -0.88]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={-0.07}
      />
      <mesh
        name="下圆洞2001"
        castShadow
        receiveShadow
        geometry={nodes.下圆洞2001.geometry}
        material={nodes.下圆洞2001.material}
        position={[-39.97, -1.21, -0.88]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={-0.07}
      />
      <mesh
        name="下圆洞3001"
        castShadow
        receiveShadow
        geometry={nodes.下圆洞3001.geometry}
        material={nodes.下圆洞3001.material}
        position={[-44.97, -1.37, -1.04]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.07}
      />
      <mesh
        name="柱体178"
        castShadow
        receiveShadow
        geometry={nodes.柱体178.geometry}
        material={materials.铁柱子}
        position={[-41.59, -1.04, 0.47]}
        scale={[-0.08, -0.03, -0.08]}
      />
      <group
        name="下圆洞4001"
        position={[-39.97, -1.37, -1.04]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.07}
      >
        <mesh
          name="柱体202_1"
          castShadow
          receiveShadow
          geometry={nodes.柱体202_1.geometry}
          material={nodes.柱体202_1.material}
        />
        <mesh
          name="柱体202_2"
          castShadow
          receiveShadow
          geometry={nodes.柱体202_2.geometry}
          material={nodes.柱体202_2.material}
        />
      </group>
      <mesh
        name="柱体179"
        castShadow
        receiveShadow
        geometry={nodes.柱体179.geometry}
        material={materials.变压器主体}
        position={[-42.47, 1.31, -2.93]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[0.01, 1.03, 0.01]}
      />
      <mesh
        name="柱体180"
        castShadow
        receiveShadow
        geometry={nodes.柱体180.geometry}
        material={materials.铁柱子}
        position={[-41.59, -1.04, -1.06]}
        scale={[-0.08, -0.03, -0.08]}
      />
      <mesh
        name="柱体181"
        castShadow
        receiveShadow
        geometry={nodes.柱体181.geometry}
        material={materials.变压器主体}
        position={[-42.47, 1.31, 2.35]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.01, 1.03, 0.01]}
      />
      <mesh
        name="柱体182"
        castShadow
        receiveShadow
        geometry={nodes.柱体182.geometry}
        material={nodes.柱体182.material}
        position={[-42.46, -0.76, 2.34]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.01, 1.03, 0.01]}
      />
      <mesh
        name="柱体183"
        castShadow
        receiveShadow
        geometry={nodes.柱体183.geometry}
        material={materials.散热片}
        position={[-43, 2.4, -1.51]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <mesh
        name="柱体184"
        castShadow
        receiveShadow
        geometry={nodes.柱体184.geometry}
        material={materials.按钮}
        position={[-44.74, -0.33, -8.17]}
        rotation={[-Math.PI, 0, 0]}
        scale={[0.06, 0.18, 0.06]}
      />
      <mesh
        name="柱体185"
        castShadow
        receiveShadow
        geometry={nodes.柱体185.geometry}
        material={nodes.柱体185.material}
        position={[-45.27, 0.32, -8.17]}
        rotation={[-Math.PI, 0, 0]}
        scale={[0.06, 0.14, 0.06]}
      />
      <mesh
        name="柱体186"
        castShadow
        receiveShadow
        geometry={nodes.柱体186.geometry}
        material={materials.打卡机前壳}
        position={[-45.27, -0.26, -8.17]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-0.06, -0.08, -0.06]}
      />
      <mesh
        name="柱体187"
        castShadow
        receiveShadow
        geometry={nodes.柱体187.geometry}
        material={nodes.柱体187.material}
        position={[-45.27, 0.25, -8.17]}
        rotation={[-Math.PI, 0, 0]}
        scale={[0.06, 0.14, 0.06]}
      />
      <mesh
        name="立方体053"
        castShadow
        receiveShadow
        geometry={nodes.立方体053.geometry}
        material={nodes.立方体053.material}
        position={[-44.97, 0.23, -8.17]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={[-0.58, -0.14, -0.4]}
      />
      <mesh
        name="立方体054"
        castShadow
        receiveShadow
        geometry={nodes.立方体054.geometry}
        material={materials.变压器主体}
        position={[-44.97, 0.23, -8.1]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={[-0.46, -0.11, -0.31]}
      />
      <mesh
        name="柱体188"
        castShadow
        receiveShadow
        geometry={nodes.柱体188.geometry}
        material={materials.铁柱子}
        position={[-44.49, -0.02, -8.06]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.04}
      />
      <mesh
        name="柱体189"
        castShadow
        receiveShadow
        geometry={nodes.柱体189.geometry}
        material={materials.铁柱子}
        position={[-44.49, 0.5, -8.06]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.04}
      />
      <mesh
        name="柱体190"
        castShadow
        receiveShadow
        geometry={nodes.柱体190.geometry}
        material={materials.铁柱子}
        position={[-45.44, 0.5, -8.06]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.04}
      />
      <mesh
        name="柱体191"
        castShadow
        receiveShadow
        geometry={nodes.柱体191.geometry}
        material={materials.铁柱子}
        position={[-45.45, -0.02, -8.06]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.04}
      />
      <lineSegments
        name="圆环001"
        geometry={nodes.圆环001.geometry}
        material={nodes.圆环001.material}
        position={[-44.97, 0.23, -8.17]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.36}
      />
      <mesh
        name="立方体055"
        castShadow
        receiveShadow
        geometry={nodes.立方体055.geometry}
        material={nodes.立方体055.material}
        position={[-44.97, 0.23, -8.09]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.3, 0.12, 0.23]}
      />
      <mesh
        name="立方体056"
        castShadow
        receiveShadow
        geometry={nodes.立方体056.geometry}
        material={materials["材质.001"]}
        position={[-44.97, 0.23, -8]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.27, 0.04, 0.2]}
      />
      <group
        name="立方体057"
        position={[-42.81, 0.37, -6.41]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.64, 2.07, 1.1]}
      >
        <mesh
          name="立方体074"
          castShadow
          receiveShadow
          geometry={nodes.立方体074.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体074_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体074_1.geometry}
          material={materials["材质.007"]}
        />
      </group>
      <group
        name="立方体024"
        position={[-35.64, 1.93, 1.28]}
        rotation={[0, 1.57, 0]}
        scale={[0.09, 0.15, 0.01]}
      >
        <mesh
          name="立方体075"
          castShadow
          receiveShadow
          geometry={nodes.立方体075.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体075_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体075_1.geometry}
          material={materials.变压器主体}
        />
      </group>
      <group
        name="立方体025"
        position={[-35.64, 1.93, -1.91]}
        rotation={[0, 1.57, 0]}
        scale={[0.09, 0.15, 0.01]}
      >
        <mesh
          name="立方体076"
          castShadow
          receiveShadow
          geometry={nodes.立方体076.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体076_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体076_1.geometry}
          material={materials.变压器主体}
        />
      </group>
      <group
        name="立方体026"
        position={[-33.8, 1.93, 1.28]}
        rotation={[0, 1.57, 0]}
        scale={[0.09, 0.15, 0.01]}
      >
        <mesh
          name="立方体077"
          castShadow
          receiveShadow
          geometry={nodes.立方体077.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体077_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体077_1.geometry}
          material={materials.变压器主体}
        />
      </group>
      <group
        name="立方体027"
        position={[-33.8, 1.93, -1.91]}
        rotation={[0, 1.57, 0]}
        scale={[0.09, 0.15, 0.01]}
      >
        <mesh
          name="立方体078"
          castShadow
          receiveShadow
          geometry={nodes.立方体078.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体078_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体078_1.geometry}
          material={materials.变压器主体}
        />
      </group>
      <mesh
        name="立方体028"
        castShadow
        receiveShadow
        geometry={nodes.立方体028.geometry}
        material={materials.变压器主体}
        position={[-33.39, 1.54, -2.23]}
        rotation={[0, -1.57, 0]}
        scale={[0.03, 0.15, 0.15]}
      />
      <mesh
        name="立方体029"
        castShadow
        receiveShadow
        geometry={nodes.立方体029.geometry}
        material={materials.变压器主体}
        position={[-34.7, 1.76, -0.3]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[1.39, 0.05, 2.05]}
      />
      <mesh
        name="立方体030"
        castShadow
        receiveShadow
        geometry={nodes.立方体030.geometry}
        material={materials.变压器主体}
        position={[-33.39, 1.54, 1.64]}
        rotation={[0, -1.57, 0]}
        scale={[0.03, 0.15, 0.15]}
      />
      <mesh
        name="立方体031"
        castShadow
        receiveShadow
        geometry={nodes.立方体031.geometry}
        material={materials.变压器主体}
        position={[-32.67, 1.29, -1.48]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.11, 0.02, 0.11]}
      />
      <group
        name="立方体032"
        position={[-34.71, -1.17, -1.06]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[2.84, 0.17, 0.17]}
      >
        <mesh
          name="立方体083"
          castShadow
          receiveShadow
          geometry={nodes.立方体083.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体083_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体083_1.geometry}
          material={materials.变压器主体}
        />
      </group>
      <mesh
        name="立方体033"
        castShadow
        receiveShadow
        geometry={nodes.立方体033.geometry}
        material={materials.变压器主体}
        position={[-36.74, 1.29, 0.88]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[0.11, 0.02, 0.11]}
      />
      <mesh
        name="立方体034"
        castShadow
        receiveShadow
        geometry={nodes.立方体034.geometry}
        material={materials.散热片}
        position={[-35.99, 0.28, 1.35]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-0.73, -1.03, -0.01]}
      />
      <mesh
        name="立方体035"
        castShadow
        receiveShadow
        geometry={nodes.立方体035.geometry}
        material={materials.散热片}
        position={[-35.72, 0.27, 1.11]}
        rotation={[0, 0, -Math.PI]}
        scale={[-0.01, -1.04, -1.23]}
      />
      <mesh
        name="立方体036"
        castShadow
        receiveShadow
        geometry={nodes.立方体036.geometry}
        material={materials.变压器主体}
        position={[-34.7, 0.23, -0.3]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[1.23, 1.23, 1.85]}
      />
      <mesh
        name="柱体004"
        castShadow
        receiveShadow
        geometry={nodes.柱体004.geometry}
        material={materials.变压器主体}
        position={[-33.53, 2.83, 1.58]}
        rotation={[0, 0, -Math.PI]}
        scale={[0.05, 0.41, 0.05]}
      />
      <mesh
        name="柱体005"
        castShadow
        receiveShadow
        geometry={nodes.柱体005.geometry}
        material={materials.变压器主体}
        position={[-33.53, 1.9, 1.58]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.05, 0.41, 0.05]}
      />
      <mesh
        name="柱体006"
        castShadow
        receiveShadow
        geometry={nodes.柱体006.geometry}
        material={materials.散热片}
        position={[-33.53, 2.37, 1.58]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.05, 0.41, 0.05]}
      />
      <mesh
        name="柱体007"
        castShadow
        receiveShadow
        geometry={nodes.柱体007.geometry}
        material={materials.按钮}
        position={[-34.68, 2.01, -1.94]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.13, 0.03, 0.13]}
      />
      <mesh
        name="柱体011"
        castShadow
        receiveShadow
        geometry={nodes.柱体011.geometry}
        material={materials.打卡机前壳}
        position={[-34.68, 1.97, -1.94]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.14, 0.06, 0.14]}
      />
      <mesh
        name="柱体016"
        castShadow
        receiveShadow
        geometry={nodes.柱体016.geometry}
        material={materials.打卡机前壳}
        position={[-34.62, 1.97, -1.83]}
        scale={[0.06, 0, 0.06]}
      />
      <mesh
        name="柱体017"
        castShadow
        receiveShadow
        geometry={nodes.柱体017.geometry}
        material={materials.打卡机前壳}
        position={[-34.56, 1.97, -1.94]}
        rotation={[0, Math.PI / 3, 0]}
        scale={[0.06, 0, 0.06]}
      />
      <mesh
        name="柱体018"
        castShadow
        receiveShadow
        geometry={nodes.柱体018.geometry}
        material={materials.打卡机前壳}
        position={[-34.75, 1.97, -1.83]}
        rotation={[0, -1.05, 0]}
        scale={[0.06, 0, 0.06]}
      />
      <mesh
        name="柱体019"
        castShadow
        receiveShadow
        geometry={nodes.柱体019.geometry}
        material={materials.打卡机前壳}
        position={[-34.81, 1.97, -1.94]}
        rotation={[Math.PI, -Math.PI / 3, Math.PI]}
        scale={[0.06, 0, 0.06]}
      />
      <mesh
        name="柱体020"
        castShadow
        receiveShadow
        geometry={nodes.柱体020.geometry}
        material={materials.打卡机前壳}
        position={[-34.75, 1.97, -2.04]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.06, 0, 0.06]}
      />
      <mesh
        name="柱体021"
        castShadow
        receiveShadow
        geometry={nodes.柱体021.geometry}
        material={materials.打卡机前壳}
        position={[-34.62, 1.97, -2.05]}
        rotation={[-Math.PI, Math.PI / 3, -Math.PI]}
        scale={[0.06, 0, 0.06]}
      />
      <lineSegments
        name="柱体022"
        geometry={nodes.柱体022.geometry}
        material={nodes.柱体022.material}
        position={[-34.68, -3.27, -0.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.22, 0.01, 0.22]}
      />
      <mesh
        name="柱体023"
        castShadow
        receiveShadow
        geometry={nodes.柱体023.geometry}
        material={materials.打卡机前壳}
        position={[-34.68, 1.81, -1.94]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.17, 0, 0.17]}
      />
      <mesh
        name="柱体024"
        castShadow
        receiveShadow
        geometry={nodes.柱体024.geometry}
        material={materials.铁柱子}
        position={[-35.24, 2.58, -0.31]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.18}
      />
      <mesh
        name="柱体025"
        castShadow
        receiveShadow
        geometry={nodes.柱体025.geometry}
        material={materials.变压器主体}
        position={[-35.24, 2.59, -0.31]}
        rotation={[0, Math.PI / 3, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体026"
        castShadow
        receiveShadow
        geometry={nodes.柱体026.geometry}
        material={materials.变压器主体}
        position={[-35.24, 2.57, -0.31]}
        rotation={[0, Math.PI / 6, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体027"
        castShadow
        receiveShadow
        geometry={nodes.柱体027.geometry}
        material={materials.变压器主体}
        position={[-35.24, 2.52, -0.31]}
        scale={[-0.15, -0.04, -0.15]}
      />
      <mesh
        name="柱体028"
        castShadow
        receiveShadow
        geometry={nodes.柱体028.geometry}
        material={materials.散热片}
        position={[-35.24, 2.55, -0.31]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <mesh
        name="柱体029"
        castShadow
        receiveShadow
        geometry={nodes.柱体029.geometry}
        material={materials.散热片}
        position={[-35.24, 2.4, -0.31]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <mesh
        name="柱体030"
        castShadow
        receiveShadow
        geometry={nodes.柱体030.geometry}
        material={materials.test}
        position={[-35.24, 2.45, -0.31]}
        scale={[-0.37, -0.02, -0.37]}
      />
      <mesh
        name="柱体031"
        castShadow
        receiveShadow
        geometry={nodes.柱体031.geometry}
        material={materials.test}
        position={[-35.24, 2.19, -0.32]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体032"
        castShadow
        receiveShadow
        geometry={nodes.柱体032.geometry}
        material={materials.test}
        position={[-35.24, 2.32, -0.31]}
        scale={[-0.26, -0.01, -0.26]}
      />
      <mesh
        name="柱体033"
        castShadow
        receiveShadow
        geometry={nodes.柱体033.geometry}
        material={materials.test}
        position={[-35.24, 2.05, -0.32]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体034"
        castShadow
        receiveShadow
        geometry={nodes.柱体034.geometry}
        material={materials.test}
        position={[-35.24, 1.85, -0.32]}
        scale={[-0.24, -0.04, -0.24]}
      />
      <mesh
        name="柱体035"
        castShadow
        receiveShadow
        geometry={nodes.柱体035.geometry}
        material={materials.铁柱子}
        position={[-35.24, 2.58, -1.51]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.18}
      />
      <mesh
        name="柱体036"
        castShadow
        receiveShadow
        geometry={nodes.柱体036.geometry}
        material={materials.变压器主体}
        position={[-35.24, 2.59, -1.51]}
        rotation={[0, Math.PI / 3, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体037"
        castShadow
        receiveShadow
        geometry={nodes.柱体037.geometry}
        material={materials.变压器主体}
        position={[-35.24, 2.57, -1.51]}
        rotation={[0, Math.PI / 6, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体038"
        castShadow
        receiveShadow
        geometry={nodes.柱体038.geometry}
        material={materials.变压器主体}
        position={[-35.24, 2.52, -1.51]}
        scale={[-0.15, -0.04, -0.15]}
      />
      <mesh
        name="柱体039"
        castShadow
        receiveShadow
        geometry={nodes.柱体039.geometry}
        material={materials.散热片}
        position={[-35.24, 2.55, -1.51]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <mesh
        name="柱体040"
        castShadow
        receiveShadow
        geometry={nodes.柱体040.geometry}
        material={materials.test}
        position={[-35.24, 2.45, -1.51]}
        scale={[-0.37, -0.02, -0.37]}
      />
      <mesh
        name="柱体041"
        castShadow
        receiveShadow
        geometry={nodes.柱体041.geometry}
        material={materials.test}
        position={[-35.24, 2.19, -1.52]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体042"
        castShadow
        receiveShadow
        geometry={nodes.柱体042.geometry}
        material={materials.铁柱子}
        position={[-35.24, 2.58, 0.89]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.18}
      />
      <mesh
        name="柱体043"
        castShadow
        receiveShadow
        geometry={nodes.柱体043.geometry}
        material={materials.变压器主体}
        position={[-35.24, 2.59, 0.89]}
        rotation={[0, Math.PI / 3, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体044"
        castShadow
        receiveShadow
        geometry={nodes.柱体044.geometry}
        material={materials.变压器主体}
        position={[-35.24, 2.57, 0.89]}
        rotation={[0, Math.PI / 6, 0]}
        scale={[-0.15, -0.03, -0.15]}
      />
      <mesh
        name="柱体045"
        castShadow
        receiveShadow
        geometry={nodes.柱体045.geometry}
        material={materials.变压器主体}
        position={[-35.24, 2.52, 0.89]}
        scale={[-0.15, -0.04, -0.15]}
      />
      <mesh
        name="柱体046"
        castShadow
        receiveShadow
        geometry={nodes.柱体046.geometry}
        material={materials.散热片}
        position={[-35.24, 2.55, 0.89]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <mesh
        name="柱体047"
        castShadow
        receiveShadow
        geometry={nodes.柱体047.geometry}
        material={materials.散热片}
        position={[-35.24, 2.4, 0.89]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <mesh
        name="柱体048"
        castShadow
        receiveShadow
        geometry={nodes.柱体048.geometry}
        material={materials.test}
        position={[-35.24, 2.45, 0.89]}
        scale={[-0.37, -0.02, -0.37]}
      />
      <mesh
        name="柱体049"
        castShadow
        receiveShadow
        geometry={nodes.柱体049.geometry}
        material={materials.test}
        position={[-35.24, 2.19, 0.88]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体050"
        castShadow
        receiveShadow
        geometry={nodes.柱体050.geometry}
        material={materials.test}
        position={[-35.24, 2.32, 0.88]}
        scale={[-0.26, -0.01, -0.26]}
      />
      <mesh
        name="柱体051"
        castShadow
        receiveShadow
        geometry={nodes.柱体051.geometry}
        material={materials.test}
        position={[-35.24, 2.32, -1.52]}
        scale={[-0.26, -0.01, -0.26]}
      />
      <mesh
        name="柱体052"
        castShadow
        receiveShadow
        geometry={nodes.柱体052.geometry}
        material={materials.test}
        position={[-35.24, 2.05, 0.88]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体053"
        castShadow
        receiveShadow
        geometry={nodes.柱体053.geometry}
        material={materials.test}
        position={[-35.24, 2.05, -1.52]}
        scale={[-0.24, -0.01, -0.24]}
      />
      <mesh
        name="柱体054"
        castShadow
        receiveShadow
        geometry={nodes.柱体054.geometry}
        material={materials.test}
        position={[-35.24, 1.85, 0.88]}
        scale={[-0.24, -0.04, -0.24]}
      />
      <mesh
        name="柱体055"
        castShadow
        receiveShadow
        geometry={nodes.柱体055.geometry}
        material={materials.test}
        position={[-35.24, 1.85, -1.52]}
        scale={[-0.24, -0.04, -0.24]}
      />
      <mesh
        name="柱体056"
        castShadow
        receiveShadow
        geometry={nodes.柱体056.geometry}
        material={nodes.柱体056.material}
        position={[-34.16, 1.87, -1.5]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体057"
        castShadow
        receiveShadow
        geometry={nodes.柱体057.geometry}
        material={materials.变压器主体}
        position={[-34.16, 2.33, -1.49]}
        rotation={[Math.PI, -Math.PI / 6, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体058"
        castShadow
        receiveShadow
        geometry={nodes.柱体058.geometry}
        material={materials.变压器主体}
        position={[-34.16, 2.3, -1.49]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体059"
        castShadow
        receiveShadow
        geometry={nodes.柱体059.geometry}
        material={materials.散热片}
        position={[-34.16, 2.22, -1.49]}
        scale={[-0.1, -0.02, -0.1]}
      />
      <mesh
        name="柱体060"
        castShadow
        receiveShadow
        geometry={nodes.柱体060.geometry}
        material={materials.变压器主体}
        position={[-34.16, 2.23, -1.49]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体061"
        castShadow
        receiveShadow
        geometry={nodes.柱体061.geometry}
        material={materials.test}
        position={[-34.16, 2.18, -1.49]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.11}
      />
      <mesh
        name="柱体062"
        castShadow
        receiveShadow
        geometry={nodes.柱体062.geometry}
        material={nodes.柱体062.material}
        position={[-34.16, 2.05, -1.49]}
        scale={[-0.11, -0.02, -0.11]}
      />
      <mesh
        name="柱体063"
        castShadow
        receiveShadow
        geometry={nodes.柱体063.geometry}
        material={materials.铁柱子}
        position={[-34.16, 2.33, -1.49]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.05, 0.33, 0.05]}
      />
      <mesh
        name="柱体064"
        castShadow
        receiveShadow
        geometry={nodes.柱体064.geometry}
        material={materials.test}
        position={[-34.16, 1.99, -1.49]}
        scale={[-0.2, -0.02, -0.2]}
      />
      <mesh
        name="柱体065"
        castShadow
        receiveShadow
        geometry={nodes.柱体065.geometry}
        material={materials.test}
        position={[-34.16, 1.87, -1.5]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体066"
        castShadow
        receiveShadow
        geometry={nodes.柱体066.geometry}
        material={nodes.柱体066.material}
        position={[-34.16, 1.87, -0.3]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体067"
        castShadow
        receiveShadow
        geometry={nodes.柱体067.geometry}
        material={materials.变压器主体}
        position={[-34.16, 2.33, -0.29]}
        rotation={[Math.PI, -Math.PI / 6, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体068"
        castShadow
        receiveShadow
        geometry={nodes.柱体068.geometry}
        material={materials.变压器主体}
        position={[-34.16, 2.3, -0.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体069"
        castShadow
        receiveShadow
        geometry={nodes.柱体069.geometry}
        material={materials.散热片}
        position={[-34.16, 2.22, -0.29]}
        scale={[-0.1, -0.02, -0.1]}
      />
      <mesh
        name="柱体070"
        castShadow
        receiveShadow
        geometry={nodes.柱体070.geometry}
        material={materials.变压器主体}
        position={[-34.16, 2.23, -0.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体071"
        castShadow
        receiveShadow
        geometry={nodes.柱体071.geometry}
        material={materials.test}
        position={[-34.16, 2.18, -0.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.11}
      />
      <mesh
        name="柱体072"
        castShadow
        receiveShadow
        geometry={nodes.柱体072.geometry}
        material={nodes.柱体072.material}
        position={[-34.16, 2.05, -0.29]}
        scale={[-0.11, -0.02, -0.11]}
      />
      <mesh
        name="柱体073"
        castShadow
        receiveShadow
        geometry={nodes.柱体073.geometry}
        material={materials.铁柱子}
        position={[-34.16, 2.33, -0.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.05, 0.33, 0.05]}
      />
      <mesh
        name="柱体074"
        castShadow
        receiveShadow
        geometry={nodes.柱体074.geometry}
        material={materials.test}
        position={[-34.16, 1.99, -0.3]}
        scale={[-0.2, -0.02, -0.2]}
      />
      <mesh
        name="柱体075"
        castShadow
        receiveShadow
        geometry={nodes.柱体075.geometry}
        material={materials.test}
        position={[-34.16, 1.87, -0.3]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体076"
        castShadow
        receiveShadow
        geometry={nodes.柱体076.geometry}
        material={materials.变压器主体}
        position={[-34.16, 2.33, 0.88]}
        rotation={[Math.PI, -Math.PI / 6, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体077"
        castShadow
        receiveShadow
        geometry={nodes.柱体077.geometry}
        material={materials.变压器主体}
        position={[-34.16, 2.3, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体078"
        castShadow
        receiveShadow
        geometry={nodes.柱体078.geometry}
        material={materials.散热片}
        position={[-34.16, 2.22, 0.88]}
        scale={[-0.1, -0.02, -0.1]}
      />
      <mesh
        name="柱体079"
        castShadow
        receiveShadow
        geometry={nodes.柱体079.geometry}
        material={materials.变压器主体}
        position={[-34.16, 2.23, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.09}
      />
      <mesh
        name="柱体080"
        castShadow
        receiveShadow
        geometry={nodes.柱体080.geometry}
        material={materials.test}
        position={[-34.16, 2.18, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.11}
      />
      <mesh
        name="柱体081"
        castShadow
        receiveShadow
        geometry={nodes.柱体081.geometry}
        material={nodes.柱体081.material}
        position={[-34.16, 2.05, 0.88]}
        scale={[-0.11, -0.02, -0.11]}
      />
      <mesh
        name="柱体082"
        castShadow
        receiveShadow
        geometry={nodes.柱体082.geometry}
        material={materials.铁柱子}
        position={[-34.16, 2.33, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.05, 0.33, 0.05]}
      />
      <mesh
        name="柱体083"
        castShadow
        receiveShadow
        geometry={nodes.柱体083.geometry}
        material={materials.test}
        position={[-34.16, 1.99, 0.88]}
        scale={[-0.2, -0.02, -0.2]}
      />
      <mesh
        name="柱体084"
        castShadow
        receiveShadow
        geometry={nodes.柱体084.geometry}
        material={materials.test}
        position={[-34.16, 1.87, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体085"
        castShadow
        receiveShadow
        geometry={nodes.柱体085.geometry}
        material={nodes.柱体085.material}
        position={[-34.16, 1.87, 0.88]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.19, 0.04, 0.19]}
      />
      <mesh
        name="柱体086"
        castShadow
        receiveShadow
        geometry={nodes.柱体086.geometry}
        material={materials.铁柱子}
        position={[-36.03, 1.8, -2.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.03}
      />
      <mesh
        name="柱体087"
        castShadow
        receiveShadow
        geometry={nodes.柱体087.geometry}
        material={materials.铁柱子}
        position={[-33.64, 1.8, 1.71]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.03}
      />
      <mesh
        name="柱体088"
        castShadow
        receiveShadow
        geometry={nodes.柱体088.geometry}
        material={materials.铁柱子}
        position={[-33.64, 1.8, -2.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.03}
      />
      <mesh
        name="柱体089"
        castShadow
        receiveShadow
        geometry={nodes.柱体089.geometry}
        material={materials.铁柱子}
        position={[-33.38, 1.8, -2.29]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.03}
      />
      <mesh
        name="上圆洞1002"
        castShadow
        receiveShadow
        geometry={nodes.上圆洞1002.geometry}
        material={nodes.上圆洞1002.material}
        position={[-35.64, 1.9, 1.28]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.06}
      />
      <mesh
        name="上圆洞2002"
        castShadow
        receiveShadow
        geometry={nodes.上圆洞2002.geometry}
        material={nodes.上圆洞2002.material}
        position={[-35.64, 1.9, -1.91]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.06}
      />
      <mesh
        name="上圆洞4002"
        castShadow
        receiveShadow
        geometry={nodes.上圆洞4002.geometry}
        material={nodes.上圆洞4002.material}
        position={[-33.8, 1.9, 1.28]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.06}
      />
      <mesh
        name="上圆洞3002"
        castShadow
        receiveShadow
        geometry={nodes.上圆洞3002.geometry}
        material={nodes.上圆洞3002.material}
        position={[-33.8, 1.9, -1.91]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.06}
      />
      <mesh
        name="柱体090"
        castShadow
        receiveShadow
        geometry={nodes.柱体090.geometry}
        material={materials.铁柱子}
        position={[-34.71, -0.89, -2.13]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={0.1}
      />
      <mesh
        name="下圆洞1002"
        castShadow
        receiveShadow
        geometry={nodes.下圆洞1002.geometry}
        material={nodes.下圆洞1002.material}
        position={[-37.21, -1.21, -0.88]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={-0.07}
      />
      <mesh
        name="下圆洞2002"
        castShadow
        receiveShadow
        geometry={nodes.下圆洞2002.geometry}
        material={nodes.下圆洞2002.material}
        position={[-32.21, -1.21, -0.88]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={-0.07}
      />
      <mesh
        name="下圆洞3002"
        castShadow
        receiveShadow
        geometry={nodes.下圆洞3002.geometry}
        material={nodes.下圆洞3002.material}
        position={[-37.21, -1.37, -1.04]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.07}
      />
      <mesh
        name="柱体091"
        castShadow
        receiveShadow
        geometry={nodes.柱体091.geometry}
        material={materials.铁柱子}
        position={[-33.83, -1.04, 0.47]}
        scale={[-0.08, -0.03, -0.08]}
      />
      <group
        name="下圆洞4002"
        position={[-32.21, -1.37, -1.04]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={-0.07}
      >
        <mesh
          name="柱体398"
          castShadow
          receiveShadow
          geometry={nodes.柱体398.geometry}
          material={nodes.柱体398.material}
        />
        <mesh
          name="柱体398_1"
          castShadow
          receiveShadow
          geometry={nodes.柱体398_1.geometry}
          material={nodes.柱体398_1.material}
        />
      </group>
      <mesh
        name="柱体092"
        castShadow
        receiveShadow
        geometry={nodes.柱体092.geometry}
        material={materials.变压器主体}
        position={[-34.7, 1.31, -2.93]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[0.01, 1.03, 0.01]}
      />
      <mesh
        name="柱体093"
        castShadow
        receiveShadow
        geometry={nodes.柱体093.geometry}
        material={materials.铁柱子}
        position={[-33.83, -1.04, -1.06]}
        scale={[-0.08, -0.03, -0.08]}
      />
      <mesh
        name="柱体094"
        castShadow
        receiveShadow
        geometry={nodes.柱体094.geometry}
        material={materials.变压器主体}
        position={[-34.7, 1.31, 2.35]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.01, 1.03, 0.01]}
      />
      <mesh
        name="柱体095"
        castShadow
        receiveShadow
        geometry={nodes.柱体095.geometry}
        material={nodes.柱体095.material}
        position={[-34.7, -0.76, 2.34]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.01, 1.03, 0.01]}
      />
      <mesh
        name="柱体097"
        castShadow
        receiveShadow
        geometry={nodes.柱体097.geometry}
        material={materials.散热片}
        position={[-35.24, 2.4, -1.51]}
        scale={[-0.28, -0.01, -0.28]}
      />
      <group
        name="Camera_Security002"
        position={[-48.44, 2.08, -8.11]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={2.65}
      >
        <mesh
          name="camera_security005"
          castShadow
          receiveShadow
          geometry={nodes.camera_security005.geometry}
          material={materials["plastic_grey_camera.003"]}
        />
        <mesh
          name="camera_security005_1"
          castShadow
          receiveShadow
          geometry={nodes.camera_security005_1.geometry}
          material={materials["plastic_black_shiny_camera.003"]}
        />
      </group>
      <mesh
        name="立方体058"
        castShadow
        receiveShadow
        geometry={nodes.立方体058.geometry}
        material={materials.变压器主体}
        position={[-42.47, -0.1, 5.55]}
        rotation={[0, 0, -Math.PI]}
        scale={[-1, -1, -0.28]}
      />
      <mesh
        name="立方体059"
        castShadow
        receiveShadow
        geometry={nodes.立方体059.geometry}
        material={materials.变压器主体}
        position={[-42.47, -0.1, 5.91]}
        scale={[1, 1, 0.05]}
      />
      <mesh
        name="立方体060"
        castShadow
        receiveShadow
        geometry={nodes.立方体060.geometry}
        material={materials.铁柱子}
        position={[-41.49, -0.1, 5.85]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-0.02, -1, -0.02]}
      />
      <mesh
        name="立方体061"
        castShadow
        receiveShadow
        geometry={nodes.立方体061.geometry}
        material={materials.铁柱子}
        position={[-43.45, -0.1, 5.85]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-0.02, -1, -0.02]}
      />
      <mesh
        name="立方体062"
        castShadow
        receiveShadow
        geometry={nodes.立方体062.geometry}
        material={materials.铁柱子}
        position={[-42.47, 0.88, 5.85]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[-0.02, -1, -0.02]}
      />
      <mesh
        name="立方体063"
        castShadow
        receiveShadow
        geometry={nodes.立方体063.geometry}
        material={materials.铁柱子}
        position={[-42.47, -1.08, 5.85]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[-0.02, -1, -0.02]}
      />
      <group
        name="立方体064"
        position={[-43.09, 0.55, 5.96]}
        scale={[0.14, 0.14, 0.03]}
      >
        <mesh
          name="立方体096"
          castShadow
          receiveShadow
          geometry={nodes.立方体096.geometry}
          material={nodes.立方体096.material}
        />
        <mesh
          name="立方体096_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体096_1.geometry}
          material={materials["材质.017"]}
        />
      </group>
      <mesh
        name="柱体196"
        castShadow
        receiveShadow
        geometry={nodes.柱体196.geometry}
        material={materials["plastic_grey_camera.002"]}
        position={[-41.77, -0.66, 5.94]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        name="柱体197"
        castShadow
        receiveShadow
        geometry={nodes.柱体197.geometry}
        material={materials["plastic_black_shiny_camera.003"]}
        position={[-41.77, -0.66, 5.97]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体198"
        castShadow
        receiveShadow
        geometry={nodes.柱体198.geometry}
        material={materials.铁柱子}
        position={[-43.1, -0.09, 5.94]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.06}
      />
      <mesh
        name="柱体199"
        castShadow
        receiveShadow
        geometry={nodes.柱体199.geometry}
        material={materials.按钮}
        position={[-43.1, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体201"
        castShadow
        receiveShadow
        geometry={nodes.柱体201.geometry}
        material={materials.按钮}
        position={[-42.3, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体206"
        castShadow
        receiveShadow
        geometry={nodes.柱体206.geometry}
        material={materials.散热片}
        position={[-42.57, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体207"
        castShadow
        receiveShadow
        geometry={nodes.柱体207.geometry}
        material={materials.散热片}
        position={[-41.76, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体208"
        castShadow
        receiveShadow
        geometry={nodes.柱体208.geometry}
        material={materials.绿色按钮}
        position={[-42.84, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体209"
        castShadow
        receiveShadow
        geometry={nodes.柱体209.geometry}
        material={materials.绿色按钮}
        position={[-42.03, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="立方体065"
        castShadow
        receiveShadow
        geometry={nodes.立方体065.geometry}
        material={nodes.立方体065.material}
        position={[-43.1, -0.27, 6]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[-0.04, -0.04, -0.01]}
      />
      <mesh
        name="立方体066"
        castShadow
        receiveShadow
        geometry={nodes.立方体066.geometry}
        material={materials.变压器主体}
        position={[-34.58, -0.1, 5.55]}
        rotation={[0, 0, -Math.PI]}
        scale={[-1, -1, -0.28]}
      />
      <mesh
        name="立方体067"
        castShadow
        receiveShadow
        geometry={nodes.立方体067.geometry}
        material={materials.变压器主体}
        position={[-34.58, -0.1, 5.91]}
        scale={[1, 1, 0.05]}
      />
      <mesh
        name="立方体068"
        castShadow
        receiveShadow
        geometry={nodes.立方体068.geometry}
        material={materials.铁柱子}
        position={[-33.6, -0.1, 5.85]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-0.02, -1, -0.02]}
      />
      <mesh
        name="立方体069"
        castShadow
        receiveShadow
        geometry={nodes.立方体069.geometry}
        material={materials.铁柱子}
        position={[-35.56, -0.1, 5.85]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-0.02, -1, -0.02]}
      />
      <mesh
        name="立方体070"
        castShadow
        receiveShadow
        geometry={nodes.立方体070.geometry}
        material={materials.铁柱子}
        position={[-34.58, 0.88, 5.85]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[-0.02, -1, -0.02]}
      />
      <mesh
        name="立方体071"
        castShadow
        receiveShadow
        geometry={nodes.立方体071.geometry}
        material={materials.铁柱子}
        position={[-34.58, -1.08, 5.85]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[-0.02, -1, -0.02]}
      />
      <group
        name="立方体072"
        position={[-35.2, 0.55, 5.96]}
        scale={[0.14, 0.14, 0.03]}
      >
        <mesh
          name="立方体107"
          castShadow
          receiveShadow
          geometry={nodes.立方体107.geometry}
          material={materials.black}
        />
        <mesh
          name="立方体107_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体107_1.geometry}
          material={materials["材质.017"]}
        />
      </group>
      <mesh
        name="柱体200"
        castShadow
        receiveShadow
        geometry={nodes.柱体200.geometry}
        material={materials["plastic_grey_camera.002"]}
        position={[-33.89, -0.66, 5.94]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.05}
      />
      <mesh
        name="柱体202"
        castShadow
        receiveShadow
        geometry={nodes.柱体202.geometry}
        material={materials["plastic_black_shiny_camera.003"]}
        position={[-33.89, -0.66, 5.97]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体203"
        castShadow
        receiveShadow
        geometry={nodes.柱体203.geometry}
        material={materials.铁柱子}
        position={[-35.22, -0.09, 5.94]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.06}
      />
      <mesh
        name="柱体204"
        castShadow
        receiveShadow
        geometry={nodes.柱体204.geometry}
        material={materials.按钮}
        position={[-35.22, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体205"
        castShadow
        receiveShadow
        geometry={nodes.柱体205.geometry}
        material={materials.按钮}
        position={[-34.42, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体210"
        castShadow
        receiveShadow
        geometry={nodes.柱体210.geometry}
        material={materials.散热片}
        position={[-34.68, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体211"
        castShadow
        receiveShadow
        geometry={nodes.柱体211.geometry}
        material={materials.散热片}
        position={[-33.88, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体212"
        castShadow
        receiveShadow
        geometry={nodes.柱体212.geometry}
        material={materials.绿色按钮}
        position={[-34.95, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="柱体213"
        castShadow
        receiveShadow
        geometry={nodes.柱体213.geometry}
        material={materials.绿色按钮}
        position={[-34.15, -0.09, 5.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.03}
      />
      <mesh
        name="立方体073"
        castShadow
        receiveShadow
        geometry={nodes.立方体073.geometry}
        material={nodes.立方体073.material}
        position={[-35.22, -0.27, 6]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[-0.04, -0.04, -0.01]}
      />
      <mesh
        name="立方体001"
        castShadow
        receiveShadow
        geometry={nodes.立方体001.geometry}
        material={materials.空调屏幕}
        position={[-29.93, 1.56, -6.52]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={[0.18, 0.13, 0.13]}
      />
      <mesh
        name="立方体002"
        castShadow
        receiveShadow
        geometry={nodes.立方体002.geometry}
        material={nodes.立方体002.material}
        position={[-30.05, 1.56, -6.4]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={[0.18, 0.13, 0.13]}
      />
      <mesh
        name="空调外壳"
        castShadow
        receiveShadow
        geometry={nodes.空调外壳.geometry}
        material={materials.空调外壳}
        position={[-29.81, -1.72, -6.63]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={[0.07, 0.06, 0.07]}
      />
      <mesh
        name="柱体003"
        castShadow
        receiveShadow
        geometry={nodes.柱体003.geometry}
        material={materials.空调按钮}
        position={[-30.12, 1.27, -6.44]}
        rotation={[Math.PI / 2, 0, Math.PI / 4]}
        scale={0.03}
      />
      <mesh
        name="柱体002"
        castShadow
        receiveShadow
        geometry={nodes.柱体002.geometry}
        material={nodes.柱体002.material}
        position={[-30.12, 1.35, -6.44]}
        rotation={[Math.PI / 2, 0, Math.PI / 4]}
        scale={0.03}
      />
      <mesh
        name="柱体001"
        castShadow
        receiveShadow
        geometry={nodes.柱体001.geometry}
        material={nodes.柱体001.material}
        position={[-30.12, 1.35, -6.44]}
        rotation={[Math.PI / 2, 0, Math.PI / 4]}
        scale={0.03}
      />
      <mesh
        name="柱体"
        castShadow
        receiveShadow
        geometry={nodes.柱体.geometry}
        material={materials.空调按钮}
        position={[-30.12, 1.35, -6.44]}
        rotation={[Math.PI / 2, 0, Math.PI / 4]}
        scale={0.03}
      />
      <mesh
        name="控制模块"
        castShadow
        receiveShadow
        geometry={nodes.控制模块.geometry}
        material={nodes.控制模块.material}
        position={[-30.07, 1.32, -6.38]}
        rotation={[Math.PI / 2, 0, Math.PI / 4]}
        scale={[0.07, 0.07, 0.06]}
      />
      <group
        name="按键"
        position={[-30.05, 1.29, -6.34]}
        rotation={[Math.PI / 2, 0, Math.PI / 4]}
        scale={[0.07, 0.07, 0.06]}
      >
        <lineSegments
          name="002-0-2"
          geometry={nodes["002-0-2"].geometry}
          material={materials.archmodels74_032_003}
        />
        <points
          name="002-0-2_1"
          geometry={nodes["002-0-2_1"].geometry}
          material={materials.archmodels74_032_003}
        />
      </group>
      <mesh
        name="中国能效标识"
        castShadow
        receiveShadow
        geometry={nodes.中国能效标识.geometry}
        material={materials["材质.006"]}
        position={[-29.79, 1.59, -6.16]}
        rotation={[0, -Math.PI / 4, -3.13]}
        scale={[0.06, 0.1, 0.11]}
      />
      <mesh
        name="立方体005"
        castShadow
        receiveShadow
        geometry={nodes.立方体005.geometry}
        material={materials.铁门}
        position={[-49.24, 0.02, 5.57]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-16.32, -2.38, -1.16]}
      />
      <group
        name="立方体006"
        position={[-49.68, 0.07, 5.97]}
        scale={[0.05, 0.47, 0.07]}
      >
        <mesh
          name="立方体002_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_1.geometry}
          material={materials["black.001"]}
        />
        <mesh
          name="立方体002_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_2.geometry}
          material={materials.排风扇子}
        />
        <mesh
          name="立方体002_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_3.geometry}
          material={materials.玻璃}
        />
        <mesh
          name="立方体002_4"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_4.geometry}
          material={materials.铁门}
        />
      </group>
      <mesh
        name="立方体007"
        castShadow
        receiveShadow
        geometry={nodes.立方体007.geometry}
        material={materials.阁楼门}
        position={[-48.93, 0.02, 5.62]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-9.98, -1.46, -0.71]}
      />
      <mesh
        name="立方体008"
        castShadow
        receiveShadow
        geometry={nodes.立方体008.geometry}
        material={materials.阁楼门}
        position={[-49.14, 0.02, 5.57]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-55.85, -2.38, -1.16]}
      />
      <group
        name="Cube021"
        position={[-27.98, 0.4, 3.96]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube022_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube022_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube022_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube022_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube001"
        position={[-27.98, 0.4, 2.07]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube001_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube001_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube001_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube001_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube002"
        position={[-27.98, 0.4, -2.65]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube002_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube002_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube002_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube002_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube003"
        position={[-27.98, 0.4, -4.55]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube003_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube003_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube003_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube003_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube004"
        position={[-28.5, 0.4, -4.55]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube004_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube004_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube004_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube004_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube005"
        position={[-28.5, 0.4, -2.65]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube005_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube005_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube005_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube005_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube006"
        position={[-28.5, 0.4, 2.07]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube006_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube006_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube006_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube006_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube007"
        position={[-28.5, 0.4, 3.96]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube007_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube007_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube007_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube007_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube008"
        position={[-48.92, 0.4, 3.34]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube008_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube008_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube008_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube008_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube009"
        position={[-48.92, 0.4, 1.45]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube009_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube009_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube009_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube009_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube010"
        position={[-48.92, 0.4, -3.27]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube010_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube010_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube010_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube010_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube011"
        position={[-48.92, 0.4, -5.17]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube011_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube011_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube011_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube011_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube012"
        position={[-49.44, 0.4, -5.17]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube012_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube012_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube012_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube012_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube013"
        position={[-49.44, 0.4, -3.27]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube013_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube013_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube013_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube013_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube014"
        position={[-49.44, 0.4, 1.45]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube014_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube014_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube014_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube014_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube015"
        position={[-49.44, 0.4, 3.34]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube015_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube015_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube015_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube015_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube016"
        position={[-34.29, 0.4, -8.76]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube016_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube016_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube016_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube016_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube017"
        position={[-36.18, 0.4, -8.76]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube017_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube017_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube017_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube017_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube018"
        position={[-40.9, 0.4, -8.76]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube018_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube018_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube018_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube018_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube019"
        position={[-42.8, 0.4, -8.76]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube019_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube019_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube019_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube019_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube020"
        position={[-42.8, 0.4, -8.23]}
        rotation={[0, -1.57, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube020_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube020_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube020_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube020_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube022"
        position={[-40.9, 0.4, -8.23]}
        rotation={[0, -1.57, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube021_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube021_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube021_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube021_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube023"
        position={[-36.18, 0.4, -8.23]}
        rotation={[0, -1.57, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube023_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube023_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube023_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube023_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube024"
        position={[-34.29, 0.4, -8.23]}
        rotation={[0, -1.57, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube024_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube024_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube024_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube024_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube025"
        position={[-34.29, 0.4, 8.26]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube025_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube025_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube025_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube025_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube026"
        position={[-36.18, 0.4, 8.26]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube026_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube026_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube026_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube026_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube027"
        position={[-40.9, 0.4, 8.26]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube027_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube027_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube027_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube027_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube028"
        position={[-42.8, 0.4, 8.26]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube028_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube028_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube028_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube028_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube029"
        position={[-42.8, 0.4, 8.79]}
        rotation={[0, -1.57, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube029_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube029_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube029_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube029_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube030"
        position={[-40.9, 0.4, 8.79]}
        rotation={[0, -1.57, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube030_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube030_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube030_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube030_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube031"
        position={[-36.18, 0.4, 8.79]}
        rotation={[0, -1.57, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube031_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube031_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube031_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube031_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube032"
        position={[-34.29, 0.4, 8.79]}
        rotation={[0, -1.57, 0]}
        scale={[0.1, 1.04, 0.92]}
      >
        <mesh
          name="Cube032_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube032_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube032_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube032_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
    </group>
  );
}

// useGLTF.preload("/变压室.gltf");