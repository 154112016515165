import React, { useRef, useEffect, useState } from "react";
import { useGLTF, useCursor } from "@react-three/drei";
import { connect } from "react-redux";
import * as Actions from '../store/action'

export default function AllSubStation(props) {
  //鼠标状态
  const [active, setActive] = useState(false)
  //更改鼠标状态钩子
  useCursor(active)
  // const navigate = useNavigate()
  const group = useRef();
//   const { nodes, materials } = useGLTF("/场景建模的副本6.gltf");
  const { nodes, materials } = useGLTF("/场景建模的副本6.gltf");

  useEffect(() => {
    console.log(nodes)
    // console.log('我被挂载了')
    console.log('wswwwwwwwwwwwwwwwww',props.value.value)
    
    if (props.value.value == '温度较高'){
      materials["材质.015"].color = { r: 0.5, g: 0, b: 0, isColor: true }
      materials["灰墙.002"].color = { r: 0.5, g: 0, b: 0, isColor: true } 
      materials["外层变压器整体.002"].color = { r: 0.5, g: 0, b: 0, isColor: true } 
    }
    else if (props.value.value == '温度较低'){
      materials["材质.015"].color = { r: 0.067, g: 0.142, b: 0.219, isColor: true }
      materials["灰墙.002"].color = { r: 0.067, g: 0.142, b: 0.219, isColor: true }
      materials["外层变压器整体.002"].color = { r: 0.067, g: 0.142, b: 0.219, isColor: true }
    }
    else if(props.value.value == '温度正常'){
      materials["材质.015"].color = { r: 0.123, g: 0.123, b: 0.123, isColor: true }
      materials["灰墙.002"].color = { r: 0.123, g: 0.123, b: 0.123, isColor: true } 
      materials["外层变压器整体.002"].color = { r: 0.123, g: 0.123, b: 0.123, isColor: true } 
    }else if(props.value.value == '普通温度'){
      materials["材质.015"].color = { r: 0.255, g: 0.165, b: 0, isColor: true }
      materials["灰墙.002"].color = { r: 0.255, g: 0.165, b: 0, isColor: true }
      materials["外层变压器整体.002"].color = { r: 0.255, g: 0.165, b: 0, isColor: true }
    }
    
    console.log('wadwdadawdwadawd',props)
  },[props.value.value])


  const clickSmallSubstaionShow = (e) => {
    console.log(e)
    // navigate("/Smallsubstaion")
    props.navigate("/SmallSubstaionShow")
  }

  const clickPartialPressure = (e) => {
    console.log(e)

    props.navigate("/PartialPressureShow")
  }

  const clickTransformation = (e) => {
    console.log(e)

    props.navigate("/TransformationShow")
  }

  const clickBoxSubstation = (e) => {
    console.log(e)

    props.navigate("/BoxSubstationShow")
  }
  // <Provider store= {store}><WebsocketClass /></Provider>
  return (
    <group ref={group} {...props} dispose={null}>
      {/* <Provider store={store}><WebsocketClass /></Provider> */}
      <group
        name="Sketchfab_model"
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <group
          name="4b74769ab7c143c2b8616e89c0d44673fbx"
          rotation={[Math.PI / 2, 0, 0]}
        >
          <group name="Plane001" rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <mesh
              name="Plane001__0006"
              castShadow
              receiveShadow
              geometry={nodes.Plane001__0006.geometry}
              material={materials["Scene_-_Root.007"]}
              position={[-7, -14, -2.2]}
              rotation={[0, 0, Math.PI / 2]}
              scale={[1.2, 0.7, 1]}
            />
          </group>
        </group>
      </group>
      <group
        name="Sketchfab_model001"
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <group
          name="4b74769ab7c143c2b8616e89c0d44673fbx001"
          rotation={[Math.PI / 2, 0, 0]}
        />
      </group>
      <group
        name="柱体024"
        position={[-7.07, -0.62, -15.74]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={0.03}
      >
        <mesh
          name="柱体003"
          castShadow
          receiveShadow
          geometry={nodes.柱体003.geometry}
          material={materials["材质.017"]}
        />
        <mesh
          name="柱体003_1"
          castShadow
          receiveShadow
          geometry={nodes.柱体003_1.geometry}
          material={materials["打卡机前壳.002"]}
        />
        <mesh
          name="柱体003_2"
          castShadow
          receiveShadow
          geometry={nodes.柱体003_2.geometry}
          material={materials["材质.018"]}
        />
        <mesh
          name="柱体003_3"
          castShadow
          receiveShadow
          geometry={nodes.柱体003_3.geometry}
          material={nodes.柱体003_3.material}
        />
        <mesh
          name="柱体003_4"
          castShadow
          receiveShadow
          geometry={nodes.柱体003_4.geometry}
          material={materials["材质.019"]}
        />
        <mesh
          name="柱体003_5"
          castShadow
          receiveShadow
          geometry={nodes.柱体003_5.geometry}
          material={materials["材质.020"]}
        />
        <mesh
          name="柱体003_6"
          castShadow
          receiveShadow
          geometry={nodes.柱体003_6.geometry}
          material={materials["材质.021"]}
        />
        <mesh
          name="柱体003_7"
          castShadow
          receiveShadow
          geometry={nodes.柱体003_7.geometry}
          material={materials["材质.wall"]}
          scale={[0.9, 0.9, 1]}
        />
      </group>
      <group
        name="Cylinder014__0"
        position={[-11.77, 2.74, -15.62]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={0.08}
      >
        <mesh
          name="Cylinder014__0_1"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder014__0_1.geometry}
          material={materials["铁杆.001"]}
        />
        <mesh
          name="Cylinder014__0_2"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder014__0_2.geometry}
          material={materials["球.001"]}
        />
        <mesh
          name="Cylinder014__0_3"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder014__0_3.geometry}
          material={materials["Scene_-_Root"]}
        />
        <mesh
          name="Cylinder014__0_4"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder014__0_4.geometry}
          material={materials["杆装饰.001"]}
        />
      </group>
      <mesh
        name="外层变压器整体004"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体004.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.21, 4.05, 29.06]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体005"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体005.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.21, 4.05, 30.06]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体006"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体006.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.21, 4.05, 28.02]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体007"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体007.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.34, 4.05, 28.02]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体008"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体008.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.34, 4.05, 29.07]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体009"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体009.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.34, 4.05, 30.06]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体010"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体010.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.43, 4.05, 29.06]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体011"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体011.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.43, 4.05, 30.05]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体012"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体012.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.43, 4.05, 28.01]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体013"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体013.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.55, 4.05, 28.01]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体014"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体014.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.55, 4.05, 29.06]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体015"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体015.geometry}
        material={materials["外层变压器整体.002"]}
        position={[-25.55, 4.05, 30.06]}
        rotation={[1.19, 1.37, -1]}
        scale={0.01}
      />
      <group
        name="变电箱005"
        position={[13.02, -1.84, -11.35]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={0.01}
      >
        <mesh
          name="Mesh025"
          castShadow
          receiveShadow
          geometry={nodes.Mesh025.geometry}
          material={materials["外层变压器整体.002"]}
        />
        <mesh
          name="Mesh025_1"
          castShadow
          receiveShadow
          geometry={nodes.Mesh025_1.geometry}
          material={materials.电塔}
        />
        <lineSegments
          name="Mesh025_2"
          geometry={nodes.Mesh025_2.geometry}
          material={materials["外层变压器整体.002"]}
        />
        <points
          name="Mesh025_3"
          geometry={nodes.Mesh025_3.geometry}
          material={materials["外层变压器整体.002"]}
        />
      </group>
      <mesh
        name="外层变压器整体022"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体022.geometry}
        material={materials["外层变压器整体.002"]}
        position={[12.47, 3.83, 21.13]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体020"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体020.geometry}
        material={materials["外层变压器整体.002"]}
        position={[12.47, 3.83, 21.21]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体021"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体021.geometry}
        material={materials["外层变压器整体.002"]}
        position={[12.47, 3.83, 21.29]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体023"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体023.geometry}
        material={materials["外层变压器整体.002"]}
        position={[12.47, 3.83, 21.37]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体024"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体024.geometry}
        material={materials["外层变压器整体.002"]}
        position={[13.53, 3.83, 21.13]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体025"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体025.geometry}
        material={materials["外层变压器整体.002"]}
        position={[13.53, 3.83, 21.21]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体026"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体026.geometry}
        material={materials["外层变压器整体.002"]}
        position={[13.53, 3.83, 21.29]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体027"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体027.geometry}
        material={materials["外层变压器整体.002"]}
        position={[13.53, 3.83, 21.37]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体028"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体028.geometry}
        material={materials["外层变压器整体.002"]}
        position={[14.52, 3.83, 21.13]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体029"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体029.geometry}
        material={materials["外层变压器整体.002"]}
        position={[14.52, 3.83, 21.21]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体030"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体030.geometry}
        material={materials["外层变压器整体.002"]}
        position={[14.52, 3.83, 21.29]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体031"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体031.geometry}
        material={materials["外层变压器整体.002"]}
        position={[14.52, 3.83, 21.37]}
        rotation={[2.95, 0.07, -2.95]}
        scale={0.01}
      />
      <mesh
        name="外层变压器整体033"
        castShadow
        receiveShadow
        geometry={nodes.外层变压器整体033.geometry}
        material={materials["外层变压器整体.002"]}
        position={[13.13, 4.02, 25.67]}
        rotation={[-3.04, 0, -Math.PI]}
        scale={[-0.01, -0.01, 0]}
      />
      <group
        name="Water_Tower_Rust_0003"
        position={[-24.98, -1.26, 4.24]}
        rotation={[0, -0.01, 0]}
        scale={0.01}
      >
        <mesh
          name="Water_Tower_Rust_0004"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0004.geometry}
          material={materials["材质.022"]}
        />
        <mesh
          name="Water_Tower_Rust_0004_1"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0004_1.geometry}
          material={materials["上下底座.002"]}
        />
        <mesh
          name="Water_Tower_Rust_0004_2"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0004_2.geometry}
          material={materials["银白色.002"]}
        />
        <mesh
          name="Water_Tower_Rust_0004_3"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0004_3.geometry}
          material={materials.Gray_2}
        />
        <mesh
          name="Water_Tower_Rust_0004_4"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0004_4.geometry}
          material={materials["水管白.002"]}
        />
        <mesh
          name="Water_Tower_Rust_0004_5"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0004_5.geometry}
          material={materials["材质.023"]}
        />
      </group>
      <group
        name="Water_Tower_Rust_0001"
        position={[-8.62, -1.26, 4.24]}
        rotation={[0, -0.01, 0]}
        scale={0.01}
      >
        <mesh
          name="Water_Tower_Rust_0005"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0005.geometry}
          material={materials["材质.022"]}
        />
        <mesh
          name="Water_Tower_Rust_0005_1"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0005_1.geometry}
          material={materials["上下底座.002"]}
        />
        <mesh
          name="Water_Tower_Rust_0005_2"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0005_2.geometry}
          material={materials["银白色.002"]}
        />
        <mesh
          name="Water_Tower_Rust_0005_3"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0005_3.geometry}
          material={materials.Gray_2}
        />
        <mesh
          name="Water_Tower_Rust_0005_4"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0005_4.geometry}
          material={materials["水管白.002"]}
        />
        <mesh
          name="Water_Tower_Rust_0005_5"
          castShadow
          receiveShadow
          geometry={nodes.Water_Tower_Rust_0005_5.geometry}
          material={materials["材质.023"]}
        />
      </group>
      <group
        name="墙体002"
        position={[-7.28, 1.48, 13.06]}
        rotation={[3.02, -1.57, 3.02]}
        scale={2.73}
      >
        <mesh
          name="camera_security003"
          castShadow
          receiveShadow
          geometry={nodes.camera_security003.geometry}
          material={materials.plastic_grey_camera}
        />
        <mesh
          name="camera_security003_1"
          castShadow
          receiveShadow
          geometry={nodes.camera_security003_1.geometry}
          material={materials.plastic_black_shiny_camera}
        />
      </group>
      <mesh
        name="灯罩外围004"
        castShadow
        receiveShadow
        geometry={nodes.灯罩外围004.geometry}
        material={materials.lambert1}
        position={[-3.32, 0.55, 24.15]}
        rotation={[0, -1.57, 0]}
        scale={0.59}
      />
      <group
        name="墙体006"
        position={[15.79, 2.03, -6.81]}
        rotation={[0.12, 1.57, -0.12]}
        scale={2.73}
      >
        <mesh
          name="camera_security007"
          castShadow
          receiveShadow
          geometry={nodes.camera_security007.geometry}
          material={materials.plastic_grey_camera}
        />
        <mesh
          name="camera_security007_1"
          castShadow
          receiveShadow
          geometry={nodes.camera_security007_1.geometry}
          material={materials.plastic_black_shiny_camera}
        />
      </group>
      <mesh
        name="平面"
        castShadow
        receiveShadow
        geometry={nodes.平面.geometry}
        material={materials["材质.002"]}
        position={[-5.29, -2.56, 10.53]}
        scale={[52.65, 42.64, 46.91]}
      />
      <group
        name="立方体052"
        position={[-1.83, 1.88, -8.45]}
        rotation={[-Math.PI, Math.PI / 2, 0]}
        scale={[-1.7, -1.63, -2.83]}
        onPointerOver={() => setActive(true)}//鼠标移入
        onPointerOut={() => setActive(false)}//鼠标移出
        onClick={clickTransformation}
      >
        <mesh
          name="立方体035"
          castShadow
          receiveShadow
          geometry={nodes.立方体035.geometry}
          material={materials["灰墙.005"]}
        />
        <mesh
          name="立方体035_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_1.geometry}
          material={nodes.立方体035_1.material}
        />
        <mesh
          name="立方体035_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_2.geometry}
          material={materials["铁门.005"]}
        />
        <mesh
          name="立方体035_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_3.geometry}
          material={materials["black.010"]}
        />
        <mesh
          name="立方体035_4"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_4.geometry}
          material={materials["排风扇子.005"]}
        />
        <mesh
          name="立方体035_5"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_5.geometry}
          material={materials["玻璃.014"]}
        />
        <mesh
          name="立方体035_6"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_6.geometry}
          material={materials["阁楼门.005"]}
        />
        <mesh
          name="立方体035_7"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_7.geometry}
          material={materials["材质.045"]}
        />
        <mesh
          name="立方体035_8"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_8.geometry}
          material={materials["材质.046"]}
        />
        <mesh
          name="立方体035_9"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_9.geometry}
          material={materials["打卡机前壳.006"]}
        />
        <mesh
          name="立方体035_10"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_10.geometry}
          material={materials["材质.047"]}
        />
        <mesh
          name="立方体035_11"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_11.geometry}
          material={materials["打卡机后壳.006"]}
        />
        <mesh
          name="立方体035_12"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_12.geometry}
          material={materials["材质.048"]}
        />
        <mesh
          name="立方体035_13"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_13.geometry}
          material={materials["材质.049"]}
        />
        <mesh
          name="立方体035_14"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_14.geometry}
          material={materials["材质.050"]}
        />
        <mesh
          name="立方体035_15"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_15.geometry}
          material={materials["材质.051"]}
        />
        <mesh
          name="立方体035_16"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_16.geometry}
          material={materials["ac_stand.006"]}
        />
        <mesh
          name="立方体035_17"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_17.geometry}
          material={materials["wire.006"]}
        />
        <mesh
          name="立方体035_18"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_18.geometry}
          material={materials["ac.006"]}
        />
        <mesh
          name="立方体035_19"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_19.geometry}
          material={materials["mesh.006"]}
        />
        <mesh
          name="立方体035_20"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_20.geometry}
          material={materials["Material.008"]}
        />
        <mesh
          name="立方体035_21"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_21.geometry}
          material={materials["black.011"]}
        />
        <mesh
          name="立方体035_22"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_22.geometry}
          material={materials["sticker.006"]}
        />
        <mesh
          name="立方体035_23"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_23.geometry}
          material={materials["玻璃.015"]}
        />
        <mesh
          name="立方体035_24"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_24.geometry}
          material={materials["灭火器红色.005"]}
        />
        <mesh
          name="立方体035_25"
          castShadow
          receiveShadow
          geometry={nodes.立方体035_25.geometry}
          material={materials["灭火器黑色.005"]}
        />
      </group>
      <group
        name="立方体012"
        position={[-10.83, 0.68, 15.74]}
        scale={[-0.03, -0.02, -0.01]}
        onClick={clickSmallSubstaionShow}
        onPointerOver={() => setActive(true)}//鼠标移入
        onPointerOut={() => setActive(false)}//鼠标移出
      >
        <mesh
          name="立方体008"
          castShadow
          receiveShadow
          geometry={nodes.立方体008.geometry}
          material={materials["Material.009"]}
        />
        <mesh
          name="立方体008_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_1.geometry}
          material={nodes.立方体008_1.material}
        />
        <mesh
          name="立方体008_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_2.geometry}
          material={materials["材质.052"]}
        />
        <mesh
          name="立方体008_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_3.geometry}
          material={materials["铁柱子.001"]}
        />
        <mesh
          name="立方体008_4"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_4.geometry}
          material={materials["变压器主体.001"]}
        />
        <mesh
          name="立方体008_5"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_5.geometry}
          material={materials["绿色按钮.001"]}
        />
        <mesh
          name="立方体008_6"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_6.geometry}
          material={materials["散热片.001"]}
        />
        <mesh
          name="立方体008_7"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_7.geometry}
          material={materials["按钮.001"]}
        />
        <mesh
          name="立方体008_8"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_8.geometry}
          material={materials["plastic_black_shiny_camera.001"]}
        />
        <mesh
          name="立方体008_9"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_9.geometry}
          material={materials["plastic_grey_camera.001"]}
        />
        <mesh
          name="立方体008_10"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_10.geometry}
          material={materials["材质.015"]}
        />
        <mesh
          name="立方体008_11"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_11.geometry}
          material={materials["材质.016"]}
        />
        <mesh
          name="立方体008_12"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_12.geometry}
          material={materials["阁楼门.002"]}
        />
        <mesh
          name="立方体008_13"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_13.geometry}
          material={materials["灰墙.002"]}
        />
        <mesh
          name="立方体008_14"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_14.geometry}
          material={materials["材质.025"]}
        />
        <mesh
          name="立方体008_15"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_15.geometry}
          material={materials["铁门.002"]}
        />
        <mesh
          name="立方体008_16"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_16.geometry}
          material={materials["black.004"]}
        />
        <mesh
          name="立方体008_17"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_17.geometry}
          material={materials["排风扇子.002"]}
        />
        <mesh
          name="立方体008_18"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_18.geometry}
          material={materials["玻璃.007"]}
        />
        <mesh
          name="立方体008_19"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_19.geometry}
          material={materials["把手黑.001"]}
        />
        <mesh
          name="立方体008_20"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_20.geometry}
          material={materials["打卡机后壳.003"]}
        />
        <mesh
          name="立方体008_21"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_21.geometry}
          material={materials["材质.026"]}
        />
        <mesh
          name="立方体008_22"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_22.geometry}
          material={materials["材质.027"]}
        />
        <mesh
          name="立方体008_23"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_23.geometry}
          material={materials["打卡机前壳.003"]}
        />
        <mesh
          name="立方体008_24"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_24.geometry}
          material={materials["材质.028"]}
        />
        <mesh
          name="立方体008_25"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_25.geometry}
          material={materials["材质.029"]}
        />
        <mesh
          name="立方体008_26"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_26.geometry}
          material={materials["材质.030"]}
        />
        <mesh
          name="立方体008_27"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_27.geometry}
          material={materials["ac_stand.003"]}
        />
        <mesh
          name="立方体008_28"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_28.geometry}
          material={materials["wire.003"]}
        />
        <mesh
          name="立方体008_29"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_29.geometry}
          material={materials["ac.003"]}
        />
        <mesh
          name="立方体008_30"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_30.geometry}
          material={materials["mesh.003"]}
        />
        <mesh
          name="立方体008_31"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_31.geometry}
          material={materials["Material.005"]}
        />
        <mesh
          name="立方体008_32"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_32.geometry}
          material={materials["black.005"]}
        />
        <mesh
          name="立方体008_33"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_33.geometry}
          material={materials["sticker.003"]}
        />
        <mesh
          name="立方体008_34"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_34.geometry}
          material={materials["玻璃.008"]}
        />
        <mesh
          name="立方体008_35"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_35.geometry}
          material={materials["玻璃.009"]}
        />
        <mesh
          name="立方体008_36"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_36.geometry}
          material={materials["灭火器红色.002"]}
        />
        <mesh
          name="立方体008_37"
          castShadow
          receiveShadow
          geometry={nodes.立方体008_37.geometry}
          material={materials["灭火器黑色.002"]}
        />
      </group>
      
      <group
        name="立方体013"
        position={[-9.02, 0.42, 4.3]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.21, 0.68, 0.36]}
        onClick={clickPartialPressure}
        onPointerOver={() => setActive(true)}//鼠标移入
        onPointerOut={() => setActive(false)}//鼠标移出
      >
        <mesh
          name="立方体009"
          castShadow
          receiveShadow
          geometry={nodes.立方体009.geometry}
          material={materials["变压器主体.002"]}
        />
        <mesh
          name="立方体009_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_1.geometry}
          material={materials["材质.053"]}
        />
        <mesh
          name="立方体009_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_2.geometry}
          material={materials["铁门.003"]}
        />
        <mesh
          name="立方体009_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_3.geometry}
          material={materials["black.006"]}
        />
        <mesh
          name="立方体009_4"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_4.geometry}
          material={materials["排风扇子.003"]}
        />
        <mesh
          name="立方体009_5"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_5.geometry}
          material={materials["玻璃.010"]}
        />
        <mesh
          name="立方体009_6"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_6.geometry}
          material={materials["阁楼门.003"]}
        />
        <mesh
          name="立方体009_7"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_7.geometry}
          material={materials["灰墙.003"]}
        />
        <mesh
          name="立方体009_8"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_8.geometry}
          material={nodes.立方体009_8.material}
        />
        <mesh
          name="立方体009_9"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_9.geometry}
          material={materials["材质.031"]}
        />
        <mesh
          name="立方体009_10"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_10.geometry}
          material={materials["材质.032"]}
        />
        <mesh
          name="立方体009_11"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_11.geometry}
          material={materials["打卡机前壳.004"]}
        />
        <mesh
          name="立方体009_12"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_12.geometry}
          material={materials["材质.033"]}
        />
        <mesh
          name="立方体009_13"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_13.geometry}
          material={materials["打卡机后壳.004"]}
        />
        <mesh
          name="立方体009_14"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_14.geometry}
          material={materials["材质.034"]}
        />
        <mesh
          name="立方体009_15"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_15.geometry}
          material={materials["材质.035"]}
        />
        <mesh
          name="立方体009_16"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_16.geometry}
          material={materials["材质.036"]}
        />
        <mesh
          name="立方体009_17"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_17.geometry}
          material={materials["材质.037"]}
        />
        <mesh
          name="立方体009_18"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_18.geometry}
          material={materials["ac_stand.004"]}
        />
        <mesh
          name="立方体009_19"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_19.geometry}
          material={materials["wire.004"]}
        />
        <mesh
          name="立方体009_20"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_20.geometry}
          material={materials["ac.004"]}
        />
        <mesh
          name="立方体009_21"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_21.geometry}
          material={materials["mesh.004"]}
        />
        <mesh
          name="立方体009_22"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_22.geometry}
          material={materials["Material.006"]}
        />
        <mesh
          name="立方体009_23"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_23.geometry}
          material={materials["black.007"]}
        />
        <mesh
          name="立方体009_24"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_24.geometry}
          material={materials["sticker.004"]}
        />
        <mesh
          name="立方体009_25"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_25.geometry}
          material={materials["玻璃.011"]}
        />
        <mesh
          name="立方体009_26"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_26.geometry}
          material={materials["灭火器红色.003"]}
        />
        <mesh
          name="立方体009_27"
          castShadow
          receiveShadow
          geometry={nodes.立方体009_27.geometry}
          material={materials["灭火器黑色.003"]}
        />
      </group>
      <group
        name="红色墙004"
        position={[-11.8, -1.24, 18.67]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={2.93}
        onClick={clickBoxSubstation}
        onPointerOver={() => setActive(true)}//鼠标移入
        onPointerOut={() => setActive(false)}//鼠标移出
      >
        <mesh
          name="defaultMaterial004"
          castShadow
          receiveShadow
          geometry={nodes.defaultMaterial004.geometry}
          material={materials.Terrace}
        />
        <mesh
          name="defaultMaterial004_1"
          castShadow
          receiveShadow
          geometry={nodes.defaultMaterial004_1.geometry}
          material={materials.Bottom}
        />
        <mesh
          name="defaultMaterial004_2"
          castShadow
          receiveShadow
          geometry={nodes.defaultMaterial004_2.geometry}
          material={materials.Door}
        />
        <mesh
          name="defaultMaterial004_3"
          castShadow
          receiveShadow
          geometry={nodes.defaultMaterial004_3.geometry}
          material={materials.Room}
        />
        <mesh
          name="defaultMaterial004_4"
          castShadow
          receiveShadow
          geometry={nodes.defaultMaterial004_4.geometry}
          material={materials.Window}
        />
        <mesh
          name="defaultMaterial004_5"
          castShadow
          receiveShadow
          geometry={nodes.defaultMaterial004_5.geometry}
          material={materials.黑铁}
        />
      </group>
      
    </group>
  );
}


// const mapStateToProps = (state) => ({
//   // value: state.AppValue.value
// })

// const mapDispatchToProps = (dispatch) => ({
//   // Handleh(data){
//     // dispatch(Actions.Handleh(data))
//   // }
// })

// export default connect(mapStateToProps, mapDispatchToProps)(AllSubStation);