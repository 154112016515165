import React, { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { useNavigate } from "react-router-dom";
import { OrbitControls, Stage, Sky, Stats } from '@react-three/drei'
import PartialPressure from './component/partialPressure';

export default function PartialPressureShow() {
    console.log('-all');

    const navigate = useNavigate()

    //平滑上升
    const up = () => {

        navigate('/Substaion')
    }

    const ref = useRef()
    return (
        <div className='App'>
            <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
                <Suspense fallback={null}>
                    <Stage controls={ref} preset="rembrandt" intensity={1} environment="city">
                        <PartialPressure />
                    </Stage>
                  
                    <Sky scale={1000} sunPosition={[500, 150, -1000]} turbidity={0.1} />
                </Suspense>
                <OrbitControls ref={ref} />
                <Stats />
            </Canvas>
        </div>

    )
}