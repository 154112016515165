/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";


export default function BoxSubstation({ ...props }) {
    const group = useRef();
    // const { nodes, materials } = useGLTF("/变电箱.gltf");
    const { nodes, materials } = useGLTF("/变电箱.gltf");
    return (
      <group ref={group} {...props} dispose={null}>
        <group
          position={[-0.47, -0.24, -0.1]}
          rotation={[0, -1.57, 0]}
          scale={[-0.017, -0.017, 0.005]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100.geometry}
            material={materials["Material.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_1.geometry}
            material={nodes.立方体100_1.material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_2.geometry}
            material={materials["材质.017"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_3.geometry}
            material={materials.铁柱子}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_4.geometry}
            material={materials.变压器主体}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_5.geometry}
            material={materials.绿色按钮}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_6.geometry}
            material={materials.散热片}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_7.geometry}
            material={materials.按钮}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_8.geometry}
            material={materials["plastic_black_shiny_camera.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_9.geometry}
            material={materials["plastic_grey_camera.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_10.geometry}
            material={materials.plastic_grey_camera}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_11.geometry}
            material={materials.plastic_black_shiny_camera}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_12.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_13.geometry}
            material={materials["材质.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_14.geometry}
            material={materials["变压器主体.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_15.geometry}
            material={materials["铁柱子.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_16.geometry}
            material={materials["打卡机前壳.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_17.geometry}
            material={materials["按钮.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_18.geometry}
            material={materials["散热片.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_19.geometry}
            material={materials["变压器主体.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_20.geometry}
            material={materials["铁柱子.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_21.geometry}
            material={materials.test}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_22.geometry}
            material={materials.打卡机前壳}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_23.geometry}
            material={materials["按钮.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_24.geometry}
            material={materials.灭火器红色}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_25.geometry}
            material={materials.灭火器黑色}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_26.geometry}
            material={materials.Bottom}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_27.geometry}
            material={materials.Room}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_28.geometry}
            material={materials.Window}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_29.geometry}
            material={materials.Terrace}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.立方体100_30.geometry}
            material={materials.黑铁}
          />
          <lineSegments
            geometry={nodes.立方体100_31.geometry}
            material={materials["Material.001"]}
          />
        </group>
      </group>
    );
}

// useGLTF.preload("/ 变电箱.gltf");