import {combineReducers, createStore} from "redux"

import WebsocketReducer from './store/reducer'

const reducer = combineReducers({
    AppValue: WebsocketReducer
})

const initState = {
    AppValue: {
        value: ''
    }
}

export default createStore(reducer, initState)