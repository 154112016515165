import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useEffect, useState } from "react";
import { Select } from "@react-three/postprocessing"

export function Model(props) {

    const selectData = {
        "电箱": false,
        "梯子": false
    }


    const group = useRef();
    const { nodes, materials, animations } = useGLTF("/采油机_h.glb");
    const { actions, names } = useAnimations(animations, group);
    const [hovered, setHovered] = useState(false)
    const [selectModel, setSelectModel] = useState(selectData)

    useEffect(() => {
        names.forEach(name => {
            actions[name].play()
        })
    }, [actions, names])

    useEffect(() => {
        document.body.style.cursor = hovered ? "pointer" : "auto"
    }, [hovered])

    return (
    <group ref={group} {...props} dispose={null}>
        <group name="Scene">
            <mesh
                name="固定轴1001"
                castShadow
                receiveShadow
                geometry={nodes.固定轴1001.geometry}
                material={nodes.固定轴1001.material}
                onPointerOver={() => setHovered(true)}
                onPointerOut={() => setHovered(false)}
                onClick={() => {
                    console.log("点击了 固定轴")
                }}
                position={[-0.02, 2.4, -0.38]}
                rotation={[0.22, 0, 0]}
                scale={[0.53, 0.62, 0.53]}
            />
            <group name="架子" scale={[2, 1, 4]}>
                <mesh
                name="平面"
                castShadow
                receiveShadow
                geometry={nodes.平面.geometry}
                material={materials.地面默认}
                onPointerOver={() => setHovered(true)}
                onPointerOut={() => setHovered(false)}
                onClick={() => {
                    console.log("点击了 地面")
                }}
                />
                <Select name="梯子"  enabled={selectModel.梯子}>
                    <mesh
                    name="平面_1"
                    castShadow
                    receiveShadow
                    geometry={nodes.平面_1.geometry}
                    material={materials.梯子}
                    onPointerOver={() => setHovered(true)}
                    onPointerOut={() => setHovered(false)}
                    onClick={() => {
                        setSelectModel((proModel) => (
                            {
                                ...proModel,
                                "梯子" : !proModel.梯子
                            }
                        ))
                    }}
                    />
                </Select>

                <Select name="电箱" enabled={selectModel.电箱} >
                    <mesh
                    name="平面_2"
                    castShadow
                    receiveShadow
                    geometry={nodes.平面_2.geometry}
                    material={materials.电箱}
                    onPointerOver={() => setHovered(true)}
                    onPointerOut={() => setHovered(false)}
                    onClick={() => {
                        setSelectModel((proModel) => (
                            {
                                ...proModel,
                                "电箱" : !proModel.电箱
                            }
                        ))
                    }}
                    />
                </Select>
                <mesh
                name="平面_3"
                castShadow
                receiveShadow
                geometry={nodes.平面_3.geometry}
                material={materials.电箱红灯}
                />
                <mesh
                name="平面_4"
                castShadow
                receiveShadow
                geometry={nodes.平面_4.geometry}
                material={materials.电箱绿灯}
                />
                <mesh
                name="平面_5"
                castShadow
                receiveShadow
                geometry={nodes.平面_5.geometry}
                material={materials.电箱黄灯}
                />
                <mesh
                name="平面_6"
                castShadow
                receiveShadow
                geometry={nodes.平面_6.geometry}
                material={materials.电箱把手}
                />
            </group>
            <group
                name="采油臂"
                position={[-0.01, 2.99, 1.3]}
                rotation={[0.08, 0, 0]}
                scale={[0.05, 0.08, 2.3]}
            >
                <mesh
                name="立方体"
                castShadow
                receiveShadow
                geometry={nodes.立方体.geometry}
                material={materials.采油手臂材质}
                />
                <mesh
                name="立方体_1"
                castShadow
                receiveShadow
                geometry={nodes.立方体_1.geometry}
                material={materials.采油臂轴}
                />
            </group>
            <group
                name="转轴1"
                position={[-0.01, 1.48, -1.31]}
                rotation={[-Math.PI, 0, Math.PI / 2]}
                scale={2.4}
            >
                <mesh
                name="环体001"
                castShadow
                receiveShadow
                geometry={nodes.环体001.geometry}
                material={materials.转轴1_轴}
                />
                <mesh
                name="环体001_1"
                castShadow
                receiveShadow
                geometry={nodes.环体001_1.geometry}
                material={materials.转轴1_标识}
                />
            </group>
            <mesh
                name="马达"
                castShadow
                receiveShadow
                geometry={nodes.马达.geometry}
                material={materials.辅助马达外壳}
                position={[0, 1.54, -0.74]}
                rotation={[1.46, 0, 0]}
                scale={[3.21, 1, 1]}
            />
            <group
                name="摆臂"
                position={[-0.01, 1.57, -0.52]}
                rotation={[-1.46, 0, Math.PI / 2]}
                scale={1.08}
            >
                <mesh
                name="圆环006"
                castShadow
                receiveShadow
                geometry={nodes.圆环006.geometry}
                material={materials.摆臂_转轴材质}
                />
                <mesh
                name="圆环006_1"
                castShadow
                receiveShadow
                geometry={nodes.圆环006_1.geometry}
                material={materials.摆臂_固定器}
                />
                <mesh
                name="圆环006_2"
                castShadow
                receiveShadow
                geometry={nodes.圆环006_2.geometry}
                material={materials.摆臂_负重器}
                />
            </group>
            <group
                name="转轴2"
                position={[0.02, 0.8, -2.42]}
                rotation={[Math.PI, 0, Math.PI / 2]}
                scale={[1.2, 2.4, 1.2]}
            >
                <mesh
                name="环体003"
                castShadow
                receiveShadow
                geometry={nodes.环体003.geometry}
                material={materials.动力马达转轴}
                />
                <mesh
                name="环体003_1"
                castShadow
                receiveShadow
                geometry={nodes.环体003_1.geometry}
                material={materials.动力马达转轴_标识}
                />
            </group>
            <group
                name="马达001"
                position={[0.02, 0.8, -2.42]}
                rotation={[0, 0, -Math.PI / 2]}
                scale={[0.17, 1.83, 0.17]}
            >
                <mesh
                name="Gear001"
                castShadow
                receiveShadow
                geometry={nodes.Gear001.geometry}
                material={materials.动力马达默认色}
                />
                <mesh
                name="Gear001_1"
                castShadow
                receiveShadow
                geometry={nodes.Gear001_1.geometry}
                material={materials.动力马达支架}
                />
            </group>
            <mesh
                name="传力带1"
                castShadow
                receiveShadow
                geometry={nodes.传力带1.geometry}
                material={materials.传力带}
                position={[0.5, 0.8, -2.42]}
                rotation={[0, 0, -Math.PI / 2]}
                scale={0.06}
            />
            <mesh
                name="传力带2"
                castShadow
                receiveShadow
                geometry={nodes.传力带2.geometry}
                material={materials.传力带}
                position={[-0.47, 0.8, -2.42]}
                rotation={[0, 0, -Math.PI / 2]}
                scale={0.06}
            />
        </group>
    </group>
    );
}

useGLTF.preload("/采油机_h.glb");

export default Model