import * as ActionTypes from './actionTypes'
const reducer = (state = {}, action) => {
    switch(action.type){
        case ActionTypes.HIGHT: {
            return {...state, value: action.data}
        }
        default: {
            return state
        }
    }
}

export default reducer