import React, { useRef, useState, useEffect } from "react";
import { useGLTF, useCursor, Html, useTexture } from "@react-three/drei";
import { useFrame, useThree } from '@react-three/fiber'
import { useControls } from 'leva'
import request from '../../../utils/request';
import './style.css'
import { Select } from "@react-three/postprocessing"
import { useSpring, a } from '@react-spring/three'


export default function Model(props) {
	console.log("111",props)
	// const socket = io("ws://localhost:9999/ws");
	// const Socket = new WebSocket('ws://127.0.0.1:8080/wsresult?ljh');
	// Socket.onopen = () => {
	// 	const data = {
	// 		messsage: 'hello'
	// 	}
	// 	// heartCheck.reset().start();
	// 	Socket.send(JSON.stringify(data))
	// };

	let socketData = {
		A: 1,
		R: 0,
		G: 0,
		B: 0,
	}

	// Socket.onmessage = (event) => {
	// 	// console.log('-->', JSON.parse(event.data));
	// 	socketData = JSON.parse(event.data).data
	// 	setRGBA(socketData)
	// }

	const sendWbS = () => {
		request('POST', 'http://localhost:8080/web/rest/wbsocket', {
			cmd: 'sendWB',
			type: 'request',
			request: {
				data: 'hello_socket'
			}
		}).then(obj => {
			console.log('-->obj', obj);
		})
	}

	const [RGBA, setRGBA] = useState({
		R: 0,
		G: 0,
		B: 0,
		A: 0,
	})

	useEffect(() => {
		console.log(RGBA);
		const color = { r: Math.pow(RGBA.R / 255, 2.2), g: Math.pow(RGBA.G / 255, 2.2), b: Math.pow(RGBA.A / 255, 2.2), isColor: true }

		if (selectObj !== null) {
			selectObj.material.color = color
		}
	}, [RGBA])

	// const bakedTexture = useTexture('/漫射.png')

	const selectData = {
		Cube_: false,
		z002_: false,
		z005_: false,
		NURBS_: false,
		l001_: false,
		z013_1: false,
		unit_: false,
		z013_: false,
		z013_2: false,
		z013_3: false,
	}

	const [spring, api] = useSpring(() => ({ 'rotation-y': 0, 'position-x': 0, config: { friction: 40 } }), [])

	//判断选择的物体
	const select = (e) => {
		for (let i in selectData) {
			// console.log(i, selectData[i]);
			if (e === i) {
				selectData[i] = true
			} else {
				selectData[i] = false
			}
		}

		setIsSelect(selectData)
	}

	const [selectObj, setSelectObj] = useState(null)

	const [isSelect, setIsSelect] = useState({
		Cube_: false,
		z002_: false,
		z005_: false,
		NURBS_: false,
		l001_: false,
		z013_1: false,
		unit_: false,
		z013_: false,
		z013_2: false,
		z013_3: false,
	})

	const group = useRef();
	const { nodes, materials } = useGLTF("/泵_整体.gltf");
	// const { nodes, materials } = useGLTF("/泵_整体.gltf");
	const { viewport } = useThree()
	useFrame((state) => {

		const t = state.clock.getElapsedTime()
		// console.log('-=>',state.mouse.z);
		if (crProps.是否可控) {
			group.current.position.x = state.mouse.x * viewport.width / 2
			group.current.position.y = state.mouse.y * viewport.height / 2
		}

		// group.current.position.z = state.mouse.z * viewport.width / 2
	})

	// console.log('--nodes>', materials);
	const crProps = useControls({
		myBarColor: { r: 0, b: 0, g: 0, a: 1 },
		是否可控: false,
		回到原点: false
		// 取消选择:
	})

	useEffect(() => {
		if (crProps.回到原点) {
			group.current.position.x = 0
			group.current.position.y = 0
			group.current.position.z = 0
		}
	}, [crProps.回到原点])

	useEffect(() => {
		let r = crProps.myBarColor.r
		let g = crProps.myBarColor.g
		let b = crProps.myBarColor.b

		const color = { r: Math.pow(r / 255, 2.2), g: Math.pow(g / 255, 2.2), b: Math.pow(b / 255, 2.2), isColor: true }

		if (selectObj !== null) {
			selectObj.material.color = color
		}
	}, [crProps.myBarColor])


	//鼠标状态
	const [active, setActive] = useState(false)
	//更改鼠标状态钩子
	useCursor(active)

	//平滑下降
	const down = () => {
		props.navigate('/unit')
	}

	const click = (e) => {
		console.log('--e-->',e);
		switch (e.object.parent.name) {
			case 'unit_':
				select('unit_')
				break;
			case 'Cube_':
				select('Cube_')
				break;
			case '柱体002_':
				select('z002_')
				break;
			case '柱体005_':
				select('z005_')
				break;
			case 'NURBS_路径_':
				select('NURBS_')
				break;
			case '立方体001_':
				select('l001_')
				break;
			case '柱体013_1':
				select('z013_1')
				break;
			case '柱体013_':
				select('z013_')
				break;
			case '柱体013_2':
				select('z013_2')
				break;
			case '柱体013_3':
				select('z013_3')
				break;
			default:
				break;
		}
		setSelectObj(e.object)
	}

	return (
		<group onClick={click} ref={group} {...props} dispose={null}>
			<Select name="Cube_" enabled={isSelect.Cube_}>
				<mesh
					name={'Cube'}
					castShadow
					receiveShadow
					geometry={nodes.Cube.geometry}
					material={materials["Material.002"]}
					position={[-1.67, 0.3, -0.01]}
					scale={1.09}
				/>
			</Select>

			<Select name="柱体002_" enabled={isSelect.z002_}>

				<mesh
					name={'柱体002'}
					castShadow
					receiveShadow
					geometry={nodes.柱体002.geometry}
					material={materials["材质.004"]}
					position={[-2.69, 1.1, -1.48]}
					rotation={[Math.PI / 2, 0, 0]}
					onPointerOver={() => setActive(true)}//鼠标移入
					onPointerOut={() => setActive(false)}//鼠标移出
					onClick={sendWbS}
					scale={0.34}
				/>
			</Select>

			<Select name="柱体005_" enabled={isSelect.z005_}>
				<mesh
					name={'柱体005'}
					castShadow
					receiveShadow
					geometry={nodes.柱体005.geometry}
					material={materials["Material.002"]}
					position={[1.08, 1.16, -0.03]}
					rotation={[0, 0, -Math.PI / 2]}
					onPointerOver={() => setActive(true)}//鼠标移入
					onPointerOut={() => setActive(false)}//鼠标移出
					onClick={down}
					scale={0.15}
				>
				</mesh>
			</Select>


			<Select name="NURBS_路径_" enabled={isSelect.NURBS_}>
				<mesh
					name={'NURBS_路径'}
					castShadow
					receiveShadow
					geometry={nodes.NURBS_路径.geometry}
					material={materials["材质.019"]}
					position={[1.73, 1.38, -1.19]}
					rotation={[-0.44, 0, 0]}
					scale={0.19}
				/>
			</Select>


			<Select name="立方体001_" enabled={isSelect.l001_}>
				<mesh
					name={'立方体001'}
					castShadow
					receiveShadow
					geometry={nodes.立方体001.geometry}
					material={materials["材质.020"]}
					position={[1.73, 1.31, -0.01]}
					scale={0.24}
				/>
			</Select>

			{/* <Select name="柱体013_" enabled={isSelect.z013_}> */}

			<group position={[1.73, 1.31, 1.31]} scale={0.47}>
				<Select name="柱体013_" enabled={isSelect.z013_}>
					<mesh
						name={'柱体013'}
						castShadow
						receiveShadow
						geometry={nodes.柱体013.geometry}
						material={materials["材质.025"]}
					/>
				</Select>
				<Select name="柱体013_1" enabled={isSelect.z013_1}>
					<mesh
						name={'柱体013_1'}
						castShadow
						receiveShadow
						geometry={nodes.柱体013_1.geometry}
						material={materials["材质.021"]}
					/>
				</Select>
				<Select name="柱体013_2" enabled={isSelect.z013_2}>
					<mesh
						name={'柱体013_2'}
						castShadow
						receiveShadow
						geometry={nodes.柱体013_2.geometry}
						material={materials["材质.022"]}
					/>
				</Select>
				<Select name="柱体013_3" enabled={isSelect.z013_3}>
					<mesh
						name={'柱体013_3'}
						castShadow
						receiveShadow
						geometry={nodes.柱体013_3.geometry}
						material={materials["材质.024"]}
					/>
				</Select>
			</group>
			{/* </Select> */}

			<Select name="unit_" enabled={isSelect.unit_}>
				<mesh
					name={'柱体009'}
					castShadow
					receiveShadow
					geometry={nodes.柱体009.geometry}
					material={materials["材质.026"]}
					position={[1.35, 1.42, -0.05]}
					rotation={[0, 0, -Math.PI / 2]}
					scale={0.15}
				/>
				<mesh
					name={'柱体012'}
					castShadow
					receiveShadow
					geometry={nodes.柱体012.geometry}
					material={materials["材质.027"]}
					position={[0.84, 1.14, -0.03]}
					rotation={[0, 0, -Math.PI / 2]}
					scale={0.15}
				/>
				<mesh
					name={'柱体001'}
					castShadow
					receiveShadow
					geometry={nodes.柱体001.geometry}
					material={materials["材质.014"]}
					position={[-0.1, 2.21, 0]}
					rotation={[0, 0, -Math.PI / 2]}
					scale={0.15}
				/>
				<mesh
					name={'柱体'}
					castShadow
					receiveShadow
					geometry={nodes.柱体.geometry}
					material={materials["材质.014"]}
					position={[-2.66, 1.07, 0.1]}
					rotation={[Math.PI / 2, 0, 0]}
					scale={0.67}
				/>
				<mesh
					name={'柱体003'}
					castShadow
					receiveShadow
					geometry={nodes.柱体003.geometry}
					material={materials["材质.014"]}
					position={[-0.24, 1.42, -0.16]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={0.72}
				/>
				<mesh
					name={'柱体004'}
					castShadow
					receiveShadow
					geometry={nodes.柱体004.geometry}
					material={materials["材质.014"]}
					position={[-0.86, 1.23, -1.65]}
					rotation={[-Math.PI / 2, 0, 0]}

					scale={0.88}
				/>
				<mesh
					name={'立方体'}
					castShadow
					receiveShadow
					geometry={nodes.立方体.geometry}
					material={materials["材质.014"]}
					position={[1.68, 1.49, -0.02]}
					scale={0.33}
				/>
				<mesh
					name={'柱体008'}
					castShadow
					receiveShadow
					geometry={nodes.柱体008.geometry}
					material={materials["材质.014"]}
					position={[-0.78, 1.2, 1.15]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={0.52}
				/>
			</Select>

		</group>

	);
}

// useGLTF.preload("/泵_整体.gltf");
