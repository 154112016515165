import * as THREE from 'three';
import { useEffect, useRef } from 'react';

import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { Water } from 'three/addons/objects/Water.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { Sky } from 'three/addons/objects/Sky.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';



const OilWell3D = (props) => {

    const canvasRef = useRef(null);
    // 父组件传入的数据 
    const {info} = props

    // 默认实际总井深 设为定值 3500
    const realityAllDepth = 3500

    // 由父组件传入的液面距井口的距离
    const savedDepth = useRef(0);
    // 上次的井口距离数据
    const saveLastDepth = useRef(realityAllDepth);
    // 由父组件传递的声速
    const saveAcousticVelocity = useRef(0);
    // 上次的声速
    const saveLastAcousticVelocity = useRef(0);

    useEffect(() => {

        // 获取画布组件
        const canvas = canvasRef.current;
        const clock = new THREE.Clock();

        // 长度放大基数
        const cardinalNumber = 5
        // 管道半径放大基数
        const radius = 8
        // 放大比例  长度放大基数 * 放大比例 = 总井深
        const cardinalNumberScale = 53
        // 虚拟圆柱总长度
        const colLong = cardinalNumber * cardinalNumberScale
        // 圆柱底部的y轴坐标, 
        const colBottom = - colLong / 2 ;


        // 根据比例计算虚拟深度
        // 传入实际深度
        const getInventedDepth = (x) => {
            return x * colLong / realityAllDepth
        }

        // 通过虚拟深度获得实际深度
        // 传入虚拟深度
        const getRealityDepth = (x) => {
            return x * realityAllDepth / colLong
        }

        // 动画
        let mixer;

        // 注释字体
        let spriteX;
        // 动态变化的水柱
        let mesh_water;
        // 标识线
        let lineHead, lineBody, lineFoot;
        // 动画id
        let animationId = 0;
        // 3d文字类型
        let fontType;
        // 3d文字 
        let textMesh;

        // 液体材质
        const material1 = new THREE.MeshBasicMaterial( {
            transparent: true,
            opacity: 0.9,
            color: 0x37D0F6
        } );
        
        // 辅助线材质
        const material2 = new THREE.LineBasicMaterial( { color: 0x0540FF } );

        // 3D字体材质
        const textMaterial = new THREE.MeshPhongMaterial( { color: 0xA5098, flatShading: true } )
    
        // 渲染器
        const renderer = new THREE.WebGLRenderer({ canvas });
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( window.innerWidth, window.innerHeight );
        renderer.toneMapping = THREE.ACESFilmicToneMapping;

        // 场景
        const scene = new THREE.Scene();

        // 摄像机
        const camera = new THREE.PerspectiveCamera( 80, window.innerWidth / window.innerHeight, 1, 20000 );
        // 摄像机位置
        camera.position.set( -10, 200, 300 );

        // 视角控制器
        const controls = new OrbitControls( camera, renderer.domElement );
        controls.maxPolarAngle = Math.PI * 0.85;
        controls.minPolarAngle = Math.PI * 0.2;
        controls.maxAzimuthAngle = Math.PI * 0.3
        controls.minAzimuthAngle = - Math.PI * 0.3
        controls.target.set( 0, 80, 0 );
        controls.minDistance = 50.0;
        controls.maxDistance = 400.0;
        controls.enableDamping = true;
        controls.update();

        const point = new THREE.PointLight(0xffffff, 1) //光源设置
        point.position.set(300, 400, 200) //点光源位置
        scene.add(point) //将光源添加到场景中
        
        const ambient = new THREE.AmbientLight(0xffffff, 1) //环境光
        ambient.position.set(200, 300, 200) //点光源位置
        scene.add(ambient)
        
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1) //方向光
        directionalLight.position.set(150, 300, 200)
        scene.add(directionalLight)

        // Water
        const waterGeometry = new THREE.CircleGeometry( radius, 100 );

        const water = new Water(
            waterGeometry,
            {
                textureWidth: 512,
                textureHeight: 512,
                waterNormals: new THREE.TextureLoader().load( '/waternormals.jpg', function ( texture ) {
                    // 重复纹理
                    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

                } ),
                sunDirection: new THREE.Vector3(),
                sunColor: 0xffffff,
                waterColor: 0x37D0F6,
                distortionScale: 3.7,
                fog: scene.fog !== undefined
            }
        );

        water.rotation.x = - Math.PI / 2;

        // Skybox
        const sky = new Sky();
        sky.scale.setScalar( 10000 );
        sky.position.set(0, 200, 0)
        scene.add( sky );

        const skyUniforms = sky.material.uniforms;

        skyUniforms[ 'turbidity' ].value = 10;
        skyUniforms[ 'rayleigh' ].value = 2;
        skyUniforms[ 'mieCoefficient' ].value = 0.005;
        skyUniforms[ 'mieDirectionalG' ].value = 0.8;

        const parameters = {
            elevation: 2,
            azimuth: 180
        };

        // 太阳
        const sun = new THREE.Vector3();
        
        // 创建一个PMREMGenerator，从立方体映射环境纹理生成预过滤的 Mipmap 辐射环境贴图
        const pmremGenerator = new THREE.PMREMGenerator( renderer );
        let renderTarget;

        function updateSun() {

            const phi = THREE.MathUtils.degToRad( 90 - parameters.elevation );
            const theta = THREE.MathUtils.degToRad( parameters.azimuth );

            sun.setFromSphericalCoords( 1, phi, theta );

            sky.material.uniforms[ 'sunPosition' ].value.copy( sun );
            water.material.uniforms[ 'sunDirection' ].value.copy( sun ).normalize();

            if ( renderTarget !== undefined ) renderTarget.dispose();

            renderTarget = pmremGenerator.fromScene( sky );

            scene.environment = renderTarget.texture;

        }

        updateSun();

        // 加载场地模型
        const loader = new GLTFLoader();
        loader.load( '/陆地钻井平台_看板专用.glb', function ( gltf ) {

            const model = gltf.scene;
            model.position.set( 122, 127, 0 );
            model.scale.set( 10, 10, 10 );
            scene.add( model );

        }, undefined, function ( e ) {
            console.error( e );

        } );

        // 加载采油机
        loader.load( '/采油机_h.glb', function ( gltf ) {

            const model = gltf.scene;
            model.position.set( -10, 130, 0 );
            model.scale.set( 25, 20, 20 );
            model.rotation.y = - Math.PI / 2

            // 创建动画混合器
            mixer = new THREE.AnimationMixer( model );
            for (var i = 0; i < gltf.animations.length; i++) {
                mixer.clipAction( gltf.animations[ i ] ).play();
            }
            scene.add( model );

        }, undefined, function ( e ) {

            console.error( e );

        } );

        // 加载横向液体
        const broadwise_mesh_water = new THREE.Mesh( new THREE.CylinderGeometry( radius, radius, 225 ), material1 );
        broadwise_mesh_water.position.set(147, -162, 0);
        broadwise_mesh_water.rotateZ( - Math.PI / 2 );

        scene.add( broadwise_mesh_water );

        // 创建标识辅助线
        const createGuide = (centerPoint, length) => {
            centerPoint["x"] += 4
            centerPoint["y"] += colBottom
            // 传入中心点位坐标和需要生成的线段长度
            var explanatoryLineHead = new THREE.BufferGeometry(); //辅助线头
            var explanatoryLineBody = new THREE.BufferGeometry(); //辅助线身体
            var explanatoryLineFoot = new THREE.BufferGeometry(); //辅助线尾部
            explanatoryLineBody.setFromPoints(
                [
                    new THREE.Vector3(centerPoint["x"], centerPoint["y"] - length / 2, centerPoint["z"]),
                    new THREE.Vector3(centerPoint["x"], centerPoint["y"] + length / 2, centerPoint["z"])
                ]
            );
            explanatoryLineHead.setFromPoints(
                [
                    new THREE.Vector3(centerPoint["x"] - 1, centerPoint["y"] + length / 2, centerPoint["z"]),
                    new THREE.Vector3(centerPoint["x"] + 1, centerPoint["y"] + length / 2, centerPoint["z"])
                ]
            );
            explanatoryLineFoot.setFromPoints(
                [
                    new THREE.Vector3(centerPoint["x"] - 1, centerPoint["y"] - length / 2, centerPoint["z"]),
                    new THREE.Vector3(centerPoint["x"] + 1, centerPoint["y"] - length / 2, centerPoint["z"])
                ]
            );
            lineHead = new THREE.Line(explanatoryLineHead, material2);
            lineBody = new THREE.Line(explanatoryLineBody, material2);
            lineFoot = new THREE.Line(explanatoryLineFoot, material2);
            scene.add(lineHead); //线条对象添加到场景中
            scene.add(lineBody); 
            scene.add(lineFoot); 
        }

        /* 创建字体精灵 */
        function makeTextSprite(message, parameters) {

            if (parameters === undefined) parameters = {};
    
            var fontface = parameters.hasOwnProperty("fontface") ?
                parameters["fontface"] : "Arial";
    
            /* 字体大小 */
            var fontsize = parameters.hasOwnProperty("fontsize") ?
                parameters["fontsize"] : 18;
    
            /* 字体颜色 */
            var fontColor = parameters.hasOwnProperty("fontColor") ?
                parameters["fontColor"] : { r: 0, g: 0, b: 0, a: 1.0 };
    
            /* 边框厚度 */
            var borderThickness = parameters.hasOwnProperty("borderThickness") ?
                parameters["borderThickness"] : 4;
    
            /* 边框颜色 */
            var borderColor = parameters.hasOwnProperty("borderColor") ?
                parameters["borderColor"] : { r: 0, g: 0, b: 0, a: 1.0 };
    
            /* 背景颜色 */
            var backgroundColor = parameters.hasOwnProperty("backgroundColor") ?
                parameters["backgroundColor"] : { r: 255, g: 255, b: 255, a: 1.0 };
    
            /* 创建画布 */
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
    
            /* 字体加粗 */
            context.font = "Bold " + fontsize + "px " + fontface;
    
            /* 边框的颜色 */
            context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + ","
                + borderColor.b + "," + borderColor.a + ")";
            context.lineWidth = borderThickness;
    
    
            /* 字体颜色 */
            context.fillStyle = "rgba(" + fontColor.r + "," + fontColor.g + ","
            + fontColor.b + "," + backgroundColor.a + ")";
            context.fillText(message, borderThickness, fontsize + borderThickness);
    
            /* 画布内容用于纹理贴图 */
            var texture = new THREE.Texture(canvas);
            texture.needsUpdate = true;
    
            var spriteMaterial = new THREE.SpriteMaterial({ map: texture });
            var sprite = new THREE.Sprite(spriteMaterial);
    
            /* 缩放比例 */
            sprite.scale.set(20, 10, 1);
    
            return sprite;
    
        }

        // 在场景指定位置创建字体
        function createFont(centerPoint, length) {
            // 显示实际深度
            spriteX = makeTextSprite(Math.round(getRealityDepth(length) * 100) / 100 + "m",
            {
                // 字体大小
                fontsize: 50,
                // 字体颜色
                fontColor: { r: 7, g: 74, b: 254, a: 0.4 }
            });
            spriteX.center = new THREE.Vector2(0, 0);
            scene.add(spriteX);
            spriteX.position.set(centerPoint["x"] + 2, centerPoint["y"] - 4, centerPoint["z"]);
        }

        // 创建备注
        // 中心点位， 长度
        function createRemarks(centerPoint, length) {
            // 创建标识线
            createGuide(centerPoint, length)
            // 创建字体
            createFont(centerPoint, length)
        }

        function loadFont() {
            // 加载3D字体资源
            const textLoader = new FontLoader();
            textLoader.load( '/fonts/Arial Unicode MS_Regular.json', (response) => {
                fontType = response
                refreshText(get3DText(saveLastDepth.current, saveLastAcousticVelocity.current))
            } );
            
        }

        // 加载字体字体
        function refreshText(text) {

            // 清除之前的字体
            if (textMesh) {
                scene.remove( textMesh );
            }
            createText(text);
        }

        function createText(text) {

            // 生成字体
            let textGeo = new TextGeometry( text, {

                font: fontType,

                size: 20,
                height: 5,
                curveSegments: 4,

                bevelThickness: 2,
                bevelSize: 1.5,
                bevelEnabled: true

            } );

            // 计算当前几何体的的边界矩形，该操作会更新已有 [param:.boundingBox]。
            textGeo.computeBoundingBox();

            textMesh = new THREE.Mesh( textGeo, textMaterial );

            textMesh.position.x = 60;
            textMesh.position.y = 230;
            textMesh.position.z = 0;

            textMesh.rotation.x = 0;
            textMesh.rotation.y = Math.PI * 2;

            scene.add( textMesh );

        }

        loadFont();

        // 获取需要在屏幕上显示的3D字体
        function get3DText(depth, acousticVelocity) {
            // 3D字体显示实际深度
            return '液面深度 ' + depth.toFixed(1) + '\n声速' + acousticVelocity.toFixed(1)
        }

        // 更新  传入 实际水面距离井口的深度
        function renew(realityDegree) {

            // 根据比例计算 虚拟水柱长度
            const degree = getInventedDepth(realityAllDepth - realityDegree)

            water.position.y = degree + colBottom + 0.02;
            // 水立方
            scene.remove( mesh_water );
            // 注解文字
            scene.remove( spriteX );
            // 标识线
            scene.remove( lineHead );
            scene.remove( lineBody );
            scene.remove( lineFoot );            
            
            if ( degree - 0.01 > 0 ) {

                createRemarks(
                    {
                        "x": 10,
                        "y": (colLong - degree) / 2 + degree ,
                        "z":0
                    },
                    colLong - degree
                )
                scene.add( water );
                mesh_water = new THREE.Mesh( new THREE.CylinderGeometry( radius, radius, degree - 0.01 ), material1 );
                mesh_water.position.y = degree / 2 + colBottom;
                scene.add( mesh_water );

            } else {

                scene.remove( water );

            }
        }

        window.addEventListener( 'resize', onWindowResize );

        function onWindowResize() {

            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize( window.innerWidth, window.innerHeight );

        }

        function render() {

            // 水表面波纹变化
            water.material.uniforms[ 'time' ].value += 1.0 / 60.0;

            // 当前值是否发送变化
            let change = false
    
            // 液体高度变动
            if (saveLastDepth.current <= savedDepth.current - 10 ) {
                change = true
                saveLastDepth.current += 10;
                renew(saveLastDepth.current);
            }
            if (saveLastDepth.current >= savedDepth.current + 10 ) {
                change = true
                saveLastDepth.current -= 10;
                renew(saveLastDepth.current);
            }
            // 终值回归
            if (saveLastDepth.current > savedDepth.current - 10 && saveLastDepth.current < savedDepth.current + 10 && saveLastDepth.current !== savedDepth.current) {
                change = true
                saveLastDepth.current = savedDepth.current
                renew(saveLastDepth.current);
            }

            // 3D字体变动
            if (change || saveLastAcousticVelocity.current !== saveAcousticVelocity.current) {
                saveLastAcousticVelocity.current = saveAcousticVelocity.current
                const str_3D = get3DText(saveLastDepth.current, saveLastAcousticVelocity.current)
                refreshText(str_3D)
            }

            renderer.render( scene, camera );
    
        }

        // 持续渲染动画效果
        function animate() {

            const delta = clock.getDelta();
            if (mixer) {
                mixer.update(delta)
            }

            controls.update()
            animationId = requestAnimationFrame( animate );
            render();
        }

        animate();

        return () => {

            window.removeEventListener('resize', onWindowResize);
            cancelAnimationFrame(animationId);

            scene.traverse((child) => {
              if (child.material) {
                child.material.dispose();
              }
              if (child.geometry) {
                child.geometry.dispose();
              }
              child = null;
            })
            renderer.dispose()
        }

    }, [])

    useEffect(() => {
        // 实际深度
        savedDepth.current = info["deepth"]
        // 声速
        saveAcousticVelocity.current = info["acousticVelocity"]
    }, [info])
    

    return <canvas ref={canvasRef} />;
}

export default OilWell3D