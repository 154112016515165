import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { Provider } from "react-redux";


import LandRIGSHOW from "../pages/landRIG";
import OceanRIGSHOW from "../pages/OceanRIG";
import Viewer from "../pages/fiber";
import Anima from "../pages/anima";
import SubstationShow from "../pages/3Dsubstation";
import SmallSubstaionShow from "../pages/smallSubstation";
import PartialPressureShow from "../pages/partialPressure";
import TransformationShow from "../pages/transformation";
import BoxSubstationShow from "../pages/boxSubstation";
import OilWell3D from "../pages/Visualization3D"
import store from "../pages/3Dsubstation/store";
import Test from "../pages/test"
import BambooDragonfly from "../pages/bambooDragonfly"

import HomePage from "../pages/HomePage";

// import CubeShow from "../pages/cube";
// import KeyShow from "../pages/key";

const Root = () => {
    return (
        <div style={
            {
                width: "100%",
                height: "100%"
            }
        }>
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        {/* 陆地钻井平台 */}
                        <Route path="LandRIGSHOW" element={<LandRIGSHOW />} />
                        {/* 海上钻井平台 */}
                        <Route path="OceanRIGSHOW" element={<OceanRIGSHOW />} />
                        {/* 泵 */}
                        <Route path="model" element={<Viewer />} />
                        {/* 泵子组件 */}
                        <Route path="unit" element={<Anima />} />
                        {/* 变电站 */}
                        <Route path="SubstationShow" element={<Provider store={store}><SubstationShow /></Provider>} />
                        {/* 变电站子组件 */}
                        <Route path="SmallSubstaionShow" element={<SmallSubstaionShow />} />
                        <Route path="PartialPressureShow" element={<PartialPressureShow />} />
                        <Route path="TransformationShow" element={<TransformationShow />} />
                        <Route path="BoxSubstationShow" element={<BoxSubstationShow />} />
                        {/* 3D井产看板 */}
                        <Route path="OilWell3D" element={<OilWell3D />} />
                        {/* 测试 */}
                        <Route path="test" element={<Test />} />
                        {/* 竹蜻蜓 */}
                        <Route path="BambooDragonfly" element={<BambooDragonfly />} />
                        {/* 导航 */}
                        <Route path="/" element={<HomePage />} />
                        <Route path="*" element={<HomePage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default Root
