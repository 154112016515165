import { Canvas } from '@react-three/fiber'
import { OrbitControls,Sky,Stats, Stage  } from '@react-three/drei'
import { Suspense } from 'react'

import BambooDragonfly from './compoment/BambooDragonfly'
import MyScene from './compoment/MyScene'
import './index.css'
  
export default function App() {

  return (
    <div id="canvas-container">
        <Suspense fallback={<span>加载中...</span>}>
            {/* 场景 */}
            <Canvas shadows camera={{ position: [0, 1, 5], fov: 50 }}>
            {/* 控制器 */}
            <OrbitControls />
            {/* 天空盒子 */}
            <Sky scale={1000} sunPosition={[500, 150, -1000]} turbidity={0.1} />
            {/* 舞台 （提供灯光） */}
            <Stage preset="rembrandt" intensity={1} environment="city" />
            <MyScene position={[0, -2, 0]} />
            <BambooDragonfly scale={0.2}/>
            </Canvas>
            <Stats/>
        </Suspense>
    </div>
  )
}