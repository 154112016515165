import React from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/废土与剑.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.立方体.geometry}
        material={materials.材质}
        scale={[5, 0.5, 5]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.立方体001.geometry}
        material={materials["材质.004"]}
        position={[-3.35, 0.68, -3.06]}
        rotation={[-0.05, -0.34, -0.31]}
        scale={0.41}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.立方体002.geometry}
        material={materials["材质.001"]}
        position={[-2.5, 0.73, -1.13]}
        rotation={[-0.67, -0.85, -1.33]}
        scale={0.43}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.立方体003.geometry}
        material={materials["材质.005"]}
        position={[-0.9, 0.67, -2.99]}
        rotation={[-1.54, 0.56, 2.56]}
        scale={0.27}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.立方体004.geometry}
        material={materials["材质.006"]}
        position={[3.15, 0.53, -2.85]}
        rotation={[-0.88, -0.86, -1.6]}
        scale={0.41}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock.geometry}
        material={materials["材质.002"]}
        position={[-3.57, 0.43, 0.41]}
        rotation={[-0.21, 0.48, 0.99]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock001.geometry}
        material={materials["材质.007"]}
        position={[3.47, 0.43, 0.79]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rock002.geometry}
        material={materials["材质.003"]}
        position={[-3.02, 0.43, 1.45]}
        rotation={[-0.34, 0.19, 1.94]}
        scale={0.65}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.柱体.geometry}
        material={materials["材质.009"]}
        position={[2.24, 0.75, 2.13]}
        rotation={[0, 0.02, 0.47]}
        scale={[0.37, 0.45, 0.45]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.立方体005.geometry}
        material={materials["材质.010"]}
        position={[1.06, 3.06, 2.15]}
        rotation={[0, 0.02, 0.47]}
        scale={[0.45, 0.11, 0.45]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.球体.geometry}
        material={materials["材质.008"]}
        position={[2.31, 0.47, 2.15]}
        rotation={[0.42, -0.36, -0.09]}
        scale={0.64}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.贝塞尔曲线.geometry}
        material={materials["材质.011"]}
        position={[1.57, 0.66, 1.18]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.叶子.geometry}
        material={materials["材质.012"]}
        position={[1.6, 0.66, 1.2]}
        scale={0.52}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.贝塞尔曲线002.geometry}
        material={nodes.贝塞尔曲线002.material}
      />
    </group>
  );
}

useGLTF.preload("/废土与剑.glb");