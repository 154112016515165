import { Canvas } from '@react-three/fiber'
import { OrbitControls,Sky,Stats, Stage, Html  } from '@react-three/drei'
import { Suspense } from 'react'
import { useNavigate } from "react-router-dom";
import { FaChevronUp } from 'react-icons/fa'
import { EffectComposer, SMAA, Outline, Selection } from "@react-three/postprocessing"


import Model from './compoment/Model'

import './index.css'

export default function App() {

    const navigate = useNavigate()

    // 路由跳转
    const push = () => {
        navigate('/')
    }

  return (
    <div id="canvas-container">
        <Suspense fallback={<span>加载中...</span>}>
            {/* 场景 */}
            <Canvas shadows camera={{ position: [10, 2, 1], fov: 50 }}>
            {/* 控制器 */}
            <OrbitControls />
            {/* 天空盒子 */}
            <Sky scale={1000} sunPosition={[500, 150, -1000]} turbidity={0.1} />
            {/* 舞台 （提供灯光） */}
            <Stage preset="rembrandt" intensity={1} environment="city">
                <Html distanceFactor={80} position={[-0.5, 8, 0]}>
                    <FaChevronUp onClick={push} style={{ cursor: 'pointer', color: 'indianred' }} />
                </Html>
                <Selection>
                    <EffectComposer multisampling={0} autoClear={false}>
                        <Outline visibleEdgeColor="white" hiddenEdgeColor="white" blur  edgeStrength={5} />
                        <SMAA />
                    </EffectComposer>
                    {/* 模型 */}
                    <Model />
                </Selection>
            </Stage>
            </Canvas>
            <Stats/>
        </Suspense>
    </div>
  )
}