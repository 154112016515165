import React, { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage, Sky, Stats } from '@react-three/drei'
import AllSubStation from './component/allSubstaion'
import { useNavigate } from "react-router-dom";
// import Websocket from './websocket';
import { connect } from 'react-redux';
import LoaderInfo from '../utils/loaderInfo'

function Substaion (props) {
    console.log('-all');
    console.log('hhhhhhh',props)
    const navigate = useNavigate()

    const ref = useRef()
    return (
        <div className='App'>
            {/* <Websocket /> */}
            <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
                <Suspense fallback={<LoaderInfo/>}>
                    <Stage controls={ref} preset="rembrandt" intensity={1} environment="city">
                            <AllSubStation navigate={navigate} Child value = {props}/>
                    </Stage>

                    <Sky scale={1000} sunPosition={[500, 150, -1000]} turbidity={0.1} />
                </Suspense>
                <OrbitControls ref={ref} />
                <Stats />
            </Canvas>
        </div>

    )
}

const mapStateToProps = (state) => ({
    value: state.AppValue.value
})

const mapDispatchToProps = (dispatch) => ({
    // Handleh(data) {
    //     dispatch(Actions.Handleh(data))
    // }
})


export default connect(mapStateToProps,mapDispatchToProps)(Substaion)