import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { Card, Row, Col } from 'antd';
import LandRIGSHOW from './pages/landRIG';
import OceanRIGSHOW from './pages/OceanRIG';
import Viewer from './pages/fiber';
import SubstationShow from './pages/3Dsubstation';
import OilWell3D from './pages/Visualization3D';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center">3D平台</h1>
            </div>
          </div>
          <Row className="justify-content-md-center">
            <Col md={2}>
              <Link to="/"><Card><Card.Img variant="top" src="/waternormals.jpg" alt="路由1" /></Card></Link>
            </Col>
            <Col md={2}>
              <Link to="/LandRIGSHOW"><Card><Card.Img variant="top" src="/waternormals.jpg" alt="路由2" /></Card></Link>
            </Col>
            <Col md={2}>
              <Link to="/OceanRIGSHOW"><Card><Card.Img variant="top" src="/waternormals.jpg" alt="路由3" /></Card></Link>
            </Col>
            <Col md={2}>
              <Link to="/model"><Card><Card.Img variant="top" src="/waternormals.jpg" alt="路由4" /></Card></Link>
            </Col>
            <Col md={2}>
              <Link to="/SubstationShow"><Card><Card.Img variant="top" src="/waternormals.jpg" alt="路由5" /></Card></Link>
            </Col>
          </Row>
          <Routes>
            <Route path="/" element={<img src="/waternormals.jpg" alt="路由1" />} />
            <Route path="/LandRIGSHOW" element={<LandRIGSHOW />} />
            <Route path="/OceanRIGSHOW" element={<OceanRIGSHOW />} />
            <Route path="/model" element={<Viewer />} />
            <Route path="/SubstationShow" element={<SubstationShow />} />
            <Route path="/OilWell3D" element={<img src="/waternormals.jpg" alt="路由1" />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
