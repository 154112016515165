/*
 * @Author: mwxp (ljh) 
 * @Date: 2022-06-08 19:16:26 
 * @Last Modified by: mwxp (ljh)
 * @Last Modified time: 2022-07-12 18:03:16
 */

import React, { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage, Sky, Stats } from '@react-three/drei'
import Model from './componenet/model'
import './style.css'
import { useNavigate } from "react-router-dom";
import { EffectComposer, SSAO, SMAA, Selection, Outline } from "@react-three/postprocessing"
import LoaderInfo from '../utils/loaderInfo'




export default function Viewer() {
    const ref = useRef()

    const navigate = useNavigate()
    return (
        <div className='App'>
            <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
                <Suspense fallback={<LoaderInfo/>}>

                    <Selection>
                        <Stage controls={ref} preset="rembrandt" intensity={1} environment="city">

                            <EffectComposer multisampling={0} autoClear={false}>
                                {/* <SSAO radius={0.05} intensity={150} luminanceInfluence={0.5} color="black" /> */}
                                <Outline visibleEdgeColor="white" hiddenEdgeColor="white" blur  edgeStrength={5} />
                                <SMAA />
                            </EffectComposer>

                            <Model navigate={navigate} />
                        </Stage>
                    </Selection>


                    <Sky scale={1000} sunPosition={[500, 150, -1000]} turbidity={0.1} />
                </Suspense>
                <OrbitControls ref={ref} />
                <Stats />
            </Canvas>
        </div>
    )
}