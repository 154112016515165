import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function PartialPressureShow({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/分压房内部场景.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        name="立方体"
        castShadow
        receiveShadow
        geometry={nodes.立方体.geometry}
        material={nodes.立方体.material}
        scale={[23.1, 7.36, 30.18]}
      />
      <group
        name="立方体001"
        position={[-6.6, -1.49, -14.22]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={[0.21, 0.52, 0.52]}
      >
        <mesh
          name="立方体002_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_1.geometry}
          material={materials["灰色.001"]}
        />
        <mesh
          name="立方体002_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_2.geometry}
          material={materials["红色.002"]}
        />
        <mesh
          name="立方体002_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_3.geometry}
          material={materials["浅灰色.001"]}
        />
        <mesh
          name="立方体002_4"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_4.geometry}
          material={materials.浅灰色}
        />
        <mesh
          name="立方体002_5"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_5.geometry}
          material={materials.灰色}
        />
        <mesh
          name="立方体002_6"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_6.geometry}
          material={materials.透明红}
        />
        <mesh
          name="立方体002_7"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_7.geometry}
          material={materials.透明橙色}
        />
        <mesh
          name="立方体002_8"
          castShadow
          receiveShadow
          geometry={nodes.立方体002_8.geometry}
          material={materials.大屏幕}
        />
      </group>
      <group
        name="立方体002"
        position={[8.06, 2.8, -12.62]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={[0.21, 0.52, 0.52]}
      >
        <mesh
          name="立方体001_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体001_1.geometry}
          material={materials["灰色.001"]}
        />
        <mesh
          name="立方体001_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体001_2.geometry}
          material={materials["红色.002"]}
        />
        <mesh
          name="立方体001_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体001_3.geometry}
          material={materials["浅灰色.001"]}
        />
        <mesh
          name="立方体001_4"
          castShadow
          receiveShadow
          geometry={nodes.立方体001_4.geometry}
          material={materials.浅灰色}
        />
        <mesh
          name="立方体001_5"
          castShadow
          receiveShadow
          geometry={nodes.立方体001_5.geometry}
          material={materials.灰色}
        />
        <mesh
          name="立方体001_6"
          castShadow
          receiveShadow
          geometry={nodes.立方体001_6.geometry}
          material={materials.透明红}
        />
        <mesh
          name="立方体001_7"
          castShadow
          receiveShadow
          geometry={nodes.立方体001_7.geometry}
          material={materials.透明橙色}
        />
        <mesh
          name="立方体001_8"
          castShadow
          receiveShadow
          geometry={nodes.立方体001_8.geometry}
          material={materials.大屏幕}
        />
      </group>
      <mesh
        name="柱体"
        castShadow
        receiveShadow
        geometry={nodes.柱体.geometry}
        material={nodes.柱体.material}
        position={[7.85, 4.52, -1.44]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[-0.27, -20.87, -0.27]}
      />
      <mesh
        name="柱体001"
        castShadow
        receiveShadow
        geometry={nodes.柱体001.geometry}
        material={nodes.柱体001.material}
        position={[14.42, 4.53, 19.14]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.28}
      />
      <mesh
        name="柱体002"
        castShadow
        receiveShadow
        geometry={nodes.柱体002.geometry}
        material={nodes.柱体002.material}
        position={[-6.54, 4.53, 19.14]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.28}
      />
      <mesh
        name="柱体003"
        castShadow
        receiveShadow
        geometry={nodes.柱体003.geometry}
        material={nodes.柱体003.material}
        position={[-6.56, 4.53, 19.02]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.28}
      />
      <mesh
        name="立方体004"
        castShadow
        receiveShadow
        geometry={nodes.立方体004.geometry}
        material={nodes.立方体004.material}
        position={[-14.38, 0.42, -28.17]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={[-1.6, -0.38, -1.11]}
      />
      <mesh
        name="立方体003"
        castShadow
        receiveShadow
        geometry={nodes.立方体003.geometry}
        material={materials["变压器主体.001"]}
        position={[-14.39, 0.42, -27.98]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={[-1.26, -0.3, -0.87]}
      />
      <mesh
        name="立方体005"
        castShadow
        receiveShadow
        geometry={nodes.立方体005.geometry}
        material={nodes.立方体005.material}
        position={[-14.38, 0.42, -27.96]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.84, 0.32, 0.64]}
      />
      <mesh
        name="立方体006"
        castShadow
        receiveShadow
        geometry={nodes.立方体006.geometry}
        material={materials["材质.002"]}
        position={[-14.38, 0.42, -27.71]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.74, 0.12, 0.56]}
      />
      <mesh
        name="柱体191"
        castShadow
        receiveShadow
        geometry={nodes.柱体191.geometry}
        material={materials["铁柱子.001"]}
        position={[-15.72, -0.3, -27.86]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.11}
      />
      <mesh
        name="柱体189"
        castShadow
        receiveShadow
        geometry={nodes.柱体189.geometry}
        material={materials["铁柱子.001"]}
        position={[-13.06, 1.16, -27.86]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.11}
      />
      <mesh
        name="柱体188"
        castShadow
        receiveShadow
        geometry={nodes.柱体188.geometry}
        material={materials["铁柱子.001"]}
        position={[-13.06, -0.3, -27.86]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.11}
      />
      <mesh
        name="柱体004"
        castShadow
        receiveShadow
        geometry={nodes.柱体004.geometry}
        material={materials["按钮.001"]}
        position={[-13.76, -1.14, -28.16]}
        rotation={[-Math.PI, 0, 0]}
        scale={[0.16, 0.51, 0.16]}
      />
      <mesh
        name="柱体005"
        castShadow
        receiveShadow
        geometry={nodes.柱体005.geometry}
        material={materials["打卡机前壳.001"]}
        position={[-15.22, -0.94, -28.16]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-0.17, -0.22, -0.17]}
      />
      <mesh
        name="柱体006"
        castShadow
        receiveShadow
        geometry={nodes.柱体006.geometry}
        material={materials["铁柱子.001"]}
        position={[-15.69, 1.16, -27.86]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.11}
      />
      <mesh
        name="立方体007"
        castShadow
        receiveShadow
        geometry={nodes.立方体007.geometry}
        material={nodes.立方体007.material}
        position={[21.56, 0.42, 19.93]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={[-1.6, -0.38, -1.11]}
      />
      <mesh
        name="立方体008"
        castShadow
        receiveShadow
        geometry={nodes.立方体008.geometry}
        material={materials["变压器主体.001"]}
        position={[21.37, 0.42, 19.92]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={[-1.26, -0.3, -0.87]}
      />
      <mesh
        name="立方体009"
        castShadow
        receiveShadow
        geometry={nodes.立方体009.geometry}
        material={nodes.立方体009.material}
        position={[21.35, 0.42, 19.93]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.84, 0.32, 0.64]}
      />
      <mesh
        name="立方体010"
        castShadow
        receiveShadow
        geometry={nodes.立方体010.geometry}
        material={materials["材质.002"]}
        position={[21.1, 0.42, 19.93]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.74, 0.12, 0.56]}
      />
      <mesh
        name="柱体007"
        castShadow
        receiveShadow
        geometry={nodes.柱体007.geometry}
        material={materials["铁柱子.001"]}
        position={[21.25, -0.3, 18.59]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.11}
      />
      <mesh
        name="柱体008"
        castShadow
        receiveShadow
        geometry={nodes.柱体008.geometry}
        material={materials["铁柱子.001"]}
        position={[21.25, 1.16, 21.25]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.11}
      />
      <mesh
        name="柱体009"
        castShadow
        receiveShadow
        geometry={nodes.柱体009.geometry}
        material={materials["铁柱子.001"]}
        position={[21.25, -0.3, 21.25]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.11}
      />
      <mesh
        name="柱体010"
        castShadow
        receiveShadow
        geometry={nodes.柱体010.geometry}
        material={materials["按钮.001"]}
        position={[21.55, -1.14, 20.55]}
        rotation={[-Math.PI, Math.PI / 2, 0]}
        scale={[0.16, 0.51, 0.16]}
      />
      <mesh
        name="柱体011"
        castShadow
        receiveShadow
        geometry={nodes.柱体011.geometry}
        material={materials["打卡机前壳.001"]}
        position={[21.55, -0.94, 19.09]}
        rotation={[-Math.PI, Math.PI / 2, 0]}
        scale={[-0.17, -0.22, -0.17]}
      />
      <mesh
        name="柱体012"
        castShadow
        receiveShadow
        geometry={nodes.柱体012.geometry}
        material={materials["铁柱子.001"]}
        position={[21.25, 1.16, 18.62]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.11}
      />
      <group
        name="立方体065"
        position={[2.32, -3.98, -26.1]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[-0.13, -0.12, -0.04]}
      >
        <mesh
          name="立方体100"
          castShadow
          receiveShadow
          geometry={nodes.立方体100.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          name="立方体100_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_1.geometry}
          material={nodes.立方体100_1.material}
        />
        <mesh
          name="立方体100_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_2.geometry}
          material={materials["材质.017"]}
        />
        <mesh
          name="立方体100_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_3.geometry}
          material={materials.铁柱子}
        />
        <mesh
          name="立方体100_4"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_4.geometry}
          material={materials.变压器主体}
        />
        <mesh
          name="立方体100_5"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_5.geometry}
          material={materials.绿色按钮}
        />
        <mesh
          name="立方体100_6"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_6.geometry}
          material={materials.散热片}
        />
        <mesh
          name="立方体100_7"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_7.geometry}
          material={materials.按钮}
        />
        <mesh
          name="立方体100_8"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_8.geometry}
          material={materials["plastic_black_shiny_camera.003"]}
        />
        <mesh
          name="立方体100_9"
          castShadow
          receiveShadow
          geometry={nodes.立方体100_9.geometry}
          material={materials["plastic_grey_camera.002"]}
        />
      </group>
      <mesh
        name="立方体011"
        castShadow
        receiveShadow
        geometry={nodes.立方体011.geometry}
        material={materials["空调屏幕.001"]}
        position={[18.52, 3.95, -24.62]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={[0.59, 0.41, 0.42]}
      />
      <mesh
        name="空调外壳"
        castShadow
        receiveShadow
        geometry={nodes.空调外壳.geometry}
        material={materials["空调外壳.004"]}
        position={[18.9, -6.68, -24.99]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={[0.22, 0.19, 0.22]}
      />
      <mesh
        name="柱体013"
        castShadow
        receiveShadow
        geometry={nodes.柱体013.geometry}
        material={materials["空调按钮.002"]}
        position={[17.9, 3.02, -24.37]}
        rotation={[Math.PI / 2, 0, Math.PI / 4]}
        scale={0.1}
      />
      <mesh
        name="柱体014"
        castShadow
        receiveShadow
        geometry={nodes.柱体014.geometry}
        material={materials["空调按钮.002"]}
        position={[17.9, 3.28, -24.37]}
        rotation={[Math.PI / 2, 0, Math.PI / 4]}
        scale={0.1}
      />
      <mesh
        name="控制模块"
        castShadow
        receiveShadow
        geometry={nodes.控制模块.geometry}
        material={nodes.控制模块.material}
        position={[18.08, 3.15, -24.17]}
        rotation={[Math.PI / 2, 0, Math.PI / 4]}
        scale={[0.22, 0.22, 0.19]}
      />
      <mesh
        name="中国能效标识"
        castShadow
        receiveShadow
        geometry={nodes.中国能效标识.geometry}
        material={materials["材质.003"]}
        position={[18.98, 4.05, -23.47]}
        rotation={[0, -Math.PI / 4, -3.13]}
        scale={[0.21, 0.31, 0.35]}
      />
      <group
        name="Cube013"
        position={[-23.85, 0.03, 4.6]}
        scale={[-0.69, -4.43, -12.18]}
      >
        <mesh
          name="Cube013_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube013_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube013_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube013_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube001"
        position={[-20.9, 0.03, 4.6]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[-0.69, -4.43, -12.18]}
      >
        <mesh
          name="Cube001_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube001_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube001_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube001_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube002"
        position={[-15.01, 0.23, 30.56]}
        rotation={[0, -1.57, 0]}
        scale={[0.35, 3.72, 3.27]}
      >
        <mesh
          name="Cube002_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube002_1.geometry}
          material={materials["灰墙.001"]}
        />
        <mesh
          name="Cube002_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube002_2.geometry}
          material={materials["玻璃.001"]}
        />
      </group>
      <group
        name="Cube003"
        position={[16.93, 0.23, 30.56]}
        rotation={[0, -1.57, 0]}
        scale={[0.35, 3.72, 3.27]}
      >
        <mesh
          name="Cube003_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube003_1.geometry}
          material={materials["灰墙.001"]}
        />
        <mesh
          name="Cube003_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube003_2.geometry}
          material={materials["玻璃.001"]}
        />
      </group>
      <group
        name="Cube004"
        position={[-15.01, 0.23, 27.9]}
        rotation={[0, 1.57, 0]}
        scale={[0.35, 3.72, 3.27]}
      >
        <mesh
          name="Cube004_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube004_1.geometry}
          material={materials["灰墙.001"]}
        />
        <mesh
          name="Cube004_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube004_2.geometry}
          material={materials["玻璃.001"]}
        />
      </group>
      <group
        name="Cube005"
        position={[16.93, 0.23, 27.9]}
        rotation={[0, 1.57, 0]}
        scale={[0.35, 3.72, 3.27]}
      >
        <mesh
          name="Cube005_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube005_1.geometry}
          material={materials["灰墙.001"]}
        />
        <mesh
          name="Cube005_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube005_2.geometry}
          material={materials["玻璃.001"]}
        />
      </group>
      <group
        name="Cube006"
        position={[20.84, 0.03, 0.24]}
        scale={[-0.69, -4.43, -16.55]}
      >
        <mesh
          name="Cube006_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube006_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube006_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube006_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <group
        name="Cube007"
        position={[23.78, 0.03, 0.24]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[-0.69, -4.43, -16.55]}
      >
        <mesh
          name="Cube007_1"
          castShadow
          receiveShadow
          geometry={nodes.Cube007_1.geometry}
          material={materials.灰墙}
        />
        <mesh
          name="Cube007_2"
          castShadow
          receiveShadow
          geometry={nodes.Cube007_2.geometry}
          material={materials["玻璃.004"]}
        />
      </group>
      <mesh
        name="立方体043"
        castShadow
        receiveShadow
        geometry={nodes.立方体043.geometry}
        material={materials.铁门}
        position={[-22.67, -2.23, -22.15]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-36.7, -5.13, -2.51]}
      />
      <group
        name="立方体034"
        position={[-23.65, -2.12, -21.29]}
        scale={[0.12, 1, 0.16]}
      >
        <mesh
          name="立方体057"
          castShadow
          receiveShadow
          geometry={nodes.立方体057.geometry}
          material={materials.black}
        />
        <mesh
          name="立方体057_1"
          castShadow
          receiveShadow
          geometry={nodes.立方体057_1.geometry}
          material={materials.排风扇子}
        />
        <mesh
          name="立方体057_2"
          castShadow
          receiveShadow
          geometry={nodes.立方体057_2.geometry}
          material={materials.玻璃}
        />
        <mesh
          name="立方体057_3"
          castShadow
          receiveShadow
          geometry={nodes.立方体057_3.geometry}
          material={materials.铁门}
        />
      </group>
      <mesh
        name="立方体012"
        castShadow
        receiveShadow
        geometry={nodes.立方体012.geometry}
        material={materials.阁楼门}
        position={[-21.36, -1.1, -22.15]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-36.7, -5.13, -2.51]}
      />
      <mesh
        name="立方体013"
        castShadow
        receiveShadow
        geometry={nodes.立方体013.geometry}
        material={materials.阁楼门}
        position={[-22.43, -2.23, -22.15]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-125.6, -5.13, -2.51]}
      />
    </group>
  );
}

// useGLTF.preload("/分压房内部场景.gltf");